import { generateFilterBadges } from "utils/query"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { startCase } from "lodash"

import DeleteButton from "./DeleteButton"

import "./ProfilesTable.scss"

const ProfilesTable = ({ profiles, deleteProfile }) => {
  const navigate = useNavigate()

  return (
    <table className="profiles-table">
      <thead>
        <tr className="profiles-table-header">
          <th>Talent Profile</th>
          <th>People</th>
          <th>Job Roles</th>
          <th>Sales Orgs</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {profiles.map(profile => {
          const {
            author_name,
            created_at,
            filter,
            id,
            job_role_count,
            name,
            people_count,
            sales_org_count,
          } = profile

          const onClick = () => {
            deleteProfile(id)
          }

          const activeFilterModel = filter.filter_model
            ? filter.filter_model
            : null

          return (
            <tr className="profiles-table-row" key={id}>
              <td>
                <h5 onClick={() => navigate(`/talent-profiles/${id}`)}>
                  {name}
                </h5>
                <div className="profiles-table-badges">
                  {generateFilterBadges(activeFilterModel || filter).map(
                    (subFilter, i) =>
                      subFilter.map((badge, j) => (
                        <div className="profiles-table-badge" key={`${i}${j}`}>
                          {badge}
                        </div>
                      ))
                  )}
                  {filter.period && (
                    <div className="profiles-table-badge">
                      {startCase(filter.period)}
                    </div>
                  )}
                  {filter?.selling_persona_names !== null &&
                    filter?.selling_persona_names?.map(name => (
                      <div className="profiles-table-badge">{`Buyer Persona: ${name}`}</div>
                    ))}
                </div>
                <span>{`Created by ${author_name}, ${dayjs(created_at).format(
                  "MMM D, YYYY"
                )}`}</span>
              </td>
              <td>
                <h6>{people_count}</h6>
              </td>
              <td>
                <h6>{job_role_count}</h6>
              </td>
              <td>
                <h6>{sales_org_count}</h6>
              </td>
              <td>
                <div className="actions-cell">
                  <DeleteButton onClick={onClick} />
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ProfilesTable
