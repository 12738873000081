import cn from "classnames"
import { Col } from "react-bootstrap"
import EmployesRanked from "./Components/EmployeesRanked"
import AvgCategories from "./Components/AvgCategories"
import styles from "./RowCategoriesValued.module.scss"

const RowCategoriesValued = ({ rating }) => {
  return (
    <div className={cn(styles.rowCategoriesValued__container)}>
      <article className={styles.rowCategoriesValued__row}>
        <Col lg={4}>
          <span className={styles.rowCategoriesValued__row__category}>
            {rating?.metric_name}
          </span>
        </Col>

        <Col lg={2}>
          <span className={styles.rowCategoriesValued__row__rank}>
            {rating?.rank}
          </span>
        </Col>

        <Col lg={4}>
          <EmployesRanked topRankPercentage={rating?.top_rank_percentage} />
        </Col>

        <Col lg={2}>
          <AvgCategories avg={rating?.average_rating} />
        </Col>
      </article>
    </div>
  )
}

export default RowCategoriesValued
