import { useEffect } from "react"
import classNames from "classnames"
import { pageCall } from "utils/mixpanel"
import { htmlStripper } from "utils/string"

import ReactQuill from "react-quill"

import "react-quill/dist/quill.snow.css"
import styles from "./StepTwo.module.scss"

const StepTwo = ({
  setDescription,
  description,
  errors,
  isEditingAndIntegrated,
}) => {
  const strippedDescription = description && htmlStripper(description)

  useEffect(() => {
    pageCall("Post Job Description", "Jobs")
  }, [])

  return (
    <div
      className={classNames(styles.form, {
        [styles.error]: errors.description,
      })}
    >
      <label htmlFor="description">Tell us more about the job</label>
      {isEditingAndIntegrated && <em className={styles.subtext}>We ingest the job description from your ATS. Please make changes there if you wish to update the job listing description.</em>}
      <div className={styles.wrapper}>
        {isEditingAndIntegrated ? (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strippedDescription || "",
            }}
          />
        ) : (
          <ReactQuill
            theme="snow"
            value={description}
            onChange={value => {
              setDescription(value)
            }}
            data-cy="new-job-description"
          />
        )}
      </div>
      {errors.description && (
        <p className={styles.error__message}>Description must not be blank.</p>
      )}
    </div>
  )
}

export default StepTwo
