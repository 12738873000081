import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"

import styles from "./acceptedActionsDetails.module.scss"

const AcceptedActionsDetails = ({ linkedin }) => {
  return (
    <>
      <article className={styles.acceptedActionsDetails__container}>
        <a href={linkedin} target="_blank" rel="noreferrer">
          <PrimaryButton>
            <span>Linkedin</span>
            <Icon name="external-link" height="20" width="20" />
          </PrimaryButton>
        </a>
      </article>
    </>
  )
}

export default AcceptedActionsDetails
