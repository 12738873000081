import { useState, useEffect } from "react"
import styles from "./lineChartTooltip.module.scss"

const CustomTooltip = ({ data, title }) => {
  const [heightBar, setHeightBar] = useState(0)
  const [label, setLabel] = useState("")
  const [resume, setResume] = useState(0)

  useEffect(() => {
    let height = 0
    let resumeCount = 0
    setLabel(data?.title?.[0]?.replace(",", " "))
    data?.dataPoints?.forEach(point => {
      height += point?.element?.height
      resumeCount += point?.raw
    })
    setHeightBar(height)
    setResume(Number(resumeCount).toLocaleString())
  }, [data])

  return (
    <section
      className={styles.stackedBarChartTooltip}
      style={{
        left: `calc(${data?.caretX}px - 7rem)`,
        bottom: `calc(${heightBar}px + 6rem)`,
      }}
    >
      <div>
        <div className={styles.stackedBarChartTooltip__title}>
          <span>{title} </span>
          <span>On {label}</span>
        </div>

        <h5 className={styles.stackedBarChartTooltip__resume}>{resume}</h5>

        <article className={styles.stackedBarChartTooltip__pageSection}>
          <div className={styles.stackedBarChartTooltip__pageSection__header}>
            <span>Page</span>
            <span>Views</span>
          </div>

          {data?.dataPoints?.map((point, index) => (
            <div
              className={styles.stackedBarChartTooltip__pageSection__row}
              key={`tooltip-${index}`}
            >
              <div
                className={
                  styles.stackedBarChartTooltip__pageSection__row__categoryContainer
                }
              >
                <span
                  className={
                    styles.stackedBarChartTooltip__pageSection__row__itemColor
                  }
                  style={{ background: point?.dataset?.backgroundColor }}
                ></span>
                <span
                  className={
                    styles.stackedBarChartTooltip__pageSection__row__category
                  }
                >
                  {point?.dataset?.label}
                </span>
              </div>
              <span
                className={
                  styles.stackedBarChartTooltip__pageSection__row__value
                }
              >
                {point?.formattedValue}
              </span>
            </div>
          ))}
        </article>
      </div>
    </section>
  )
}

export default CustomTooltip
