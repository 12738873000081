import { useState } from "react"

import Icon from "components/Icon"
import Form from "react-bootstrap/Form"

import styles from "./PasswordField.module.scss"

const PasswordField = ({ retype, value, handleChange, reset }) => {
  const [seePassword, setSeePassword] = useState(false)

  const toggleSeePassword = () => {
    setSeePassword(!seePassword)
  }

  return (
    <div className={styles.inputWrapper}>
      <Icon name="lock" height="20" width="20" className={styles.iconLeft} />
      <Form.Control
        className={styles.input}
        type={seePassword ? "text" : "password"}
        placeholder={
          retype ? "Retype Password" : reset ? "Type password" : "Password"
        }
        value={value}
        name={retype ? "retypePassword" : "password"}
        onChange={handleChange}
      />
      <Icon
        name="eye"
        height="20"
        width="20"
        className={styles.iconRight}
        onClick={toggleSeePassword}
      />
    </div>
  )
}

export default PasswordField
