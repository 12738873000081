import { useEffect, useState } from "react"
import { getTalentProfiles, deleteTalentProfile } from "api/talent"
import { pageCall } from "utils/mixpanel"

import { Form, Spinner, Tooltip, OverlayTrigger, Row } from "react-bootstrap"
// import PeopleFilter from "components/talentProfiles/PeopleFilter"
import ProfilesTable from "components/talentProfiles/ProfilesTable"
import EmptyState from "components/talentProfiles/EmptyState"
import Confirmation from "components/shared/Notifications/Confirmation"
import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"

import "./TalentProfiles.scss"

const TalentProfilesRoute = () => {
  const [talentProfiles, setTalentProfiles] = useState([])
  const [soughtProfiles, setSoughtProfiles] = useState(null)
  // const [filters, setFilters] = useState({
  //   peopleGroup: { label: "All Recruiters", value: "all recruiters" },
  // })
  const [searchInput, setSearchInput] = useState("")
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const [loading, setLoading] = useState(true)

  const renderTooltip = props => (
    <Tooltip {...props}>
      Talent profiles are saved from the Talent Data page
    </Tooltip>
  )

  const onInputChange = event => {
    setSearchInput(event.target.value)
    if (event.target.value === "") {
      setSoughtProfiles(null)
    } else {
      const tempProfiles = [...talentProfiles]
      setSoughtProfiles(
        tempProfiles.filter(profile =>
          profile.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
      )
    }
  }

  const fetchTalentProfiles = () => {
    getTalentProfiles()
      .then(res => {
        setTalentProfiles(res.data.talent_profiles)
        setLoading(false)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const deleteLocalProfile = id => {
    const newProfiles = [...talentProfiles].filter(profile => profile.id !== id)
    setTalentProfiles(newProfiles)
  }

  const deleteProfile = id => {
    deleteTalentProfile(id)
      .then(res => {
        deleteLocalProfile(id)
        setShowDeleteSuccess(true)
      })
      .catch(error => console.error(error.data))
  }

  useEffect(() => {
    fetchTalentProfiles()
  }, [])

  useEffect(() => {
    pageCall("Talent Profiles", "Talent")
  }, [])

  return (
    <>
      <div className="profiles scroll-container">
        <Header title="Talent Profiles" />
        <Content>
          <div className="title-wrapper">
            <div className="title-info">
              <h3>Talent Profiles</h3>
              <span>
                Talent profiles are dynamic groups of anonymous RepVue users who
                match your criteria
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 100 }}
                  overlay={renderTooltip}
                >
                  <Row className="talent-profiles-info">
                    <Icon name="info" height="18" width="18" />
                  </Row>
                </OverlayTrigger>
              </span>
            </div>
            {/* <div className="tutorial-button">
              <Button>               
                Insights{" "}
                <Icon name="double-arrow" height={24} width={24} />
              </Button>
            </div> */}
          </div>
          <div className="action-bar">
            <div className="profile-search">
              <Icon name="search" height="18" width="18" />
              <Form.Control
                type="text"
                placeholder="Search talent profiles"
                onChange={onInputChange}
              />
            </div>
            {/* <PeopleFilter filters={filters} setFilters={setFilters} /> */}
          </div>
          {loading ? (
            <div className="talent-spinner-wrapper">
              <Spinner animation="border" />
            </div>
          ) : talentProfiles.length > 0 && soughtProfiles?.length !== 0 ? (
            <ProfilesTable
              profiles={soughtProfiles ? soughtProfiles : talentProfiles}
              deleteProfile={deleteProfile}
            />
          ) : (
            <EmptyState search={searchInput !== ""} />
          )}
        </Content>
      </div>
      <Confirmation
        show={showDeleteSuccess}
        onClose={() => setShowDeleteSuccess(false)}
      >
        <div className="added">Profile deleted successfully</div>
      </Confirmation>
    </>
  )
}

export default TalentProfilesRoute
