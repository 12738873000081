import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Tooltip from "components/shared/Tooltip"
import LineChart from "components/shared/LineChart"
// import GrowthLabel from "components/shared/GrowthLabel"
import styles from "./Score.module.scss"

const Score = ({ data }) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const [pointsChart, setPointsChart] = useState({
    labels: [],
    data: [],
    firstPoint: "",
    lastPoint: "",
  })

  useEffect(() => {
    let dataMap = { labels: [], data: [], firstPoint: "", lastPoint: "" }

    data?.monthly_company_trends?.forEach((point, index) => {
      dataMap.labels.push(`${point.month} ${point.year}`)
      dataMap.data.push(point.repvue_score)
      if (index === 0) dataMap.firstPoint = `${point.month} ${point.year}`
      if (index + 1 === data?.monthly_company_trends.length)
        dataMap.lastPoint = `${point.month} ${point.year}`
    })

    setPointsChart(dataMap)
  }, [data])

  return (
    <section className={styles.scoreSales__container}>
      <article className={styles.scoreSales__infoSection}>
        <div className={styles.scoreSales__title}>Your RepVue Score</div>
        <div className={styles.scoreSales__value}>{data?.repvue_score}</div>
        {/* Leaving these commented out in case we want to re-implement --TH */}
        {/* <GrowthLabel growth={data?.score_change_since_company_claimed} /> */}
        {data?.top_percent && (
          <p className={styles.scoreSales__infoSection__description}>
            <span className={styles.text}>
              {currentEmployer?.company?.name} is in the{" "}
              <span className={styles.scoreSales__infoSection__description__top}>
                top {data?.top_percent}%
              </span>{" "}
              of all sales orgs!
            </span>
            <Tooltip text="This refers to your RepVue score specifically" />
          </p>
        )}
      </article>
      <div className={styles.scoreSales__line}>
        <LineChart
          points={pointsChart}
          className={styles.scoreSales__lineChart}
          title="RepVue Score"
        />
      </div>
    </section>
  )
}

export default Score
