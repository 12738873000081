import SelectInput from "components/shared/SelectInput"
import Icon from "components/Icon"
import { components } from "react-select"
import cn from "classnames"

import styles from "./PeriodFilter.module.scss"

const PeriodFilter = ({ setFilters, filters }) => {
  const CustomControl = props => {
    const { children } = props
    return (
      <components.Control {...props} className={styles.placeholder}>
        <Icon name="calendar" height={20} width={20} />
        {children}
      </components.Control>
    )
  }

  const CustomOption = props => {
    const isActiveOption = props.children === filters?.ratingsPeriod.label

    return (
      <components.Option
        {...props}
        className={cn({
          [`${styles.activeOption}`]: isActiveOption,
          [`${styles.option}`]: true,
        })}
      >
        {props.children}
        {isActiveOption && <Icon name="check" height={15} width={15} />}
      </components.Option>
    )
  }

  return (
    <SelectInput
      onChange={data => {
        setFilters({ ...filters, ratingsPeriod: data })
      }}
      value={filters?.ratingsPeriod || null}
      className={styles.periodDropdown}
      name="period"
      options={[
        { label: "All Time", value: "all time" },
        { label: "Last 12 Months", value: "last 12 months" },
        { label: "Last 6 Months", value: "last 6 months" },
      ]}
      components={{
        Control: CustomControl,
        // IndicatorsContainer: CustomIndicatorContainer,
        Option: CustomOption,
        IndicatorSeparator: () => null,
      }}
    />
  )
}

export default PeriodFilter
