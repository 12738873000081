import { Button, Modal } from "react-bootstrap"
import Icon from "components/Icon"
import styles from "./modal.questions.module.scss"

const ModalQuestions = ({ show, company, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} className={styles.questionsModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.questionsModal__icon}>
          <Icon name="complex-check" height="50" width="50" />
        </div>
        <div className={styles.questionsModal__title}>
          This official answer from {company.name} has been submitted for review
        </div>
        <div className={styles.questionsModal__description}>
          {`RepVue will review your answer within the next business day. Once
            this answer is reviewed, we’ll post it on the Q&A page of your
            RepVue profile!`}
        </div>
        <Button className={styles.questionsModal__button} onClick={handleClose}>
          Understood
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default ModalQuestions
