import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { pageCall } from "utils/mixpanel"
import { Button, Tab, Tabs, Spinner } from "react-bootstrap"
import { Toaster } from "react-hot-toast"
import cn from "classnames"
import OutsideClickDetector from "hooks/outsideClickDetector"
import { getQuestions } from "api/questions"
import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"
import QuestionsTable from "components/questions/Table"
import Drawer from "components/questions/Drawer"

import "./Questions.scss"

const QuestionsRoute = () => {
  const { currentEmployer } = useSelector(state => state.auth)
  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(true)
  const [answeredQuestions, setAnsweredQuestions] = useState([])
  const [unansweredQuestions, setUnsweredQuestions] = useState([])
  const [drawerData, setDrawerData] = useState(null)

  useEffect(() => {
    fetchQuestions()
  }, [])

  const fetchQuestions = () => {
    setLoading(true)
    getQuestions()
      .then(res => {
        const questions = res.questions
        const filterAnsweredQuestions = questions.filter(
          question => question.official_answer !== null
        )
        const filterUnsweredQuestions = questions.filter(
          question => question.official_answer === null
        )

        setQuestions(questions)
        setAnsweredQuestions(filterAnsweredQuestions)
        setUnsweredQuestions(filterUnsweredQuestions)
      })
      .catch(e => {
        console.log("e", e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const openDrawer = (event, questionData, autofocus = false) => {
    event.stopPropagation()
    setDrawerData({ ...questionData, autofocus })
  }

  const closeDrawer = () => {
    setDrawerData(null)
  }

  useEffect(() => {
    pageCall("Question Response", "RepVue Profile")
  }, [])

  return (
    <div
      className={cn("questions", {
        "scroll-container": drawerData === null,
        "disabled-scroll": drawerData,
      })}
    >
      <Header title="Questions" />
      <Content>
        <div className="title-wrapper">
          <h3>Questions on Q&A Page</h3>
          <div className="title-button">
            <a
              href={`https://www.repvue.com/companies/${currentEmployer.company.slug}/questions`}
              target="_blank"
              rel="noreferrer"
            >
              <Button>
                View Q&A Page
                <Icon name="external-link" height="20" width="20" />
              </Button>
            </a>
          </div>
        </div>
        <div className={`wrapper`}>
          {loading ? (
            <div className="spinner-wrapper">
              <Spinner animation="border" />
            </div>
          ) : (
            <Tabs defaultActiveKey="all">
              <Tab
                eventKey="all"
                title={
                  <>
                    All
                    <span>{questions.length}</span>
                  </>
                }
              >
                <QuestionsTable questions={questions} openDrawer={openDrawer} />
              </Tab>
              <Tab
                eventKey="unanswered"
                title={
                  <>
                    Unanswered
                    <span>{unansweredQuestions.length}</span>
                  </>
                }
              >
                <QuestionsTable
                  questions={unansweredQuestions}
                  openDrawer={openDrawer}
                />
              </Tab>
              <Tab
                eventKey="answered"
                title={
                  <>
                    Answered
                    <span>{answeredQuestions.length}</span>
                  </>
                }
              >
                <QuestionsTable
                  questions={answeredQuestions}
                  openDrawer={openDrawer}
                />
              </Tab>
            </Tabs>
          )}
          <OutsideClickDetector onClick={closeDrawer}>
            <Drawer
              open={Boolean(drawerData)}
              onClose={closeDrawer}
              data={drawerData}
              company={currentEmployer.company}
              refreshQuestions={fetchQuestions}
            ></Drawer>
          </OutsideClickDetector>
        </div>
      </Content>
      <Toaster position="bottom-center" />
    </div>
  )
}

export default QuestionsRoute
