import apiCall from "utils/api"

export const getDashboard = () => {
  return apiCall("get", `dashboard`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getDataHealth = () => {
  return apiCall("get", `dashboard/health`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getRatingByJobRole = () => {
  return apiCall("get", `dashboard/average_metric_scores_by_job_role`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getEmployeeMetric = () => {
  return apiCall("get", `dashboard/employee_metric_rankings`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getVisits = params => {
  const options = { params }
  return apiCall("get", `dashboard/visits`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
