import RowRating from "./RowRating"
import styles from "./ListRatings.module.scss"

const ListRatings = ({ rows }) => {
  return (
    <section className={styles.listRatings}>
      <article className={styles.listRatings__header}>
        <div className={styles.listRatings__header__category}></div>
        <div className={styles.listRatings__header__column}>
          <span>Product Market Fit</span>
        </div>
        <div className={styles.listRatings__header__column}>
          <span>Professional Development</span>
        </div>
        <div className={styles.listRatings__header__column}>
          <span>Culture / Leadership</span>
        </div>
        <div className={styles.listRatings__header__column}>
          <span>Incentive Comp Structure</span>
        </div>
        <div className={styles.listRatings__header__column}>
          <span>Inbound Lead / Opportunity Flow</span>
        </div>
        <div className={styles.listRatings__header__column}>
          <span>Base Comp</span>
        </div>
        <div className={styles.listRatings__header__column}>
          <span>Diversity / Inclusion</span>
        </div>
      </article>
      <article className={styles.listRatings__body}>
        {rows.map((row, index) => (
          <RowRating key={`rating-${index}`} rating={row} />
        ))}
      </article>
    </section>
  )
}

export default ListRatings
