import { useSelector } from "react-redux"

import TableJobListing from "./Components/Table"
import JobTitle from "components/jobs/jobListings/JobsListingsTable/Components/JobTitle"
import Actions from "components/jobs/jobListings/JobsListingsTable/Components/Actions"
import Recruiters from "components/shared/Table/Cells/Recruiters"
import styles from "./jobListingsTable.module.scss"

const JobsListingsTable = ({
  jobs,
  setJobs,
  section,
  noJobs,
  activeJobsCount,
}) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const { company, job_board, customer } = currentEmployer
  const { access_type } = customer
  const { integration } = company

  const freeTier = access_type === "free"
  const noMoreJobs = freeTier && activeJobsCount >= 2

  const columns = [
    {
      Header: "Job Listings",
      hideHeader: true,
      columns: [
        {
          Header: "Job Title",
          accessor: "title",
          sortable: false,
          filterable: false,
          Cell: ({ cell }) => (
            <JobTitle
              jobTitle={cell?.value}
              data={cell?.row?.original}
              company={company}
            />
          ),
          width: "18rem",
          minWidth: "18rem",
          fixed: true,
          colSpan: 1,
          className: styles.jobsListingsTable__table__jobTitle,
        },
        {
          Header: "Applicants",
          accessor: "job_applications_count",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },
        {
          Header: "Accept",
          accessor: "accepted_job_applications_count",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },
        {
          Header: "Hired",
          accessor: "hired_job_applications_count",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },
        {
          Header: "Location",
          accessor: "metro_locations",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => {
            if (value) {
              return value?.length > 1 ? "Multiple locations" : value?.[0]?.name
            } else {
              return "Fully remote"
            }
          },
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },
        {
          Header: "Office",
          accessor: "show.premiered",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },
        {
          Header: "OTE Range",
          accessor: "oteRange",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => value || "--",
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },
        {
          Header: "Recruiter(s)",
          accessor: "recruiters",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) =>
            <Recruiters recruiters={value} /> || "--",
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },

        {
          Header: "Days Active",
          accessor: "days",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          width: "10rem",
          minWidth: "10rem",
          colSpan: 1,
        },
        {
          Header: "Actions",
          accessor: "",
          sortable: false,
          filterable: false,
          className: "actions",
          Cell: ({
            cell: {
              row: {
                original: { slug, id, status },
              },
            },
          }) =>
            <Actions slug={slug} id={id} active={status === "active"} /> ||
            "--",
          width: "8rem",
          minWidth: "8rem",
          colSpan: 1,
        },
      ],
    },
  ]

  if (integration || job_board?.applicant_method === "External") {
    columns[0].columns.splice(1, 3)
  }

  if (job_board?.applicant_method === "RepVue") {
    columns[0].columns.splice(5, 2)
    columns[0].columns.splice(6, 1)
  }

  return (
    <TableJobListing
      columns={columns}
      data={jobs}
      setData={setJobs}
      section={section}
      noJobs={noJobs}
      freeTier={freeTier}
      noMoreJobs={noMoreJobs}
    />
  )
}

export default JobsListingsTable
