import cn from "classnames"
import styles from "./labelTerms.module.scss"

const LabelTerms = ({ error }) => (
  <div className={styles.labelTerms}>
    <span className={cn({ [styles.labelTerms__error]: error })}>
      <strong>
        I confirm I represent HR, Recruiting, Employer Branding/PR, Sales
        Leadership, or an executive at my company
      </strong>{" "}
      and I agree to RepVue’s{" "}
      <a
        href="https://www.repvue.com/employer-terms"
        target="_blank"
        rel="noreferrer"
      >
        Terms
      </a>{" "}
      and{" "}
      <a href="https://www.repvue.com/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>{" "}
      on behalf of my company, and represent that I am authorized to sign this
      contract on behalf of my company.
    </span>
  </div>
)

export default LabelTerms
