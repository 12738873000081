import Icon from "components/Icon"

import styles from "./FilterBadge.module.scss"

const FilterBadge = ({ value, onClick }) => {
  return (
    <div className={styles.badge} onClick={onClick}>
      {value}
      <Icon name="close" height={12} width={12} />
    </div>
  )
}

export default FilterBadge
