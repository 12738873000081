import Card from "components/shared/Card"
import ListRatings from "./ListRatings"
import styles from "./CategoryRatings.module.scss"

const CategoryRatingsCard = ({ data }) => {
  return (
    <div className={styles.categoryRatings}>
      <Card className={styles.categoryRatings__card}>
        <h4>Category Ratings</h4>
        <p className={styles.categoryRatings__description}>
          Employees rate your org from 1-5 stars in each category below. Then
          the category ratings are combined to create an overall average rating.
        </p>

        <ListRatings
          averageRating={data?.average_rating}
          rows={data?.current_company_metric_scores}
        />
      </Card>
    </div>
  )
}

export default CategoryRatingsCard
