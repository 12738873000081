import { useEffect, useState, useRef } from "react"
import ProgressBar from "react-bootstrap/ProgressBar"
import * as htmlToImage from "html-to-image"
import Imgix from "react-imgix"
import dayjs from "dayjs"

import { getCompanyById } from "api/companies"
import StarRating from "components/StarRating"
import logo from "assets/images/repvue_color.png"

import styles from "./BattlecardPrint.module.scss"

const BattlecardPrint = ({ companies, downloading, afterDownload }) => {
  const [companyColumns, setCompanyColumns] = useState([])
  const [metrics, setMetrics] = useState([])
  const printRef = useRef()

  useEffect(() => {
    if (downloading) {
      downloadBattlecard()
    }
  }, [downloading])

  useEffect(() => {
    fillColumns()
  }, [companies])

  const fillColumns = async () => {
    Promise.all(
      companies
        .filter(company => company !== null)
        .map(async company => {
          const data = await fetchCompanyById(company.id)
          const allData = { ...company, ...data }
          return allData
        })
    ).then(allCompanies => {
      const mappedCompanies = allCompanies.map(company => {
        return {
          ...company,
          company_name: company.name || company.company_name,
          company_logo: company.logo || company.company_logo,
        }
      })

      const mappedMetrics =
        mappedCompanies[0]?.current_company_metric_scores?.map(
          metric => metric.name
        )
      setMetrics(mappedMetrics)
      setCompanyColumns(mappedCompanies)
    })
  }

  const fetchCompanyById = id => {
    return getCompanyById(id)
      .then(res => {
        return res
      })
      .catch(err => {
        return err
      })
  }

  const downloadBattlecard = () => {
    const node = printRef.current
    const printName =
      companyColumns
        .filter(company => company !== null)
        .map(company => company.company_name)
        .join("vs")
        .replace(" ", "") + dayjs().format("YYYYMMDD")

    htmlToImage
      .toPng(node)
      .then(dataUrl => {
        const link = document.createElement("a")
        link.download = printName + ".png"
        link.href = dataUrl
        link.click()
      })
      .catch(err => {
        console.error("downloadBattlecard error:", err)
      })
      .finally(() => {
        afterDownload()
      })
  }

  return (
    <div
      className={`${styles.wrapper} ${downloading && styles.showForDownload}`}
    >
      <div className={styles.container} ref={printRef}>
        <div className={styles.top}>
          <div className={styles.repvue}>
            <img src={logo} alt="RepVue" />
          </div>
          <div className={styles.topTitle}>
            {companyColumns[0]?.company_name} &amp;{" "}
            {companyColumns[1]?.company_name}
            {companyColumns[2] && ` & ${companyColumns[2].company_name}`}
          </div>
          <div className={styles.topDescription}>
            This data below is sourced from employees who rated their sales
            organization on RepVue.
          </div>
        </div>
        <div
          className={`${styles.inner} ${downloading && styles.noBorderRadius}`}
        >
          <div className={styles.detailsColumn}>
            <div className={styles.detailsRow}>&nbsp;</div>
            <div className={styles.detailsRow}>% of Team Achieving Quota</div>
            <div className={styles.detailsRow}>RepVue Score</div>
            <div className={styles.detailsRow}>Total Ratings</div>
            {metrics?.map(metric => {
              return (
                <div className={styles.detailsRow} key={metric}>
                  {metric}
                </div>
              )
            })}
          </div>
          {companyColumns.map(company => {
            return (
              <div className={styles.companyColumn} key={company.id}>
                <div className={styles.companyRow}>
                  <span className={styles.companyLogo}>
                    <Imgix
                      src={`${process.env.REACT_APP_IMAGE_URL}/${company.company_logo}`}
                      alt={company.company_name}
                      width={40}
                      height={40}
                    />
                  </span>
                  <span className={styles.companyName}>
                    {company.company_name}
                  </span>
                </div>
                <div className={styles.companyRow}>
                  {Number(company.quota_attainment).toFixed(1)}%{" "}
                  <ProgressBar now={company.quota_attainment} />
                </div>
                <div className={styles.companyRow}>{company.repvue_score}</div>
                <div className={styles.companyRow}>{company.ratings_count}</div>
                {company?.current_company_metric_scores?.map(
                  ({ name, raw_average }) => {
                    return (
                      <div className={styles.companyRow} key={name}>
                        <div className={styles.companyRow__starSection}>
                          <span
                            className={styles.companyRow__starSection__value}
                          >
                            {raw_average}
                          </span>
                          <StarRating
                            rating={raw_average}
                            readyForDownload={downloading}
                          />
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BattlecardPrint
