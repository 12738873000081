import OverviewJobDetails from "./Overview"
import DescriptionJobDetails from "./Description"
import styles from "./details.jobs.module.scss"

const JobDetails = ({ jobInfo }) => {
  return (
    <section className={styles.jobDetails}>
      <OverviewJobDetails jobInfo={jobInfo} />
      <DescriptionJobDetails description={jobInfo?.description} />
    </section>
  )
}

export default JobDetails
