import Icon from "components/Icon"
import React from "react"

import styles from "./Actions.module.scss"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const Actions = ({ id, handleDelete })=> {
  return (
    <div className={styles.actionsColumn}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-2`}>Remove ad location</Tooltip>}
      >
        <span className={styles.iconWrapper}>
          <Icon
            className={styles.deleteIcon}
            onClick={() => handleDelete(id)}
            name="x"
            height="22"
            width="22"
          />
        </span>
      </OverlayTrigger>
    </div>
  )
}

export default Actions
