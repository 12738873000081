import cn from "classnames"
import ProgressBar from "react-bootstrap/ProgressBar"

import styles from "./stripedProgressBar.module.scss"

const StrippedProgressBar = ({ value, valueStriped, label }) => {
  return (
    <div className={styles.striped}>
      <ProgressBar className={cn(styles.striped__progressBar)}>
        <ProgressBar
          className={cn(styles.striped__solid)}
          now={value}
          key={1}
        />
        <ProgressBar
          className={cn(styles.striped__striped)}
          striped
          now={valueStriped}
          key={2}
          animated
        />
      </ProgressBar>

      <span className={styles.striped__label}>{label}</span>
    </div>
  )
}

export default StrippedProgressBar
