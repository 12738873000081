import Card from "components/shared/Card"
import styles from "./emptyReviews.module.scss"

const EmptyReviews = () => {
  return (
    <Card>
      <section className={styles.emptyReviews}>
        <h6>No Current Reviews</h6>
        <p>Check back soon!</p>
      </section>
    </Card>
  )
}

export default EmptyReviews
