import { useSelector } from "react-redux"
import classNames from "classnames"
import { trackAction } from "utils/mixpanel"

import PrimaryButton from "components/shared/Buttons/Primary"

import megaphone from "./megaphone.png"
import lever from "./lever.png"
import greenhouse from "./greenhouse.png"

import styles from "./TableFiller.module.scss"

const TableFiller = () => {
  const { currentEmployer } = useSelector(state => state.auth)
  const { company } = currentEmployer
  const { integration } = company

  const imgSrc =
    integration === "greenhouse"
      ? greenhouse
      : integration === "lever"
      ? lever
      : megaphone

  const subheader =
    integration === "greenhouse"
      ? "Upgrade to our premium profile offering to integrate your Greenhouse ATS for fully automated management for all of your sales jobs, job ads on your talent competitors profiles, and more!"
      : integration === "lever"
      ? "Upgrade to our premium profile offering to integrate your Lever ATS for fully automated management for all of your sales jobs, job ads on your talent competitors profiles, and more!"
      : "Upgrade to our premium profile offering to unlock unlimited jobs, job ads on your talent competitors profiles, and more!"

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.icon}
        src={imgSrc}
        height="42"
        width="42"
        alt="announcement"
      />
      <span className={styles.headline}>You are limited to 2 jobs!</span>
      <p
        className={classNames(styles.subheader, {
          [styles.subheader__integration]: integration,
        })}
      >
        {subheader}
      </p>
      <a href="mailto:support@repvue.com">
        <PrimaryButton
          className={styles.button}
          onClick={() => {
            trackAction("Call to Action Clicked", {
              location: "Job Listings Table",
              click_text: "Contact Us",
              click_url: "support@repvue.com",
              modal_name: null,
            })
          }}
        >
          <span>Contact Us</span>
        </PrimaryButton>
      </a>
    </div>
  )
}

export default TableFiller
