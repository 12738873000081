import cn from "classnames"
import TimeReviewsFilters from "./timeReviews"
import StarsReviewsFilters from "./starsReviews"
import SortReviewsFilters from "./sortReviews"
import styles from "./reviewsFilters.module.scss"

const ReviewsFilters = ({ reviewSelect, setGlobalFilters }) => {
  return (
    <section
      className={cn(styles.filtersReviews, {
        [styles.filtersReviews__hide]: reviewSelect,
      })}
    >
      <article className={styles.filtersReviews__container}>
        <div className={styles.filtersReviews__filterContainer}>
          <TimeReviewsFilters setGlobalFilters={setGlobalFilters} />
        </div>
        <div className={styles.filtersReviews__filterContainer}>
          <StarsReviewsFilters setGlobalFilters={setGlobalFilters} />
        </div>
      </article>
      <div className={styles.filtersReviews__filterContainer}>
        <SortReviewsFilters setGlobalFilters={setGlobalFilters} />
      </div>
    </section>
  )
}

export default ReviewsFilters
