import styles from "./information.row.module.scss"

const InformationRow = ({ label, value }) => {
  return (
    <article className={styles.information}>
      <span className={styles.information__label}>{label}</span>
      <p className={styles.information__value}>{value}</p>
    </article>
  )
}

export default InformationRow
