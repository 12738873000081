import { useState, useEffect } from "react"
import { getTechStackVendors } from "api/tech_stack_vendors"
import { createJobBoard } from "api/job_board"
import classNames from "classnames"
import toast from "react-hot-toast"

import SelectInput from "components/shared/SelectInput"
import Form from "react-bootstrap/Form"
import PrimaryButton from "components/shared/Buttons/Primary"
import ErrorToast from "components/shared/Notifications/ErrorToast"

import styles from "./ExternalStage.module.scss"

const ExternalStage = ({ setStage, handleClose }) => {
  const [form, setForm] = useState({
    other_vendor: null,
    tech_stack_vendor_id: null,
    applicant_method: "External",
  })
  const [options, setOptions] = useState([])
  const [errors, setErrors] = useState({})

  const handleSubmit = () => {
    const jobBoard = {
      job_board: form,
    }
    createJobBoard(jobBoard)
      .then(res => {
        handleClose(res.data)
      })
      .catch(error => {
        console.error(error)
        if (error?.data) {
          setErrors(error?.data)
        } else {
          toast.custom(t => (
            <ErrorToast t={t} headline="Something went wrong" />
          ))
        }
      })
  }

  useEffect(() => {
    getTechStackVendors()
      .then(res => {
        setOptions(
          res.data.tech_stack_vendors.map(vendor => ({
            label: vendor.name,
            value: vendor.id,
          }))
        )
      })
      .catch(error => console.error(error))
  }, [])

  return (
    <div className="d-flex flex-column gap-4 p-5 text-center align-items-center">
      <div className={styles.header}>
        <h5 className={styles.headline}>Link to Your Job Board</h5>
        <p className={styles.description}>
          You will provide a tracking link when configuring your jobs which will
          direct potential applicants from RepVue to your job posting hosted on
          your job board.
        </p>
      </div>
      <Form className={styles.form}>
        <label className={styles.label}>Select your vendor</label>
        <SelectInput
          onChange={data => {
            const newErrors = { ...errors }
            delete newErrors.base
            setErrors(newErrors)
            setForm({ ...form, tech_stack_vendor_id: data.value })
          }}
          value={form.vendor}
          name="vendor"
          selectStyles={{
            placeholder: base => ({
              ...base,
              textAlign: "start",
              paddingLeft: "0.25rem",
              color: "#96a2ab",
            }),
            singleValue: base => ({
              ...base,
              textAlign: "start",
            }),
          }}
          options={options}
          className={classNames({
            [styles.invalid]: errors?.base,
            "mb-3": form.tech_stack_vendor_id,
          })}
        />
        {!form.tech_stack_vendor_id && (
          <Form.Control
            placeholder="If other, please specify"
            type="text"
            onChange={e => {
              const newErrors = { ...errors }
              delete newErrors.base
              setForm({ ...form, other_vendor: e.target.value })
              setErrors(newErrors)
            }}
            className={classNames({
              [styles.other]: !errors?.base,
              [styles.invalid]: errors?.base,
            })}
            data-cy="job-board-vendor-input"
          />
        )}
        {errors?.base && (
          <section className="d-flex gap-2 mb-3">
            {errors.base.map((error, i) => (
              <span className={styles.error}>
                {error}
                {i !== errors.base.length - 1 ? "," : ""}
              </span>
            ))}
          </section>
        )}
      </Form>
      <div className={styles.submit}>
        <p className={styles.confirm}>
          Please confirm your choice as this will set a precedent for all future
          job postings under your company's profile.
        </p>
        <PrimaryButton
          size="small"
          className={styles.button}
          disabled={form.vendor === "" || form.url === ""}
          onClick={handleSubmit}
          data-cy="external-submit"
        >
          Submit
        </PrimaryButton>
        <button
          onClick={() => {
            setStage("native")
          }}
          className={styles.switch}
        >
          Manage applicants via RepVue instead
        </button>
      </div>
    </div>
  )
}

export default ExternalStage
