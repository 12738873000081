import classNames from "classnames"

import React from "react"
import Select from "react-select"
import { getCustomStyles } from "./reactSelectStyles"

import styles from "./Select.module.scss"

const ReactHookSelect = ({
  isMulti,
  options,
  className,
  name,
  label,
  error,
  value,
  defaultValue,
  onChange,
  ...rest
}) => {
  return (
    <>
      <Select
        styles={getCustomStyles(error)}
        isMulti={isMulti}
        classNamePrefix="select"
        className={classNames(className, { [styles.invalid]: error })}
        id={name}
        aria-label={label}
        options={options}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        {...rest}
      />
      {error && <div className={styles.error}>{error}</div>}
    </>
  )
}

export default ReactHookSelect
