import { useEffect, useState, useCallback } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import Imgix from "react-imgix"
import debounce from "lodash/debounce"
import { searchCompanies } from "api/companies"
import GenericTooltip from "../GenericTooltip"

import Icon from "components/Icon"

import "./AddCompany.scss"

const AddCompanyModal = ({
  show,
  addCompanies,
  existingCompanies,
  handleClose,
  title,
  buttonText,
}) => {
  const [addSearchResult, setAddSearchResult] = useState([])
  const [addSearchInput, setAddSearchInput] = useState("")
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [selectedCompaniesNames, setSelectedCompaniesNames] = useState([])

  useEffect(() => {
    debounceSearchByCompany(addSearchInput)
  }, [addSearchInput])

  const searchByCompany = term => {
    searchCompanies(term)
      .then(res => {
        setAddSearchResult(res.companies)
      })
      .catch(e => {
        console.log("e", e)
      })
  }

  const debounceSearchByCompany = useCallback(
    debounce(searchByCompany, 200),
    []
  )

  const selectCompanyById = (checked, company) => {
    const temp = [...selectedCompanies]
    const tempNames = [...selectedCompaniesNames]

    if (checked) {
      temp.push(company.id)
      tempNames.push(company)
    } else {
      const index = temp.indexOf(company.id)
      temp.splice(index, 1)
      tempNames.splice(index, 1)
    }
    setSelectedCompaniesNames(tempNames)
    setSelectedCompanies(temp)
  }

  const handleSubmit = e => {
    e.preventDefault()
    addCompanies(selectedCompanies, selectedCompaniesNames)
    //Timeout so that the UI doesn't get ahead of the api call
    setTimeout(() => {
      setSelectedCompanies([])
      setSelectedCompaniesNames([])
    }, 500)
  }

  // expects the same "competitor_company" object for both talent competitor and job ads
  const existingIds = existingCompanies.map(ec => ec.competitor_company.id)

  return (
    <Modal show={show} onHide={handleClose} className="add-company-modal">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
          <div className="search">
            <Icon name="search" height="18" width="18" />
            <Form.Control
              type="search"
              placeholder="Search sales orgs"
              onChange={data => {
                setAddSearchInput(data.target.value)
              }}
              value={addSearchInput}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-competitors">
            {addSearchResult.map(company => {
              const { id, logo, name } = company
              const already = existingIds.includes(id)
              const notEligible = !company.advertisable

              return (
                <div className="company-row" key={id}>
                  {logo && (
                    <Imgix
                      className="company-logo"
                      src={`${process.env.REACT_APP_IMAGE_URL}/${logo}`}
                      alt={`${name}'s logo`}
                      width={20}
                      height={20}
                    />
                  )}
                  <span>{name}</span>
                  {notEligible ? (
                    <GenericTooltip
                      text="Jobs Ads are not eligible to be placed on RepVue customer profiles"
                      content={
                        <Form.Check
                          onChange={event => {
                            selectCompanyById(event.target.checked, {
                              id,
                              name,
                            })
                          }}
                          className={`company-toggle checkbox-disabled`}
                          type="checkbox"
                          disabled={true}
                          checked={false}
                        />
                      }
                    />
                  ) : (
                    <Form.Check
                      onChange={event => {
                        selectCompanyById(event.target.checked, { id, name })
                      }}
                      className={`company-toggle`}
                      name={`${company}-check`}
                      type="checkbox"
                      id={`${company}-check`}
                      disabled={already}
                      checked={already || null}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {selectedCompanies.length > 0 && (
            <div className="company-tags">
              {selectedCompaniesNames.map(company => {
                return (
                  <div className="company-tag" key={company.id}>
                    {company.name}
                    <Icon
                      onClick={() => {
                        selectCompanyById(false, company)
                      }}
                      name="x"
                      height="20"
                      width="20"
                    />
                  </div>
                )
              })}
            </div>
          )}
          <Button
            variant="primary"
            type="submit"
            disabled={selectedCompanies < 1}
          >
            {buttonText}
            {selectedCompanies.length >= 1 && ` (${selectedCompanies.length})`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddCompanyModal
