import { useState, useMemo } from "react"
import { useSelector } from "react-redux"
import { updateJobApplication } from "api/jobs"
import { trackAction } from "utils/mixpanel"

import cn from "classnames"
import Icon from "components/Icon"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import Card from "components/shared/Card"
import styles from "./status.module.scss"

const Status = ({
  status,
  id,
  hasTooltip = false,
  selectable = false,
  email,
}) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const [showMenu, setShowMenu] = useState(false)

  const STATUSES = useMemo(
    () => [
      { label: "New", value: "new", id: "status-1" },
      { label: "Engagement", value: "engagement", id: "status-2" },
      { label: "Declined", value: "declined", id: "status-3" },
      {
        label: "Hire unsuccessful",
        value: "hire_unsuccessful",
        id: "status-4",
      },
      { label: "Hire successful", value: "hire_successful", id: "status-5" },
    ],
    []
  )

  const STATUSLABELS = {
    new: "New",
    engagement: "Engagement",
    declined: "Declined",
    hire_unsuccessful: "Hire unsuccessful",
    hire_successful: "Hire Successful",
  }

  const renderTooltip = props => <Tooltip {...props}>Satus details</Tooltip>
  const renderTooltipAts = props => (
    <Tooltip {...props}>
      This status is pulled from your ATS & cannot be edited in RepVue
    </Tooltip>
  )

  const handleStatusUpdate = (id, value, e) => {
    e.stopPropagation()
    trackAction("Applicant Statused", {
      applicant_status: value,
      applicant_email: email,
    })
    setShowMenu(false)
    updateJobApplication(id, value)
      .then(() => window.location.reload())
      .catch(error => console.error(error))
  }

  return (
    <section className={styles.status__container}>
      <article className={styles.status}>
        <div
          className={cn(styles.status__select, {
            [styles.status__select__new]: STATUSLABELS.new === status,
            [styles.status__select__engagement]:
              STATUSLABELS.engagement === status,
            [styles.status__select__declined]: STATUSLABELS.declined === status,
            [styles.status__select__hireUnsuccessful]:
              STATUSLABELS.hire_unsuccessful === status,
            [styles.status__select__hireSuccessful]:
              STATUSLABELS.hire_successful === status,
          })}
          onClick={() => {
            if (selectable) setShowMenu(!showMenu)
          }}
        >
          {!!!currentEmployer?.company?.integration && <span>{status}</span>}
          {!!!currentEmployer?.company?.integration && selectable && (
            <span
              className={cn(styles.status__arrowDown, {
                [styles.status__arrowDownNew]: STATUSLABELS.new === status,
                [styles.status__arrowDownEngagement]:
                  STATUSLABELS.engagement === status,
                [styles.status__arrowDownDeclined]:
                  STATUSLABELS.declined === status,
                [styles.status__arrowDownHireUnsuccessfull]:
                  STATUSLABELS.hire_unsuccessful === status,
                [styles.status__arrowDownHireSuccessfull]:
                  STATUSLABELS.hire_successful === status,
              })}
            ></span>
          )}
          {!!currentEmployer?.company?.integration && <span>{status}</span>}
        </div>
        {showMenu && !!!currentEmployer?.company?.integration && (
          <Card className={styles.status__select__options}>
            <div onMouseLeave={() => setShowMenu(false)}>
              {STATUSES.map(item => {
                if (item.label === "New") return null
                return (
                  <div
                    className={styles.status__select__options__item}
                    key={item.id}
                    onClick={e => {
                      handleStatusUpdate(id, item.value, e)
                    }}
                  >
                    <span>{item.label}</span>
                    <span className={styles.status__select__options__icon}>
                      <Icon name="interrogation" height="16" width="16" />
                    </span>
                  </div>
                )
              })}
            </div>
          </Card>
        )}
      </article>

      {hasTooltip && (
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 100 }}
            overlay={
              !!currentEmployer?.company?.integration
                ? renderTooltipAts
                : renderTooltip
            }
          >
            <Button variant="link" className={styles.status__information}>
              <span className={styles.status__select__options__icon}>
                <Icon name="interrogation" height="18" width="18" />
              </span>
            </Button>
          </OverlayTrigger>
        </div>
      )}
    </section>
  )
}

export default Status
