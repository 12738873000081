import StarRating from "components/StarRating"
import styles from "./rowScore.scoresWidget.module.scss"

const RowScore = ({ score = 0, label }) => {
  return (
    <div className={styles.rowScore}>
      <span className={styles.rowScore__score}>
        {parseFloat(score).toFixed(1)}
      </span>
      <StarRating rating={score} starValues={[1]} />
      <span className={styles.rowScore__label}>{label}</span>
    </div>
  )
}

export default RowScore
