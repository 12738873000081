import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"
import FeaturesProtect from "./features"
import styles from "./protect.module.scss"
import { PROTECT_ROUTES } from "constants/protect"

const ProtectRoute = ({ path }) => {
  const EMAIL = "support@repvue.com"

  const sendEmail = () => {
    window.location = `mailto:${EMAIL}`
  }

  return (
    <div className={styles.protectRoute}>
      <Header title={PROTECT_ROUTES[path]?.title} />
      <Content>
        <h3>{PROTECT_ROUTES[path]?.title}</h3>
        <p className={styles.protectRoute__information}>
          An enterprise account is required to access this feature. Interested in
          upgrading? <a href="mailto:support@repvue.com">Contact Us</a>
        </p>

        <section className={styles.protectRoute__container}>
          <figure className={styles.protectRoute__icon}>
            <Icon name={PROTECT_ROUTES[path]?.icon} height={50} width={50} />
          </figure>

          <h5 className={styles.protectRoute__title}>
            {PROTECT_ROUTES[path]?.subtitle}
          </h5>
          <p className={styles.protectRoute__description}>
            {PROTECT_ROUTES[path]?.description}
          </p>

          <FeaturesProtect features={PROTECT_ROUTES[path]?.items || []} />

          <p className={styles.protectRoute__description}>
            Want to learn more? Let our team know you’re interested!
          </p>
          <div className={styles.protectRoute__actions}>
            <PrimaryButton onClick={sendEmail}>
              <span>I’m Interested in Learning More</span>
            </PrimaryButton>
          </div>
        </section>
      </Content>
    </div>
  )
}

export default ProtectRoute
