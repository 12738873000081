const CurrencyCell = ({ value }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  })

  return <span>{value ? formatter.format(value) : "--"}</span>
}
export default CurrencyCell
