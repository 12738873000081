import { useEffect, useState, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { Button, ProgressBar } from "react-bootstrap"
import { searchCompanies, getCompanyById } from "api/companies"
import BattlecardPrint from "components/BattlecardPrint"
import {
  createBattlecard,
  getBattlecardById,
  updateBattleCard,
} from "api/battlecards"
import Imgix from "react-imgix"
import debounce from "lodash/debounce"
import { pageCall } from "utils/mixpanel"

import Icon from "components/Icon"
import Content from "components/Content"
import Header from "components/Header"
import StarRating from "components/StarRating"
import SelectInput from "components/shared/SelectInput"

import styles from "./New.module.scss"

const BattlecardsNewRoute = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { currentEmployer } = useSelector(state => state.auth)
  const [userCompany, setUserCompany] = useState(null)
  const [battlecards, setBattlecards] = useState([])
  const [companyColumns, setCompanyColumns] = useState([createEmptyCompany()])
  const [searchTerm, setSearchTerm] = useState("")
  const [canSave, setCanSave] = useState(false)
  const [readyForDownload, setReadyForDownload] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (currentEmployer.company.id) {
      getMetricsFromEmployerId()
      debounceSearchByCompany(searchTerm)
    }
  }, [currentEmployer])

  useEffect(() => {
    debounceSearchByCompany(searchTerm)
  }, [searchTerm])

  function createEmptyCompany() {
    return {
      company_name: null,
      company_id: `t${Math.round(Math.random() * 10000000)}`,
      company_logo: null,
      quota: 19.4,
      repvue_score: 44,
      ratings: 244,
      product_market_fit: "1.5",
      professional_dev: "2.7",
      culture: "3.8",
      incentive: "4.1",
      base_compensation: "4.1",
      inbound: "4.2",
      diversity: "4.3",
    }
  }

  const getMetricsFromEmployerId = async () => {
    const companyMetrics = await fetchCompanyById(currentEmployer.company.id)
    const fusion = {
      company_name: currentEmployer.company.name,
      company_logo: currentEmployer.company.logo,
      ...companyMetrics,
    }
    setUserCompany(fusion)
  }

  const searchByCompany = term => {
    searchCompanies(term)
      .then(res => {
        const resArray = res.companies.map(company => {
          return {
            value: company.id,
            label: company.name,
            logo: company.logo,
          }
        })
        setBattlecards(resArray)
      })
      .catch(e => {
        console.log("e", e)
      })
  }

  const fetchCompanyById = id => {
    return getCompanyById(id)
      .then(res => {
        return res
      })
      .catch(err => {
        return err
      })
  }

  const debounceSearchByCompany = useCallback(
    debounce(searchByCompany, 200),
    []
  )

  const insertNewCompany = () => {
    setCompanyColumns(state => [...state, createEmptyCompany()])
  }

  const removeLastCompany = () => {
    setCompanyColumns(state => state.slice(0, -1))
  }

  const closeWindow = () => {
    navigate("/battlecards")
  }

  const saveBattleCard = () => {
    createBattlecard(
      companyColumns[0].company_id,
      companyColumns[1]?.company_id
    )
      .then(res => console.log("res", res))
      .catch(e => console.log("saveBattleCard", e))
  }

  const saveCloseBattleCard = () => {
    saveBattleCard()
    setTimeout(() => {
      closeWindow()
    }, 2000)
  }

  const editBattleCard = () => {
    setCanSave(false)

    const payload = {
      opponent_company_1_id: companyColumns[0].company_id,
      opponent_company_2_id: companyColumns[1]?.company_id || null,
    }

    updateBattleCard(id, payload)
      .then(() => closeWindow())
      .catch(e => console.log("saveBattleCard", e))
      .finally(() => {
        setCanSave(true)
      })
  }

  const selectCompany = async (data, index) => {
    const selectedCompany = await fetchCompanyById(data.value)
    selectedCompany.current_company_metric_scores.sort((a, b) =>
      a.name.localeCompare(b.name)
    )

    setCompanyColumns(state => {
      const temp = [...state]
      const updatedCompany = {
        company_id: data.value,
        company_name: data.label,
        company_logo: data.logo,
        ...selectedCompany,
      }
      temp[index] = { ...temp[index], ...updatedCompany }

      return temp
    })
    setCanSave(true)
  }

  useEffect(() => {
    getBattlecardById(id)
      .then(response => {
        const temp = {
          label: response.opponent_company_1.name,
          logo: response.opponent_company_1.logo,
          value: response.opponent_company_1.id,
        }

        selectCompany(temp, 0)

        if (response?.opponent_company_2) {
          insertNewCompany()

          const temp_2 = {
            label: response.opponent_company_2.name,
            logo: response.opponent_company_2.logo,
            value: response.opponent_company_2.id,
          }

          selectCompany(temp_2, 1)
        }
      })
      .catch(() => setError(true))
  }, [id])

  useEffect(() => {
    pageCall("Battlecards", "Employer Brand")
  }, [])

  return (
    <div className={`${styles.createBattlecard} scroll-container`}>
      <Header
        title="Battlecards"
        path={id ? "Edit Battlecard" : "New Battlecard"}
        basePath="/battlecards"
      />
      <Content>
        <div className={styles.wrapper}>
          <div className={styles.titleWrapper}>
            <h3>{id ? "Edit a Battlecard" : "Create a New Battlecard"}</h3>
          </div>
          {error && id ? (
            <section className={styles.container__error}>
              <p>Battlecard not found</p>
            </section>
          ) : (
            <section className={styles.container}>
              <div className={styles.inner}>
                <div className={styles.detailsColumn}>
                  <div className={styles.detailsRow}>&nbsp;</div>
                  <div className={styles.detailsRow}>
                    % of Team Achieving Quota
                  </div>
                  <div className={styles.detailsRow}>RepVue Score</div>
                  <div className={styles.detailsRow}>Total Ratings</div>
                  {userCompany?.current_company_metric_scores?.map(
                    ({ name }) => {
                      return (
                        <div className={styles.detailsRow} key={name}>
                          {name}
                        </div>
                      )
                    }
                  )}
                </div>
                {userCompany && (
                  <div className={styles.companyColumn}>
                    <div
                      className={`${styles.companyRow}  ${styles.myCompany}`}
                    >
                      <Imgix
                        className={styles.iconCompany}
                        src={`${process.env.REACT_APP_IMAGE_URL}/${
                          userCompany.logo || userCompany.company_logo
                        }`}
                        alt={userCompany.name || userCompany.company_name}
                        width={24}
                        height={24}
                      />
                      {userCompany.name || userCompany.company_name}
                    </div>
                    <div className={styles.companyRow}>
                      {Number(userCompany.quota_attainment).toFixed(1)}%{" "}
                      <ProgressBar now={userCompany.quota_attainment} />
                    </div>
                    <div className={styles.companyRow}>
                      {userCompany.repvue_score}
                    </div>
                    <div className={styles.companyRow}>
                      {userCompany.ratings_count}
                    </div>
                    {userCompany.current_company_metric_scores.map(
                      ({ raw_average }) => {
                        return (
                          <div className={styles.companyRow} key={raw_average}>
                            <div className={styles.companyRow__starSection}>
                              <span
                                className={
                                  styles.companyRow__starSection__value
                                }
                              >
                                {raw_average}
                              </span>
                              <StarRating
                                rating={raw_average}
                                readyForDownload={readyForDownload}
                              />
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
                {companyColumns.map((company, index) => {
                  const metricsFromUser =
                    userCompany?.current_company_metric_scores?.map(
                      metric => metric.name
                    )
                  const orderedMetrics = metricsFromUser?.map(metric => {
                    let sorted
                    company?.current_company_metric_scores?.forEach(
                      companyMetrics => {
                        if (metric === companyMetrics.name) {
                          sorted = companyMetrics
                        }
                      }
                    )
                    return sorted
                  })

                  return (
                    <div className={styles.companyColumn} key={company.id}>
                      <div className={styles.companyRow}>
                        <SelectInput
                          onChange={data => {
                            selectCompany(data, index)
                          }}
                          onInputChange={data => {
                            setSearchTerm(data)
                          }}
                          className={styles.companyDropdown}
                          name="job_role"
                          optionsWithLogo={true}
                          value={{
                            value: companyColumns[index].company_id,
                            label: companyColumns[index].company_name,
                            logo: companyColumns[index].company_logo,
                          }}
                          placeholder="Search to add org"
                          options={battlecards}
                        />
                      </div>
                      {company.company_name && (
                        <>
                          <div className={styles.companyRow}>
                            {Number(company.quota_attainment).toFixed(1)}%{" "}
                            <ProgressBar now={company.quota_attainment} />
                          </div>
                          <div className={styles.companyRow}>
                            {company.repvue_score}
                          </div>
                          <div className={styles.companyRow}>
                            {company.ratings_count}
                          </div>
                          {orderedMetrics?.map(({ raw_average }) => {
                            return (
                              <div
                                className={styles.companyRow}
                                key={raw_average}
                              >
                                <div className={styles.companyRow__starSection}>
                                  <span
                                    className={
                                      styles.companyRow__starSection__value
                                    }
                                  >
                                    {raw_average}
                                  </span>
                                  <StarRating
                                    rating={raw_average}
                                    readyForDownload={readyForDownload}
                                  />
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>
                  )
                })}
                <div className={styles.plusColumn}>
                  {companyColumns.length === 2 ? (
                    <div className={styles.plusIcon}>
                      <Icon
                        name="close"
                        height="20"
                        width="20"
                        onClick={removeLastCompany}
                      />
                    </div>
                  ) : (
                    <div className={styles.plusIcon}>
                      <Icon
                        name="add"
                        height="20"
                        width="20"
                        onClick={insertNewCompany}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}

          <div className={styles.footer}>
            <Button
              className={`${styles.footerButton} ${styles.whiteButton}`}
              onClick={closeWindow}
            >
              Cancel
            </Button>
            <Button
              className={`${styles.footerButton} ${styles.whiteButton}`}
              onClick={() => {
                setReadyForDownload(true)
              }}
              disabled={!canSave}
            >
              Download
              <Icon name="download" height="20" width="20" />
            </Button>

            <Button
              className={`${styles.footerButton}`}
              onClick={id ? editBattleCard : saveCloseBattleCard}
              disabled={!canSave}
            >
              Save & Close
            </Button>
          </div>
        </div>
      </Content>
      <BattlecardPrint
        companies={[userCompany, ...companyColumns]}
        downloading={readyForDownload}
        afterDownload={() => {
          setReadyForDownload(false)
        }}
      />
    </div>
  )
}

export default BattlecardsNewRoute
