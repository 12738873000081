import apiCall from "utils/api"

export const getTechStackVendors = () => {
  return apiCall("get", `tech_stack_vendors`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}