import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"
import styles from "./headerTeamMember.module.scss"

const HeaderTeamMembers = ({ members, handleInviteMember }) => {
  return (
    <section className={styles.headerTeamMember}>
      <article>
        <div className={styles.headerTeamMember__title}>
          <h4>Team Members</h4>
          <span>{members}</span>
        </div>
        <p className={styles.headerTeamMember__description}>
          Add and manage all system users below
        </p>
      </article>
      <article className={styles.headerTeamMember__actions}>
        <PrimaryButton onClick={handleInviteMember}>
          <Icon name="add" height="20" width="20" />
          <span>Invite Team Member</span>
        </PrimaryButton>
      </article>
    </section>
  )
}

export default HeaderTeamMembers
