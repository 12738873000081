import { toast } from "react-hot-toast"
import Icon from "components/Icon"

const ErrorToast = ({ t, headline, body }) => {
  return (
    <div className="toast-error">
      <div className="toast-error-wrapper">
        <div className="toast-error-header">
          <Icon
            name="close"
            height="20"
            width="20"
            onClick={() => {
              toast.remove(t.id)
            }}
            className="toast-error-close"
          />
        </div>
        <div className="toast-error-body">
          <Icon name="x" height="30" width="30" />
          <div className="toast-error-children">
            <div className="toast-error-text toast-error-headline">
              {headline}
            </div>
            <div className="toast-error-text">{body}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorToast
