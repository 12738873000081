import apiCall from "utils/api"

export const getEmployersFormOptions = () => {
  return apiCall("get", `employers/form_options`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Company Jobs", error)
      throw error
    })
}

export const employersRegister = params => {
  const options = { data: params }
  return apiCall("post", `employers`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("register employers", error)
      throw error
    })
}

export const getEmployerProfile = () => {
  return apiCall("get", `employer/company`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("profile employers", error)
      throw error
    })
}
