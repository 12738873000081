import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getBattlecards, deleteBattlecardById } from "api/battlecards"
import { pageCall } from "utils/mixpanel"
import { Button, Form, Tab, Tabs, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"

import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"
import Battlecard from "components/Battlecard"

import "./Battlecards.scss"

const NoBattleCards = () => (
  <div className="no-battlecards-wrapper">
    <div className="no-battlecards-icon">
      <Icon name="battlecards" height="53" width="53" />
    </div>
    <div className="no-battlecards-title">No battlecards yet</div>
    <div className="no-battlecards-desc">
      Battlecards compare your own sales org to 1-2 other orgs in several
      categories. These include RepVue score, quota attainment, & more! Get
      started by creating a battlecard from scratch.
    </div>
  </div>
)

const TabContentHeader = ({ onInputChange }) => {
  return (
    <div className="tab-header">
      <div className="search">
        <Icon name="search" height="18" width="18" />
        <Form.Control
          type="text"
          placeholder="Search battlecards"
          onChange={onInputChange}
        />
      </div>
    </div>
  )
}

const TabContent = ({ cards, company, onDelete }) => {
  if (cards.length === 0) {
    return null
  }
  return (
    <div className="tab-wrapper">
      <div className="battlecard-wrapper">
        {cards.map(card => (
          <Battlecard
            key={card.id}
            id={card.id}
            opponent1={company}
            opponent2={card.opponent_company_1}
            opponent3={card.opponent_company_2}
            onDelete={onDelete}
          ></Battlecard>
        ))}
      </div>
    </div>
  )
}

const BattlecardsRoute = () => {
  const [battlecards, setBattlecards] = useState([])
  const [searchBattlecards, setSearchBattlecards] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [loading, setLoading] = useState(true)
  const { currentEmployer } = useSelector(state => state.auth)

  useEffect(() => {
    let temp = [...searchBattlecards]

    if (searchInput !== "") {
      temp = temp.filter(
        company =>
          company?.opponent_company_1?.name
            ?.toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          company?.opponent_company_2?.name
            ?.toLowerCase()
            .includes(searchInput.toLowerCase())
      )
    } else {
      temp = battlecards
    }
    setSearchBattlecards(temp)
  }, [searchInput])

  useEffect(() => {
    fetchBattlecards()
  }, [])

  const fetchBattlecards = () => {
    getBattlecards()
      .then(res => {
        setBattlecards(res.battle_cards)
        setSearchBattlecards(res.battle_cards)
        setLoading(false)
        //TO DO
        // setTalentCards()
      })
      .catch(e => {
        console.log("e", e)
      })
  }

  const deleteBattlecard = id => {
    deleteBattlecardById(id)
      .then(() => {
        fetchBattlecards()
      })
      .catch(err => {
        console.log("deleteBattlecard error", err)
      })
  }

  const onInputChange = event => {
    setSearchInput(event.target.value)
  }

  useEffect(() => {
    pageCall("Battlecards", "Employer Brand")
  }, [])

  return (
    <div className="battlecards scroll-container">
      <Header title="Battlecards" />
      <Content>
        <div className="title-wrapper">
          <h3>Battlecards</h3>
          <div className="button">
            <Link to="/battlecards/new">
              <Button>
                <Icon name="add" height="20" width="20" />
                New Battlecard
              </Button>
            </Link>
          </div>
        </div>
        <div className="wrapper">
          {loading ? (
            <div className="spinner-wrapper">
              <Spinner animation="border" />
            </div>
          ) : (
            <Tabs defaultActiveKey="all">
              <Tab
                eventKey="all"
                title={
                  <>
                    All
                    <span>{battlecards.length}</span>
                  </>
                }
              >
                {battlecards.length > 0 ? (
                  <>
                    <TabContentHeader onInputChange={onInputChange} />
                    <TabContent
                      company={currentEmployer.company}
                      cards={searchBattlecards}
                      onDelete={deleteBattlecard}
                    />
                  </>
                ) : (
                  <NoBattleCards />
                )}
              </Tab>
            </Tabs>
          )}
        </div>
      </Content>
    </div>
  )
}

export default BattlecardsRoute
