import React from "react"
import cn from "classnames"
import Form from "react-bootstrap/Form"
import styles from "./LabelInput.module.scss"

const LabelInput = ({ label, isRequired, className }) => {
  return (
    <Form.Label className={cn(styles.labelInput, className)}>
      {label} {isRequired && <span className={styles.required}>*</span>}
    </Form.Label>
  )
}

export default LabelInput
