import { Link, Outlet } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"

import logo from "assets/images/repvue.svg"

import "./Auth.scss"

function AuthRoute() {
  return (
    <Container fluid>
      <Row>
        <Col lg={{ span: 5 }} xxl={{ span: 4 }} className="p-0">
          <div className="auth-left-col">
            <Link to="/sign_in">
              <img src={logo} alt="RepVue" />
            </Link>
            <div className="auth-body">
              <strong>Hire Top-Tier Sales Talent</strong>
              <p>
                Your future sales hire is looking at your profile on RepVue.
                <br />
                Claim your profile to make your case on why they should join
                your&nbsp;organization!
              </p>
            </div>
          </div>
        </Col>
        <Col
          xxl={{ span: 8 }}
          lg={{ span: 7 }}
          className="d-flex flex-column justify-content-center align-items-center min-vh-100"
        >
          <div className="outlet-wrapper">
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default AuthRoute
