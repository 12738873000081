import Card from "components/shared/Card"
import Score from "./Score"
import RatingHeathOrganization from "./Ratings"
import styles from "./ScoreCard.module.scss"

const ScoreCard = ({ data }) => (
  <div className={styles.scoreCard}>
    <Card className={styles.scoreCard__card}>
      <Score data={data} />
      <RatingHeathOrganization data={data} />
    </Card>
  </div>
)

export default ScoreCard
