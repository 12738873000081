import { deleteTalentProfile } from "api/talent"
import { useNavigate } from "react-router-dom"

import Modal from "react-bootstrap/Modal"

import "./DeleteModal.scss"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import PrimaryButton from "components/shared/Buttons/Primary"

const DeleteModal = ({ show, modalClose }) => {
  const navigate = useNavigate()

  const deleteProfile = id => {
    deleteTalentProfile(id)
      .then(res => {
        modalClose()
        navigate("/talent-profiles")
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleClose = () => {
    modalClose()
  }

  return (
    <Modal show={show} onHide={handleClose} className="delete-modal-container">
      <Modal.Body>
        <div className="profile-modal-header">
          <span>Are you sure you want to delete this talent profile?</span>
        </div>
        <p>You can't undo this.</p>
        <div className="profile-modal-footer">
          <SecondaryLightButton onClick={handleClose} size="large">
            Never Mind
          </SecondaryLightButton>
          <PrimaryButton onClick={() => deleteProfile(show)} size="large">
            Delete
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteModal
