import { useState } from "react"
import { useSelector } from "react-redux"
import Icon from "components/Icon"
import Card from "components/shared/Card"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import styles from "./actions.module.scss"

const Actions = () => {
  const { currentEmployer } = useSelector(state => state.auth)
  const [showMenu, setShowMenu] = useState(false)

  return (
    <article className={styles.actions}>
      <div className={styles.actions__button}>
        <SecondaryLightButton onClick={() => setShowMenu(true)}>
          <Icon name="dots" height="22" width="22" />
        </SecondaryLightButton>
      </div>
      {showMenu && (
        <Card className={styles.actions__menu}>
          <div onMouseLeave={() => setShowMenu(false)}>
            <div className={styles.actions__menu__item}>
              <span>Send an Email</span>
            </div>

            <div className={styles.actions__menu__item}>
              <span>Linkedin</span>
              <span className={styles.actions__menu__icon}>
                <Icon name="external-link" height="16" width="16" />
              </span>
            </div>
            {!!currentEmployer?.company?.integration && (
              <div className={styles.actions__menu__item}>
                <span>View in ATS</span>
                <span className={styles.actions__menu__icon}>
                  <Icon name="external-link" height="16" width="16" />
                </span>
              </div>
            )}
          </div>
        </Card>
      )}
    </article>
  )
}

export default Actions
