export const generateMenuItems = isExternal => [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "house",
  },
  {
    title: "Sales Org Health",
    path: "/health",
    icon: "trending-up-arrow",
  },
  {
    title: "Talent",
    icon: "search",
    subItems: [
      { title: "Talent Data", path: "/talent-data" },
      { title: "Talent Profiles", path: "/talent-profiles" },
      { title: "Talent Competitors", path: "/competitors" },
    ],
  },
  {
    title: "Jobs",
    path: "/jobs",
    icon: "briefcase",
    subItems: [
      { title: "Job Listings", path: "/jobs" },
      { title: "Job Ads", path: "/ads" },
    ],
  },
  {
    title: "Applicants",
    path: "/applicants",
    icon: "people",
    restricted: isExternal,
  },
  {
    title: "Battlecards",
    path: "/battlecards",
    icon: "star",
  },
  {
    title: "RepVue Profile",
    path: "/profile",
    icon: "building",
    subItems: [
      { title: "Profile Overview", path: "/profile" },
      { title: "Reviews", path: "/reviews" },
      { title: "Questions on Q&A", path: "/questions" },
    ],
  },
]
