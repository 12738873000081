import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import cn from "classnames"
import { updateJob } from "api/jobs"
import Card from "components/shared/Card"
import styles from "./status.module.scss"

const Status = ({ job, selectable }) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const { id } = job

  const [showMenu, setShowMenu] = useState(false)
  const [status, setStatus] = useState(job?.status)

  const altStatus = status === "active" ? "Closed" : "Active"

  const STATUSES = {
    pending_approval: "Pending approval",
    active: "Active",
    closed: "Closed",
    rejected: "Declined",
  }

  const handleStatusUpdate = (id, status, e) => {
    e.stopPropagation()
    setStatus(status)
    setShowMenu(false)
    updateJob(id, { job: { status } })
  }

  useEffect(() => {
    setStatus(job?.status)
  }, [job])

  return (
    <article className={styles.status}>
      <div
        className={cn(styles.status__select, {
          [styles.status__select__closed]:
            status === "closed" || status === "rejected",
          [styles.status__select__active]: status === "active",
          [styles.status__select__pending]: status === "pending_approval",
          [styles.status__select__ats]: !!currentEmployer?.company?.integration,
        })}
        onClick={() => {
          if (selectable) setShowMenu(!showMenu)
        }}
      >
        {!!!currentEmployer?.company?.integration && (
          <span>{STATUSES[status]}</span>
        )}
        {!!!currentEmployer?.company?.integration && selectable && (
          <span
            className={cn(styles.status__arrowDown, {
              [styles.status__arrowDownClosed]: status === "closed",
              [styles.status__arrowDownActive]: status === "active",
              [styles.status__arrowDownPending]: status === "pending_approval",
            })}
          ></span>
        )}
        {!!currentEmployer?.company?.integration && (
          <span>{STATUSES[status]}</span>
        )}
      </div>
      {showMenu && !!!currentEmployer?.company?.integration && (
        <Card className={styles.status__select__options}>
          <div onMouseLeave={() => setShowMenu(false)}>
            <div
              className={styles.status__select__options__item}
              key={status}
              onClick={e => {
                handleStatusUpdate(
                  id,
                  status === "closed" ? "active" : "closed",
                  e
                )
              }}
            >
              <span>{altStatus}</span>
            </div>
          </div>
        </Card>
      )}
    </article>
  )
}

export default Status
