import { useEffect, useState } from "react"
import Card from "components/shared/Card"
import StackedBarChart from "components/shared/StackedBarChart"
import RangeDates from "components/shared/StackedBarChart/RangeDates"
import styles from "./ProfilePageViews.module.scss"

const ProfilePageViews = ({ data, getViews }) => {
  const [dataCategories, setDataCategories] = useState({})
  const [labels, setLabels] = useState([])
  const [monthFilterSelected, setMonthFilterSelected] = useState(false)

  const [datasets, setDatasets] = useState([])

  const formatData = data => {
    const KEYS = Object.keys(data).sort()

    const labelsMap = KEYS.map(label => {
      const date = new Date(`${label}T00:00-0800`)
      const month = date.toLocaleString("en-us", { month: "short" })
      const year = date.getFullYear()
      const day = date.toLocaleString("en-us", { day: "numeric" })
      return [month, monthFilterSelected ? year : day]
    })

    const dataMap = {
      jobs: [],
      salaries: [],
      benefits: [],
      other: [],
      culture: [],
      overview: [],
    }

    setLabels(labelsMap)

    KEYS.forEach(key => {
      const categories = Object.keys(data[key])
      const row = data[key]

      categories.forEach(cat => {
        dataMap[cat].push(row[cat])
      })
    })

    setDataCategories(dataMap)
  }

  useEffect(() => {
    formatData(data)
  }, [data])

  useEffect(() => {
    setDatasets([
      {
        label: "Other",
        data: dataCategories?.other,
        backgroundColor: "#C43838",
        borderRadius: 8,
      },
      {
        label: "Culture",
        data: dataCategories?.culture,
        backgroundColor: "#FF8D5D",
        borderRadius: 8,
      },
      {
        label: "Benefits",
        data: dataCategories?.benefits,
        backgroundColor: "#79DAB7",
        borderRadius: 8,
      },
      {
        label: "Salaries",
        data: dataCategories?.salaries,
        backgroundColor: "#7CB342",
        borderRadius: 8,
      },
      {
        label: "Jobs",
        data: dataCategories?.jobs,
        backgroundColor: "#FFDB77",
        borderRadius: 8,
      },
      {
        label: "Overview",
        data: dataCategories?.overview,
        backgroundColor: "#3E99DC",
        borderRadius: 8,
      },
    ])
  }, [dataCategories])

  return (
    <div className={styles.score}>
      <Card className={styles.score__card}>
        <div className={styles.score__card__header}>
          <h5 className={styles.title}>RepVue Profile Page Views</h5>
          <RangeDates
            getViews={getViews}
            setMonthFilterSelected={setMonthFilterSelected}
          />
        </div>

        <div className={styles.score__line}>
          <StackedBarChart labels={labels} dataSets={datasets} title="Views" />
        </div>
      </Card>
    </div>
  )
}

export default ProfilePageViews
