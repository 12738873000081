import { useEffect } from "react"
import { htmlStripper, cleanTrim } from "utils/string"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { pageCall } from "utils/mixpanel"

import Icon from "components/Icon"

import styles from "./StepThree.module.scss"

const StepThree = ({ setStep, form, isEditing, isEditingAndIntegrated }) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const {
    title,
    location_type,
    role,
    metro_location_ids,
    sales_division,
    years_of_experience,
    external_url,
  } = form
  const { description } = form

  const strippedDescription = description && htmlStripper(description)

  useEffect(() => {
    pageCall("Post Job Review", "Jobs")
  }, [])

  return (
    <div className={styles.wrapper}>
      <article className={styles.confirm}>Please confirm all job details before submission as any changes will require review before the job is activated</article>
      <div className={styles.card}>
        <div className={styles.headline}>
          <h5>Overview</h5>
          <button
            className={classNames("btn btn-sm", styles.edit)}
            onClick={() => setStep(1)}
          >
            Edit
            <Icon name="edit-square" height="14" width="14" />
          </button>
        </div>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>Job Title</th>
              <td>{cleanTrim(title, 100, true)}</td>
            </tr>
            <tr>
              <th>Primary Role</th>
              <td>{role}</td>
            </tr>
            <tr>
              <th>Location Type</th>
              <td>{location_type}</td>
            </tr>
            <tr>
              <th>Location(s)</th>
              <td>
                {metro_location_ids?.length > 0
                  ? metro_location_ids.map((location, i) => (
                      <div
                        key={location.name}
                        className={classNames("badge rounded-pill bg-primary", {
                          "me-2": i < (metro_location_ids?.length || 0) - 1,
                        })}
                      >
                        {location.name}
                      </div>
                    ))
                  : String.fromCharCode(8212)}
              </td>
            </tr>
            <tr>
              <th>Sales Division</th>
              <td>{sales_division || String.fromCharCode(8212)}</td>
            </tr>
            <tr>
              <th>Years of Experience</th>
              <td>{years_of_experience || String.fromCharCode(8212)}</td>
            </tr>
            {currentEmployer?.job_board?.applicant_method === "External" && (
              <tr>
                <th>Tracking Link</th>
                <td>{external_url || String.fromCharCode(8212)}</td>
              </tr>
            )}
            {/* <tr>
              <th>OTE Earnings Range</th>
              <td>{`${ote_min || String.fromCharCode(8212)} - ${ote_max || String.fromCharCode(8212)}`}</td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <div className={styles.card}>
        <div className={styles.headline}>
          <h5>Job Description</h5>
          {!isEditingAndIntegrated && (
            <button
              className={classNames("btn btn-sm", styles.edit)}
              onClick={() => setStep(2)}
            >
              Edit
              <Icon name="edit-square" height="14" width="14" />
            </button>
          )}
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strippedDescription || "",
          }}
        ></div>
      </div>
    </div>
  )
}

export default StepThree
