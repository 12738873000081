import React from "react"
import { number, func, string, oneOfType } from "prop-types"
import cn from "classnames"
import styles from "./linearScore.module.scss"

const LinearScoreComponent = ({ getBlobColor, progress, topCompanies }) => {
  return (
    <section className={styles.linearScore}>
      <article className={styles.linearScore__information}>
        {topCompanies && (
          <span className={styles.linearScore__information__top}>
            <span className={styles.linearScore__information__top__percentage}>
              Top {topCompanies}%{" "}
            </span>
            of sales orgs
          </span>
        )}
      </article>

      <article className={styles.linearScore__graphic}>
        <div className={styles.linearScore__linesContainer}>
          <span
            style={{
              left: `calc(${progress}% - 1.3rem )`,
              borderColor: getBlobColor(progress),
            }}
            className={styles.linearScore__linesContainer__indicator}
          ></span>

          <span
            className={cn(
              styles.linearScore__linesContainer__lines,
              styles.linearScore__linesContainer__lines__red
            )}
          ></span>
          <span
            className={cn(
              styles.linearScore__linesContainer__lines,
              styles.linearScore__linesContainer__lines__orange
            )}
          ></span>
          <span
            className={cn(
              styles.linearScore__linesContainer__lines,
              styles.linearScore__linesContainer__lines__yellow
            )}
          ></span>
          <span
            className={cn(
              styles.linearScore__linesContainer__lines,
              styles.linearScore__linesContainer__lines__lightGreen
            )}
          ></span>
          <span
            className={cn(
              styles.linearScore__linesContainer__lines,
              styles.linearScore__linesContainer__lines__green
            )}
          ></span>
        </div>
      </article>
    </section>
  )
}

LinearScoreComponent.propTypes = {
  progress: oneOfType([string, number]).isRequired,
  getBlobColor: func.isRequired,
  topCompanies: number,
}

export default LinearScoreComponent
