import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Row, Col, Spinner } from "react-bootstrap"
import { getReviews } from "api/reviews"
import { pageCall } from "utils/mixpanel"

import Header from "components/Header"
import ReviewsTabs from "components/reviews/tabs"
import ReviewsList from "components/reviews/listReviews"
import ReviewsFilters from "components/reviews/filters"
import Pagination from "components/talentData/Table/Pagination"

import "./Reviews.scss"

const TABS = [
  { label: "All", value: "total" },
  { label: "Replied To", value: "responded", query: { responded: true } },
  {
    label: "Not Replied To",
    value: "not_responded",
    query: { responded: false },
  },
]

const ReviewsRoute = () => {
  const { currentEmployer } = useSelector(state => state.auth)
  const [loading, setLoading] = useState(true)
  const [reviewSelect, setReviewSelect] = useState(null)
  const [activeTab, setActiveTab] = useState({ label: "All", value: "total" })
  const [reviews, setReviews] = useState([])
  const [paginationMeta, setPaginationMeta] = useState({})
  const [reviewsSectionCounts, setReviewsSectionCounts] = useState({})
  const [pinnedCount, setPinnedCount] = useState(0)
  const [globalFilters, setGlobalFilters] = useState({
    sort_key: "ratings.created_at&sort_direction=desc",
  })

  const fetchReviews = (allowLoad = true, page = 1) => {
    const payload = {
      ...activeTab?.query,
      ...globalFilters,
      page,
      per_page: 20,
    }
    allowLoad && setLoading(true)

    getReviews(payload)
      .then(({ data }) => {
        const { reviews, meta } = data
        setReviews(reviews)
        setPaginationMeta(meta?.page_info)
        setReviewsSectionCounts(meta?.reviews_counters)
        setPinnedCount(meta?.reviews_counters?.pinned_reviews)
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  const handleChange = page => {
    fetchReviews(true, page)
  }

  useEffect(() => {
    fetchReviews()
  }, [activeTab, globalFilters])

  useEffect(() => {
    pageCall("Reviews", "RepVue Profile")
  }, [])

  return (
    <>
      <Header title="Respond to Employee Reviews" />
      <div className="reviews content">
        <h3>Employee Reviews</h3>
        <p>
          Respond to the reviews that anonymous employees have submitted along
          their ratings.
        </p>
        <Col lg={12}>
          <Row>
            <div className="wrapper">
              <ReviewsTabs
                counts={reviewsSectionCounts}
                changeCurrentTab={setActiveTab}
                activeTab={activeTab}
                tabs={TABS}
                reviewSelect={reviewSelect}
                pinnedCount={pinnedCount}
              />

              <ReviewsFilters
                reviewSelect={reviewSelect}
                setGlobalFilters={setGlobalFilters}
              />
            </div>
          </Row>

          <Row>
            <Col lg={8}>
              {!loading ? (
                <>
                  <ReviewsList
                    reviews={reviews}
                    company={currentEmployer?.company}
                    reviewSelect={reviewSelect}
                    setReviewSelect={setReviewSelect}
                    updateReviews={fetchReviews}
                    pinnedCount={pinnedCount}
                    setPinnedCount={setPinnedCount}
                  />
                  <div className="my-4">
                    <Pagination
                      handleChange={handleChange}
                      meta={paginationMeta}
                      perPage={20}
                    />
                  </div>
                </>
              ) : (
                <div className="applicantsLoader">
                  <Spinner animation="border" />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </div>
    </>
  )
}

export default ReviewsRoute
