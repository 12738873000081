import classNames from "classnames"

import { Form } from "react-bootstrap"
import Icon from "components/Icon"
import styles from "./inputSearch.module.scss"

function InputSearch({ value, setValue, onChange, placeholder }) {
  return (
    <div
      className={classNames(styles.inputSearch, {
        [styles.inputSearch__active]: value,
      })}
    >
      <Icon name="search" height="18" width="18" />
      <Form.Control
        type="search"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={placeholder}
      />
    </div>
  )
}

export default InputSearch
