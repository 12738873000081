import Dropdown from "react-bootstrap/Dropdown"
import Icon from "components/Icon"

import "./DeleteButton.scss"

const DeleteButton = ({ onClick }) => (
  <div className="delete-button">
    <Dropdown align="end">
      <Dropdown.Toggle>
        <Icon name="ellipsis" height="25" width="25" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={onClick}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
)

export default DeleteButton
