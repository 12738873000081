import { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { getEmployerProfile } from "api/employers"
import SummaryProfile from "components/profile/summary"
import Header from "components/Header"

const ProfileRoute = () => {
  const [loading, setLoading] = useState(true)
  const [profileData, setProfileData] = useState({})

  const getProfileData = () => {
    getEmployerProfile()
      .then(data => {
        setProfileData(data)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getProfileData()
  }, [])

  return (
    <>
      <Header title="Profile Overview" />

      <Col lg={12}>
        <div className="content">
          <Row>
            <SummaryProfile profileData={profileData} loading={loading} />
          </Row>
        </div>
      </Col>
    </>
  )
}

export default ProfileRoute
