import { useState } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { deleteTeamMember } from "api/team_members"
import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"

import styles from "./delete.module.scss"

const DeleteTeamMemberModal = ({
  show,
  modalClose,
  currentMember,
  fetchTeamMembers,
}) => {
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    modalClose(false)
  }

  const deleteMember = id => {
    setLoading(true)
    deleteTeamMember(id)
      .then(({ data }) => {
        fetchTeamMembers()
        handleClose()
      })
      .catch(err => {
        console.log("err", err)
        setLoading(false)
      })
  }

  return (
    <Modal
      show={show}
      onHide={modalClose}
      className={styles.deleteModal__container}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <section className={styles.deleteModal__header}>
          <Icon
            name="close"
            height="18"
            width="18"
            onClick={handleClose}
            className={styles.deleteModal__header__close}
          />
        </section>

        <section className={styles.deleteModal__body}>
          <h5>
            Are you sure you want to remove {currentMember?.first_name}{" "}
            {currentMember?.last_name}?
          </h5>
          <p>You can always add this user again later if something changes</p>
        </section>

        <section className={styles.deleteModal__actions}>
          <SecondaryLightButton onClick={handleClose}>
            Never Mind
          </SecondaryLightButton>
          <PrimaryButton
            disabled={loading}
            onClick={() => deleteMember(currentMember?.uuid)}
          >
            {loading ? <Spinner animation="border" /> : " Yes Delete"}
          </PrimaryButton>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteTeamMemberModal
