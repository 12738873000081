import apiCall from "utils/api"

export const getJobAdvertisements = () => {
  return apiCall("get", `job_advertisements`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const createJobAdvertisement = competitorId => {
  const options = {
    data: {
      job_advertisement: {
        competitor_company_ids: competitorId,
      },
    },
  }
  return apiCall("post", `job_advertisements`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
export const deleteJobAdvertisement = id => {
  return apiCall("delete", `job_advertisements/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
