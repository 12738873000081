import Icon from "components/Icon"

import styles from "./People.module.scss"

const PeopleCell = ({ count }) => (
  <div className={styles.people}>
    <Icon name="people" height={16} width={16} />
    {count}
  </div>
)

export default PeopleCell
