import React from "react"
import classNames from "classnames"

import Button from "react-bootstrap/Button"

import styles from "./Primary.module.scss"

const PrimaryButton = React.forwardRef((props, ref) => {
  const {
    children,
    onClick,
    disabled,
    size,
    type,
    className,
    variant = "primary",
    ...rest
  } = props
  return (
    <Button
      className={classNames({
        [`${styles.secondaryLight}`]: true,
        [`${styles.small}`]: size === "small",
        [`${styles.medium}`]: size === "medium",
        [`${styles.large}`]: size === "large",
        [`${styles.extraLarge}`]: size === "xl",
        [styles.outline]: variant === "outline",
        [`${className}`]: className,
      })}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      ref={ref}
      type={type}
      {...rest}
    >
      {children}
    </Button>
  )
})

PrimaryButton.displayName = "PrimaryButton"

export default PrimaryButton
