import cn from "classnames"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import Card from "components/shared/Card"
import ReviewsCardListHeader from "components/reviews/listReviews/review/header"
import ReviewsCardListActions from "components/reviews/listReviews/review/actions"
import ReplyReview from "components/reviews/listReviews/review/replyReview"
import ReadMore from "components/shared/ReadMore"
import ReviewResponse from "components/reviews/listReviews/review/reviewResponse"

import styles from "./review.module.scss"

const Review = ({
  review,
  company,
  id,
  reviewSelect,
  setReviewSelect,
  updateReviews,
  pinnedCount,
  setPinnedCount,
}) => {
  const [animation] = useAutoAnimate()
  const { slug, response } = review
  const { name } = company

  const LIMIT = 350
  return (
    <Card
      className={cn("mb-4", styles.review, {
        [styles.review__hide]: reviewSelect && reviewSelect !== id,
      })}
    >
      <section ref={animation}>
        <ReviewsCardListHeader review={review} companyName={name} />
        <ReadMore limit={LIMIT}>{review?.body}</ReadMore>

        {reviewSelect !== id && (
          <ReviewsCardListActions
            setReviewSelect={setReviewSelect}
            company={company}
            review={review}
            id={id}
            response={response}
            pinnedCount={pinnedCount}
            setPinnedCount={setPinnedCount}
          />
        )}

        {review?.response && (
          <ReviewResponse response={response} companyName={name} />
        )}

        {reviewSelect === id && (
          <ReplyReview
            setReviewSelect={setReviewSelect}
            company={company}
            slug={slug}
            updateReviews={updateReviews}
          />
        )}
      </section>
    </Card>
  )
}

export default Review
