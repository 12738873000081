import React, { useEffect, useState } from "react"
import {
  useTable,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useColumnOrder,
  useRowSelect,
} from "react-table"
import cn from "classnames"
import { Form, Spinner } from "react-bootstrap"
import { createTalentCompetitors } from "api/talent"

import Confirmation from "components/shared/Notifications/Confirmation"
import Pagination from "./Pagination"
import ResultsPerPage from "../Filters/PerPage"
import Icon from "components/Icon"
import ActionBar from "./ActionBar"

import "./Table.scss"

const Table = ({
  columns,
  data,
  meta,
  initialPageIndex,
  initialSort,
  initialDirection,
  filters,
  setFilters,
  handleChange,
  handleRowClick,
  tableClass,
  columnValues,
  setShowFilters,
  showFilters,
  loading,
  id,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setColumnOrder,
    gotoPage,
    setHiddenColumns,
    selectedFlatRows,
    state: { sortBy },
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      className: "editable-table",
      initialState: {
        sortBy: [
          {
            id: initialSort,
            desc: initialDirection === "desc",
          },
        ],
        pageIndex: initialPageIndex,
        pageSize: 50,
        hiddenColumns: columnValues
          .filter(column => !column.visible)
          .map(hiddenColumn => hiddenColumn.accessor),
      },
      defaultColumn: {
        minWidth: 90,
        width: 150,
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(meta.total_rows / 50),
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination,
    useColumnOrder,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
              <div>
                {row.original.talent_competitor ? (
                  <div className="table-check-wrapper">
                    <Form.Check type="checkbox" checked disabled />
                  </div>
                ) : (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                )}
              </div>
            )
          },
          minWidth: 45,
          width: 45,
          filterable: false,
          canResize: false,
          rowSelect: true,
        },
        ...columns,
      ])
    }
  )

  const [showTalentCompetitorSuccess, setShowTalentCompetitorSuccess] =
    useState(false)
  // const [profileViewFlag, setProfileViewFlag] = useState(false)

  const onRowClick = (event, record) => {
    if (event.target.tagName.toLowerCase() !== "a") {
      if (handleRowClick) {
        handleRowClick(record.id)
      }
    }
  }

  const generateSortingIndicator = column => {
    return (
      <span className="table-container-sort">
        <i
          className={cn("table-sort-asc", {
            "table-sort-asc-active": !!!column.isSortedDesc && column.isSorted,
          })}
        ></i>
        <i
          className={cn("table-sort-desc", {
            "table-sort-desc-active": column.isSortedDesc,
          })}
        ></i>
      </span>
    )
  }

  const generateFilterIndicator = column => {
    return (
      <Icon
        name="filter"
        height={18}
        width={18}
        className={cn("table-filter", {
          "table-filter-active": column.isFiltered,
        })}
        onClick={() => {
          setShowFilters(!showFilters)
        }}
      />
    )
  }

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <div className="table-check-wrapper">
          <Form.Check type="checkbox" ref={resolvedRef} {...rest} />
        </div>
      )
    }
  )

  const handleCreateTalentCompetitors = () => {
    const talentCompetitorsSet = new Set(
      selectedFlatRows.map(row => row.original.company_id)
    )
    createTalentCompetitors(Array.from(talentCompetitorsSet))
      .then(res => {
        toggleAllRowsSelected(false)
        setShowTalentCompetitorSuccess(true)
      })
      .catch(error => console.error(error))
  }

  useEffect(() => {
    setColumnOrder(columnValues.map(value => value.accessor))
  }, [columnValues, setColumnOrder])

  useEffect(() => {
    setHiddenColumns(
      columnValues
        .filter(column => !column.visible)
        .map(hiddenColumn => hiddenColumn.accessor)
    )
  }, [columnValues, setHiddenColumns])

  useEffect(() => {
    if (filters !== null) {
      handleChange(1, sortBy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, gotoPage, filters])

  return loading ? (
    <div className="talent-spinner-wrapper">
      <Spinner animation="border" />
    </div>
  ) : (
    <>
      <div
        {...getTableProps({
          className: `rv-table ${tableClass || ""} ${
            selectedFlatRows.length > 0 && "shortened-table"
          }`,
        })}
      >
        <div
          className="rv-tbody"
          {...getTableBodyProps()}
          id="talentDataTableBody"
        >
          {headerGroups.map((headerGroup, i) => (
            <div
              className="rv-thead"
              {...headerGroup.getHeaderGroupProps()}
              key={`headerGroup${i}`}
            >
              {headerGroup.headers.map((column, i) => {
                return (
                  <div
                    className={cn("rv-th", {
                      "filtered-th": column.isFiltered,
                    })}
                    {...column.getHeaderProps(
                      column.sortable && column.getSortByToggleProps()
                    )}
                    key={`column${i}`}
                  >
                    <div className="rv-th-header-container">
                      <div className="rv-th-header-wrapper">
                        <span className="rv-th-text">
                          {column.render("Header")}
                        </span>
                      </div>
                      {column.sortable && generateSortingIndicator(column)}
                    </div>
                    {!column.rowSelect && (
                      <div
                        className="rv-th-header-container rv-th-utils"
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                      >
                        {column.filterable && (
                          <div className="filter-wrapper">
                            {generateFilterIndicator(column)}
                          </div>
                        )}
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className="resizer"
                          />
                        )}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          ))}
          {page.map((row, i) => {
            prepareRow(row)

            const clicked = selectedFlatRows
              .map(row => row.original.id)
              .includes(row.original.id)

            return (
              <div
                className={cn("rv-tr", {
                  clickable: handleRowClick,
                  "clicked-row": clicked,
                })}
                onClick={e => onRowClick(e, row.original)}
                {...row.getRowProps()}
                key={`row${i}`}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <div
                      {...cell.getCellProps({
                        className: `rv-td ${cell.column.className || ""}`,
                      })}
                      key={`cell${i}`}
                    >
                      {cell.render("Cell")}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="rv-tfoot">
          <Pagination
            handleChange={handleChange}
            meta={meta}
            sortBy={sortBy}
            perPage={filters.perPage}
          />
          <ResultsPerPage setFilters={setFilters} filters={filters} />
        </div>
      </div>
      {selectedFlatRows.length > 0 && (
        <ActionBar
          selectedRows={selectedFlatRows.map(row => row.original)}
          handleCreateTalentCompetitors={handleCreateTalentCompetitors}
        />
      )}
      <Confirmation
        show={showTalentCompetitorSuccess}
        onClose={() => setShowTalentCompetitorSuccess(false)}
      >
        <div className="talent-toast">Competitors added successfully</div>
      </Confirmation>
    </>
  )
}

export default Table
