import Icon from "components/Icon"
import styles from "./avgCategories.module.scss"

const AvgCategories = ({ avg }) => {
  return (
    <div className={styles.avgCategories}>
      <Icon name="star-2" height={16} width={16} />
      <span className={styles.avgCategories}>{avg}</span>
    </div>
  )
}

export default AvgCategories
