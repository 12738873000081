import styles from "./informativeMessage.module.scss"

const InformativeMessage = () => {
  return (
    <article className={styles.informativeMessage}>
      <p>We’ll send an invite to the email address entered above</p>
    </article>
  )
}

export default InformativeMessage
