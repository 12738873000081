import { useState } from "react"
import dayjs from "dayjs"
import SingleSelectFilter from "components/shared/Filters/SingleSelect"

const getRangeDates = (key, type, val) => {
  const FORMAT_DATE = "YYYY-MM-DD"

  const RANGE_TYPE = {
    month: `${dayjs()
      .subtract(val, "month")
      .format(FORMAT_DATE)}&${key}=${dayjs().format(
      FORMAT_DATE
    )}&ratings.created_at.rel=between`,
    week: `${dayjs().startOf("week").format(FORMAT_DATE)}&${key}=${dayjs()
      .endOf("week")
      .format(FORMAT_DATE)}&ratings.created_at.rel=between`,
    currentMonth: `${dayjs()
      .startOf("month")
      .format(FORMAT_DATE)}&${key}=${dayjs()
      .endOf("month")
      .format(FORMAT_DATE)}&ratings.created_at.rel=between`,
  }
  return RANGE_TYPE[type]
}

const TimeReviewsFilters = ({ setGlobalFilters }) => {
  const ALL_TIME = "all time"
  const KEY = "ratings.created_at[]"
  const options = [
    { label: "All Time", value: ALL_TIME },
    { label: "This Week", value: getRangeDates(KEY, "week", 3) },
    { label: "This Month", value: getRangeDates(KEY, "currentMonth", 3) },
    { label: "Last 3 Months", value: getRangeDates(KEY, "month", 3) },
    {
      label: "Last 6 Months",
      value: getRangeDates(KEY, "month", 6),
    },
    { label: "Last 12 Months", value: getRangeDates(KEY, "month", 12) },
  ]
  const [filterState, setFilterState] = useState({
    label: "All Time",
    value: ALL_TIME,
  })

  const onChangeFilter = data => {
    setFilterState(data)
    setGlobalFilters(state => {
      const filterLocal = { ...state }
      filterLocal[KEY] = data?.value
      data?.value === ALL_TIME && delete filterLocal[KEY]
      return filterLocal
    })
  }

  return (
    <article>
      <SingleSelectFilter
        setFilters={onChangeFilter}
        filters={filterState}
        options={options}
        name="time"
      />
    </article>
  )
}

export default TimeReviewsFilters
