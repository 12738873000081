import dayjs from "dayjs"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { getAvatar } from "utils/icons"
import StarsCategory from "components/shared/StarsCategory"

import styles from "./header.cardList.reviews.module.scss"

const ReviewsCardListHeader = ({ review, companyName }) => {
  const { rating, rating_unverified } = review

  const score = rating
    ? rating.average_metric_rating.toFixed(1).toString()
    : "5"

  return (
    <section className={styles.headerCardList}>
      {rating && (
        <>
          <div className={styles.headerCardList__user}>
            {getAvatar(rating.user_color, 40)}
            <article>
              <div className={styles.headerCardList__tags}>
                <span className={styles.headerCardList__name}>
                  {rating.user_status === "Current Employee"
                    ? rating.user_status
                    : "Former Employee"}
                </span>

                {rating_unverified && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className={styles.tooltip}>
                        <span>
                          This review was written by a user who we were unable
                          to verify as a current or former employee at{" "}
                          {companyName}
                        </span>
                      </Tooltip>
                    }
                  >
                    <div className={styles.headerCardList__tags__unverified}>
                      Unverified
                    </div>
                  </OverlayTrigger>
                )}
              </div>

              <div className={styles.headerCardList__stars}>
                <StarsCategory average={score} size="small" />
                <span>{score}</span>
              </div>
            </article>
          </div>

          <span className={styles.headerCardList__date}>
            {dayjs(review.created_at).format("MMMM D, YYYY")}
          </span>
        </>
      )}
    </section>
  )
}

export default ReviewsCardListHeader
