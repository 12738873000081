import { createJob, updateJob } from "api/jobs"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import Button from "components/shared/Buttons/Primary"
import Icon from "components/Icon"
import SuccessToast from "components/shared/Notifications/SuccessToast"

import styles from "./StepNav.module.scss"

const StepNav = ({ setStep, step, setErrors, form, errors, job }) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const { job_board } = currentEmployer

  const external = job_board?.applicant_method === "External"

  const navigate = useNavigate()
  const firstStep = step === 1
  const thirdStep = step === 3

  const handleBack = () => {
    if (firstStep) {
      return
    } else {
      setStep(step - 1)
    }
  }

  const urlRegex =
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/

  function validateURL(url) {
    return urlRegex.test(url)
  }

  const nextText =
    thirdStep && job
      ? "Confirm Changes"
      : thirdStep
      ? "Approve & Post Job"
      : "Next"

  const validateFirstStep = () => {
    const errors = {}
    const cantBeBlank = ["title", "role", "location_type"]

    for (let field of cantBeBlank) {
      if (form[field] === "" || form[field] === null) {
        errors[field] = ["Cannot be blank"]
      }
    }

    if (external && !validateURL(form.external_url)) {
      errors.external_url = [
        "Invalid URL. Must begin with `https://` or `http://`",
      ]
    }
    if (
      form.location_type &&
      form.location_type !== "Fully remote" &&
      form.metro_location_ids.length === 0
    ) {
      errors.metro_location_ids = [
        "Your selection for Location Type requires at least one location to be selected",
      ]
    }
    if (form.ote_min > form.ote_max) {
      errors.base_max = ["OTE Max must be greater than OTE Min"]
    }
    return errors
  }

  const handleNext = () => {
    if (firstStep) {
      const errors = validateFirstStep()
      if (Object.keys(errors).length > 0) {
        setErrors(errors)
      } else {
        setStep(step + 1)
      }
    } else if (step === 2 && !form.description) {
      setErrors({ ...errors, description: ["Must not be blank."] })
    } else {
      setStep(step + 1)
    }
  }

  const handleSubmit = () => {
    const submission = {
      job: {
        ...form,
        metro_location_ids: form.metro_location_ids.map(
          location => location.id
        ),
      },
    }

    if (job) {
      submission.job.status = "Pending approval"
    }

    if (job) {
      updateJob(job.id, submission)
        .then(() => {
          toast.custom(t => (
            <SuccessToast
              t={t}
              headline="Your job has been successfully updated and is pending review!"
            />
          ))
          navigate("/jobs?status=Pending%20approval")
        })
        .catch(error => console.error(error?.data || error))
    } else {
      createJob(submission)
        .then(() => {
          toast.custom(t => (
            <SuccessToast
              t={t}
              headline="Your job is pending approval and will be posted within 1-2 business days"
            />
          ))
          navigate("/jobs?status=Active")
        })
        .catch(error => console.error(error?.data || error))
    }
  }

  return (
    <div className={styles.container}>
      <Button
        variant="outline"
        onClick={firstStep ? () => navigate("/jobs") : handleBack}
        size="medium"
      >
        {firstStep ? (
          <Icon name="close" height="16" width="16" />
        ) : (
          <Icon name="back-arrow" height="12" width="12" />
        )}
        {firstStep ? "Cancel" : "Go Back"}
      </Button>
      <Button
        onClick={thirdStep ? handleSubmit : handleNext}
        size="medium"
        data-cy="new-job-proceed"
      >
        {nextText}
        <Icon
          name="back-arrow"
          height="12"
          width="12"
          className={styles.arrow}
        />
      </Button>
    </div>
  )
}

export default StepNav
