import { useState, useEffect } from "react"
import { pageCall } from "utils/mixpanel"
import { Row, Col } from "react-bootstrap"
import Header from "components/Header"
import SettingsTabs from "components/settings/Tabs"
import TeamPage from "components/settings/Team"

import "./Settings.module.scss"

const SettingsRoute = () => {
  const [activeTab, setActiveTab] = useState("team")

  const changeTab = param => {
    setActiveTab(param)
  }

  useEffect(() => {
    pageCall("Settings", "Settings")
  }, [])

  return (
    <>
      <Header title="Settings" />
      <div className="settings content">
        <h3>Settings</h3>
        <Col lg={12}>
          <Row>
            <div className="wrapper">
              <SettingsTabs setTab={changeTab} activeTab={activeTab} />

              <>
                <TeamPage />
              </>
            </div>
          </Row>
        </Col>
      </div>
    </>
  )
}

export default SettingsRoute
