import { useEffect, useState } from "react"
import { trackAction } from "utils/mixpanel"
import cn from "classnames"
import dayjs from "dayjs"
import pluralize from "pluralize"

import Card from "components/shared/Card"
import ProgressBar from "react-bootstrap/ProgressBar"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import CardInfo from "./CardInfo"
import DescriptionGoal from "./DescriptionGoal"
import GetMoreRatingsModal from "./Modal"

import styles from "./Ratings.module.scss"

const RatingsComponent = ({ data = {} }) => {
  const [goalPercentage, setGoalPercentage] = useState(0)
  const [actualPercentage, setActualPercentage] = useState(0)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)

  const todaysMonth = dayjs().month()
  const todaysQuarter = Math.floor(todaysMonth / 3 + 1)
  const quarter = `Q${todaysQuarter}`

  useEffect(() => {
    const goal = (data?.quarter_ratings_goal * 100) / data?.sales_headcount
    setGoalPercentage(goal.toFixed(2))
    const actual = (data?.quarter_ratings_count * 100) / data?.sales_headcount
    setActualPercentage(actual.toFixed(2))
  }, [data])

  const { quarter_ratings_count, quarter_ratings_goal, sales_headcount } = data

  return (
    <div className={styles.rating}>
      <Card className={styles.rating__card}>
        <div className={styles.rating__title}>
          Ratings this Quarter ({quarter})
        </div>
        <div className={styles.rating__value}>{quarter_ratings_count}</div>

        <div className={styles.rating__quarterContainer}>
          <h6 className={styles.rating__titleDescription}>
            {quarter} Goal -{" "}
            {pluralize("rating", data?.quarter_ratings_goal, true)}
          </h6>
        </div>

        <ProgressBar className={cn(styles.rating__progressBar)}>
          <ProgressBar
            className={cn(styles.rating__solid, {
              [styles.rating__solid__success]:
                quarter_ratings_goal - quarter_ratings_count < 1,
            })}
            now={actualPercentage}
            key={1}
          />
          <ProgressBar
            className={cn({
              [styles.rating__striped]:
                quarter_ratings_goal - quarter_ratings_count > 0,
              [styles.rating__striped__success]:
                quarter_ratings_goal - quarter_ratings_count < 1,
            })}
            striped={quarter_ratings_goal - quarter_ratings_count > 0}
            now={goalPercentage}
            key={2}
            animated={quarter_ratings_goal - quarter_ratings_count > 0}
          />
        </ProgressBar>

        <DescriptionGoal percentage={25} total={sales_headcount} />

        <CardInfo
          quarterRemaining={quarter_ratings_goal - quarter_ratings_count}
          quarterGoal={quarter_ratings_goal}
        />

        <SecondaryLightButton
          className={styles.rating__action}
          onClick={() => {
            setShowModalConfirmation(true)
            trackAction("Call to Action Clicked", {
              location: "Dashboard",
              click_text: "Get More Ratings",
              click_url: null,
              modal_name: "Get More Ratings Modal",
            })
          }}
        >
          Get More Ratings
        </SecondaryLightButton>
      </Card>

      <GetMoreRatingsModal
        show={showModalConfirmation}
        modalClose={setShowModalConfirmation}
      />
    </div>
  )
}

export default RatingsComponent
