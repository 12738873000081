import { OverlayTrigger, Tooltip } from "react-bootstrap"

const GenericTooltip = ({ text, placement = "top", content }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id="tooltip-1">{text}</Tooltip>}
    >
      <div>{content}</div>
    </OverlayTrigger>
  )
}

export default GenericTooltip
