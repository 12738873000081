import { ENGAGEMENT } from "./constants"
import AcceptedActionsDetails from "./AcceptedActions"
import styles from "./actionsDetails.module.scss"

const ActionsDetails = ({ details, updateJob }) => {
  const SECTIONS = {
    [ENGAGEMENT]: (
      <AcceptedActionsDetails linkedin={details?.user?.linkedin_url} />
    ),
  }

  return (
    <section className={styles.actionsDetails__container}>
      {SECTIONS[details?.status]}
    </section>
  )
}

export default ActionsDetails
