import { useState, useRef } from "react"
import { formatDate } from "utils/date"
import { trackAction } from "utils/mixpanel"
import { useSelector } from "react-redux"

import TableApplicantsComponent from "./Components/Table"
import IndeterminateCheckbox from "components/shared/Table/CheckboxSelect"
import EmailAddress from "components/shared/EmailAddress"
import LinkedinProfile from "components/shared/LinkedinProfile"
import DetailApplication from "../../../shared/DetailApplication"
import OutsideClickDetector from "hooks/outsideClickDetector"
import ApplicantName from "components/shared/Table/Cells/ApplicantName"
import Status from "components/shared/Table/Cells/Status"

import styles from "./applicantsTableDetails.module.scss"

const ApplicantsTable = ({
  applicants = [],
  section,
  updateJob,
  jobInfo,
  handleSort,
  sortState,
}) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const { company } = currentEmployer

  const detailsRef = useRef()
  const [showDetails, setShowDetails] = useState(false)
  const [details, setDetails] = useState({})

  const columns = [
    {
      Header: "Applicants Details",
      hideHeader: true,
      columns: [
        {
          Header: "",
          accessor: "checkbox",
          sortable: false,
          filterable: false,
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
          width: "2rem",
          minWidth: "2rem",
          fixed: false,
          colSpan: 1,
          className: styles.applicantsTable__table__checkbox,
          hasAction: true,
        },
        {
          Header: "Applied",
          accessor: "job_applications.created_at",
          filterable: false,
          sortable: true,
          Cell: ({ row }) => formatDate(row.original.created_at) || "--",
          width: "8rem",
          minWidth: "8rem",
          fixed: false,
          colSpan: 1,
          className: styles.applicantsTable__table__applicant,
        },
        {
          Header: "Applicant Names",
          accessor: "users.name",
          filterable: false,
          sortable: true,
          Cell: ({ row }) =>
            <ApplicantName applicantName={row.original.user.name} /> || "--",
          colSpan: 1,
          fixed: false,
          width: "12rem",
          minWidth: "12rem",
        },
        {
          Header: "Status",
          accessor: "status",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => <Status status={value} /> || "--",
          width: "12rem",
          minWidth: "12rem",
          colSpan: 1,
        },
        {
          Header: "Email Address",
          accessor: "user.email",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => <EmailAddress email={value} /> || "--",
          width: "17rem",
          minWidth: "17rem",
          colSpan: 1,
        },
        {
          Header: "Linkedin",
          accessor: "user.linkedin_url",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) =>
            <LinkedinProfile linkedinProfile={value} /> || "--",
          width: "15rem",
          minWidth: "15rem",
          colSpan: 1,
          hasAction: true,
        },
        {
          Header: "Phone Number",
          accessor: "user.phone",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => value || "--",
          width: "12rem",
          minWidth: "12rem",
          colSpan: 1,
        },
        {
          Header: "Recruiter",
          accessor: "recruiter",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          width: "12rem",
          minWidth: "12rem",
          colSpan: 1,
        },
      ],
    },
  ]

  const hideDeails = () => {
    setShowDetails(false)
  }

  const getDetails = data => {
    trackAction("Job Listing Clicked", {job: jobInfo?.title, company: company?.name})
    setDetails(data)
    setShowDetails(true)
  }

  return (
    <>
      <OutsideClickDetector exceptionRef={detailsRef} onClick={hideDeails}>
        <DetailApplication
          show={showDetails}
          details={details}
          setShowDetails={setShowDetails}
          updateJob={updateJob}
          jobInfo={jobInfo}
        />
      </OutsideClickDetector>

      <article ref={detailsRef} className={styles.applicantsTable__wrapper}>
        <TableApplicantsComponent
          columns={columns}
          data={applicants}
          section={section}
          onRowClick={getDetails}
          currentSort={sortState}
          handleSort={handleSort}
        />
      </article>
    </>
  )
}

export default ApplicantsTable
