import { formatBytes } from "utils/string"

import Icon from "components/Icon"

import styles from "./ResumeSection.module.scss"

const ResumeSection = ({ resume }) => {
  const { content_type, filename, byte_size, url } = resume
  const FILE_TYPES = {
    "application/pdf": "pdf",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "text/plain": "txt",
    "text/javascript": "txt",
    "image/jpeg": "pdf",
    "image/png": "pdf",
  }

  return (
    <div className={styles.wrapper}>
      <h6>Resume</h6>
      <a href={url} target="_blank" rel="noreferrer" download="file">
        <div className={styles.container}>
          <Icon
            name={FILE_TYPES?.[content_type] || "docx"}
            height="30"
            width="30"
            className={styles.container__file}
          />
          <div className={styles.container__info}>
            <span className={styles.container__info__title}>
              {filename || "Placeholder"}
            </span>
            <span className={styles.container__info__size}>
              {formatBytes(byte_size)}
            </span>
          </div>
          <div className={styles.container__icons}>
            <Icon name="external-link" height="22" width="22" />
          </div>
        </div>
      </a>
    </div>
  )
}

export default ResumeSection
