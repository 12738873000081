import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { Navigate, useLocation } from "react-router-dom"
import { pageCall } from "utils/mixpanel"

import RegisterForm from "components/signUp/register"
import SuccessMessage from "components/signUp/successMessage"
import logo from "assets/images/repvue-blue.webp"

import styles from "./SignUp.module.scss"

const SignUpRoute = () => {
  const location = useLocation()
  const { authenticated } = useSelector(state => state.auth)
  const [emailSend, setEmailSend] = useState(null)

  useEffect(() => {
    if (emailSend) {
      pageCall("Account Confirmation", "Sign Up")
    } else {
      pageCall("Create Account", "Sign Up")
    }
  }, [emailSend])

  if (authenticated) {
    const path = location.state || "/dashboard"
    return <Navigate to={path} replace />
  }

  return (
    <section className={classNames(styles.signUp, { success: emailSend })}>
      <article
        className={classNames(styles.signUp__logo, {
          [styles.signUp__logo__top]: emailSend,
        })}
      >
        <img src={logo} alt="RepVue" />
      </article>
      {emailSend ? (
        <SuccessMessage emailSend={emailSend} />
      ) : (
        <RegisterForm setEmailSend={setEmailSend} />
      )}
    </section>
  )
}

export default SignUpRoute
