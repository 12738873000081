import { useEffect, useState } from "react"
import { Button, Form, Tab, Tabs } from "react-bootstrap"
import { createTalentCompetitors, getTalentCompetitors } from "api/talent"
import { pageCall } from "utils/mixpanel"

import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"
import Competitor from "components/talentCompetitors/Competitor"
import AddCompanyModal from "components/shared/Modals/AddCompany"
import Confirmation from "components/shared/Notifications/Confirmation"
import { Link } from "react-router-dom"

import "./TalentCompetitor.scss"

const MAX_TALENT_COMPETITORS = 25

const CompetitorTableHeader = () => {
  return (
    <div className="table-header">
      <div className="header-cell">Talent Competitor</div>
      <div className="header-cell">RepVue Score</div>
      <div className="header-cell">Total Ratings</div>
      <div className="header-cell">Advertise My Jobs on Profile</div>
      <div className="header-cell">Actions</div>
    </div>
  )
}

const TabContentHeader = ({ onInputChange }) => {
  return (
    <div className="tab-header">
      <div className="search">
        <Icon name="search" height="18" width="18" />
        <Form.Control
          type="text"
          placeholder="Search competitors"
          onChange={onInputChange}
        />
      </div>
    </div>
  )
}

const TabContent = ({
  talentCompetitors,
  fetchTalentCompetitors,
  handleSuccess,
}) => {
  if (talentCompetitors.length === 0) {
    return null
  }
  return (
    <div className="tab-wrapper">
      <CompetitorTableHeader />
      <div className="competitor-wrapper">
        {talentCompetitors.map((talentCompetitor, index) => (
          <Competitor
            talentCompetitor={talentCompetitor}
            key={talentCompetitor.id}
            rank={index}
            fetchTalentCompetitors={fetchTalentCompetitors}
            handleSuccess={handleSuccess}
          />
        ))}
      </div>
    </div>
  )
}

const TalentCompetitorsRoute = () => {
  const [talentCompetitors, setTalentCompetitors] = useState([])
  const [searchTalentCompetitors, setSearchTalentCompetitors] = useState([])
  const [jobAds, setJobAds] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [show, setShow] = useState(false)
  const [addedCompanies, setAddedCompanies] = useState([])
  const [showSuccess, setShowSuccess] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const advertisingMyJobs = talentCompetitors.filter(
    company => company.job_advertisement_id && company.advertisable
  )

  const onSuccessClose = () => {
    setShowSuccess(false)
    setAddedCompanies([])
  }

  const onDeleteSuccessClose = () => {
    setShowDeleteSuccess(false)
  }

  useEffect(() => {
    fetchTalentCompetitors()
  }, [])

  useEffect(() => {
    let temp = [...talentCompetitors]
    if (searchInput !== "") {
      temp = temp.filter(company =>
        company.competitor_company.name
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      )
    } else {
      temp = talentCompetitors
    }

    setSearchTalentCompetitors(temp)
  }, [searchInput])

  const fetchTalentCompetitors = () => {
    getTalentCompetitors()
      .then(res => {
        setTalentCompetitors(res.data.talent_competitors)
        setSearchTalentCompetitors(res.data.talent_competitors)
        setJobAds(res.data.meta.ad_count)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const onInputChange = event => {
    setSearchInput(event.target.value)
  }

  const addCompetitor = (selectedCompanies, selectedCompaniesNames) => {
    createTalentCompetitors(selectedCompanies)
      .then(res => {
        fetchTalentCompetitors()
        setAddedCompanies(selectedCompaniesNames)
        setShowSuccess(true)
      })
      .catch(e => {
        console.log("addCompetitor error", e)
      })
      .finally(() => {
        handleClose()
      })
  }

  useEffect(() => {
    pageCall("Talent Competitors", "Talent")
  }, [])

  return (
    <div className="competitors scroll-container">
      <Header title="Talent Competitors" />
      <Content>
        <div className="title-wrapper">
          <h3>Talent Competitors</h3>
          <div className="button">
            <Button onClick={handleShow}>
              <Icon name="add" height="20" width="20" />
              Add Talent Competitor
            </Button>
          </div>
        </div>
        <div className="wrapper">
          <Tabs defaultActiveKey="all">
            <Tab
              eventKey="all"
              title={
                <>
                  All
                  <span>{talentCompetitors.length}</span>
                </>
              }
            >
              <TabContentHeader onInputChange={onInputChange} />
              <TabContent
                talentCompetitors={searchTalentCompetitors}
                fetchTalentCompetitors={fetchTalentCompetitors}
                handleSuccess={() => setShowDeleteSuccess(true)}
              />
            </Tab>
            <Tab
              eventKey="talent"
              title={
                <>
                  Advertising My Jobs
                  <span>{advertisingMyJobs.length}</span>
                </>
              }
            >
              <div className="recommended-wrapper">
                <div className="recommended-title">
                  You have {MAX_TALENT_COMPETITORS - jobAds} job ads remaining
                  to use on competitor profiles
                </div>
                <div className="recommended-desc">
                  Increase engagement on your job listings by advertising them
                  on up to {MAX_TALENT_COMPETITORS} your talent competitor
                  profiles. You can also manage job ads on your{" "}
                  <Link to="/ads">job ads list</Link>
                </div>
              </div>
              <TabContent
                talentCompetitors={advertisingMyJobs}
                fetchTalentCompetitors={fetchTalentCompetitors}
                handleSuccess={() => setShowDeleteSuccess(true)}
              />
            </Tab>
          </Tabs>
        </div>
      </Content>
      <AddCompanyModal
        show={show}
        handleClose={handleClose}
        addCompanies={addCompetitor}
        existingCompanies={talentCompetitors}
        title="Add Talent Competitors"
        buttonText="Add Talent Competitors"
      />
      <Confirmation show={showSuccess} onClose={onSuccessClose}>
        {addedCompanies.length > 0 && (
          <div className="added">{`You've added ${
            addedCompanies.length > 2
              ? addedCompanies
                  .map(company =>
                    addedCompanies.indexOf(company) ===
                    addedCompanies.length - 1
                      ? `and ${company.name}`
                      : company.name
                  )
                  .join(", ")
              : addedCompanies.length > 1
              ? addedCompanies.map(company => company.name).join(" and ")
              : addedCompanies[0].name
          } to your Talent Competitors`}</div>
        )}
      </Confirmation>
      <Confirmation show={showDeleteSuccess} onClose={onDeleteSuccessClose}>
        <div className="added">Deleted successfully</div>
      </Confirmation>
    </div>
  )
}

export default TalentCompetitorsRoute
