import dayjs from "dayjs"
import Icon from "components/Icon"
import styles from "./reviewResponse.module.scss"

const ReviewResponse = ({ response, companyName }) => {
  return (
    <section className={styles.reviewResponse}>
      <article className={styles.reviewResponse__header}>
        <span className={styles.reviewResponse__header__label}>
          Response from
        </span>{" "}
        <span className={styles.reviewResponse__header__company}>
          <Icon name="verified" height="16" width="16" /> {companyName}
        </span>{" "}
        <span className={styles.reviewResponse__header__date}>
          {dayjs(response?.created_at).format("MMMM D, YYYY")}
        </span>
      </article>
      <p className={styles.reviewResponse__description}>{response?.body}</p>
    </section>
  )
}

export default ReviewResponse
