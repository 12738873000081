import { useState } from "react"
import Icon from "components/Icon"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import TableControls from "../TableControls"
import { DragDropContext } from "react-beautiful-dnd"
import "./SettingsButton.scss"

const SettingsButton = ({ columnValues, updateColumnValues }) => {
  const [showControls, setShowControls] = useState(false)

  const handleClose = () => {
    setShowControls(false)
  }

  const onDragEnd = result => {
    const { destination, source } = result
    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const newColumnValues = [...columnValues]
    newColumnValues.splice(source.index, 1)
    newColumnValues.splice(
      destination.index,
      0,
      columnValues[`${source.index}`]
    )

    updateColumnValues(newColumnValues)
  }

  return (
    <>
      <div id="talent-settings-button">
        <SecondaryLightButton
          onClick={() => {
            setShowControls(!showControls)
          }}
        >
          <Icon name="columns" height="20" width="20" />
          <span>Columns</span>
        </SecondaryLightButton>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <TableControls
          showControls={showControls}
          columnValues={columnValues}
          setColumnValues={updateColumnValues}
          setShowControls={setShowControls}
          handleClose={handleClose}
          id="talent-controls"
        />
      </DragDropContext>
    </>
  )
}

export default SettingsButton
