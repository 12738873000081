import { PROTECT_ROUTES } from "constants/protect"
const URL_ME = "employers/me"

export const validatePermissions = (accessType, url) => {
  let allow = true
  const path = window.location.pathname

  if (!!PROTECT_ROUTES[path]) {
    allow = PROTECT_ROUTES[path].permissions?.includes(accessType)
  }

  if (url === URL_ME) allow = true

  return allow
}
