import React, { useEffect, useState } from "react"
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table"

import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"
import TableComponent from "components/shared/Table"
import Card from "components/shared/Card"
import Empty from "./Empty"
import Pagination from "components/talentData/Table/Pagination"

import styles from "./tableApplicants.module.scss"

const TableApplicantsComponent = ({
  columns,
  data,
  section = "all",
  onRowClick,
  paginationMeta,
  handleChange,
  currentSort,
  activeSearch,
  noApplicants,
}) => {
  const [loading, setLoading] = useState(true)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {
        Filter: SelectColumnFilter,
        DefaultFilter: DefaultFilterForColumn,
      },
      initialState: {
        sortBy: [
          {
            id: currentSort.length ? currentSort[0].id : null,
            desc: currentSort.length ? currentSort[0].desc : null,
          },
        ],
      },
      manualSortBy: true,
      manualPagination: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  )

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (!loading) {
      handleChange(1, sortBy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  return (
    <Card className={styles.tableContainerApplicants}>
      {data.length > 0 ? (
        <>
          <TableComponent
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            onRowClick={onRowClick}
            className={styles.tableContainerApplicants__table}
            activeSearch={activeSearch}
            headerFixed
          />
          <div className="rv-tfoot">
            <Pagination
              handleChange={handleChange}
              meta={paginationMeta}
              sortBy={sortBy}
              perPage={50}
            />
          </div>
        </>
      ) : (
        <Empty noApplicants={noApplicants} section={section} />
      )}
    </Card>
  )
}

export default TableApplicantsComponent
