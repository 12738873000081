import SelectInput from "components/shared/SelectInput"

import styles from "../PeriodFilter/PeriodFilter.module.scss"

const ResultsPerPage = ({ setFilters, filters }) => {
  const options = [
    { label: "Show 50 Rows", value: 50 },
    { label: "Show 100 Rows", value: 100 },
  ]

  const activePerPage = options.filter(
    option => option.value === filters.perPage
  )[0].label

  return (
    <SelectInput
      onChange={data => {
        setFilters({ ...filters, perPage: data.value })
      }}
      value={filters.perPage}
      placeholder={activePerPage}
      className={styles.perPageDropdown}
      menuPlacement="top"
      name="period"
      options={options}
      selectStyles={{
        control: base => ({
          ...base,
          width: "170px",
          height: "36px",
        }),
        placeholder: base => ({
          ...base,
          color: "#19303e",
          fontSize: "14px",
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  )
}

export default ResultsPerPage
