import cn from "classnames"

import RemainingProgress from "components/reviews/remainingProgress"
import styles from "./reviewsTabs.module.scss"

const ReviewsTabs = ({
  tabs,
  changeCurrentTab,
  counts,
  activeTab,
  reviewSelect,
  pinnedCount,
}) => (
  <section
    className={cn(styles.tabsReviews, {
      [styles.tabsReviews__hide]: reviewSelect,
    })}
  >
    <article className={styles.tabsReviews__container}>
      {tabs?.map(tab => (
        <button
          key={tab?.value}
          className={cn({
            [`${styles.button}`]: true,
            [`${styles.button__active}`]: activeTab.value === tab?.value,
          })}
          onClick={() => changeCurrentTab(tab)}
        >
          {tab?.label}{" "}
          <div className={styles.badge}>{counts[tab?.value] || 0}</div>
        </button>
      ))}
    </article>

    <RemainingProgress pinnedCount={pinnedCount} />
  </section>
)

export default ReviewsTabs
