// import { useEffect, useState } from "react"

import Toast from "react-bootstrap/Toast"
import Icon from "components/Icon"

import styles from "./Confirmation.module.scss"

const Confirmation = ({ children, show, onClose }) => {
  return (
    <Toast
      onClose={() => {
        onClose()
      }}
      show={show}
      delay={6000}
      className={styles.success}
      autohide
    >
      <div className={styles.success__wrapper}>
        <div className={styles.success__header}>
          <Icon
            name="close"
            height="20"
            width="20"
            onClick={() => onClose()}
            className={styles.success__header__close}
          />
        </div>
        <div className={styles.success__body}>
          <Icon name="check-circle" height={20} width={20} />
          <div className={styles.success__body__children}>{children}</div>
        </div>
      </div>
    </Toast>
  )
}

export default Confirmation
