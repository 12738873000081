import SelectInput from "components/shared/SelectInput"
import Icon from "components/Icon"
import { components } from "react-select"
import cn from "classnames"

import styles from "./SingleSelect.module.scss"

const SingleSelectFilter = ({
  setFilters,
  filters,
  options,
  hasIcon = true,
  icon = "calendar",
  label,
  name,
}) => {
  const CustomControl = props => {
    const { children } = props
    return (
      <components.Control {...props} className={styles.placeholder}>
        {label && <span className={styles.label}>{label} - </span>}
        {hasIcon && <Icon name={icon} height={20} width={20} />}
        {children}
      </components.Control>
    )
  }

  const CustomOption = props => {
    const isActiveOption = props.children === filters?.label

    return (
      <components.Option
        {...props}
        className={cn({
          [`${styles.activeOption}`]: isActiveOption,
          [`${styles.option}`]: true,
        })}
      >
        {props.children}
        {isActiveOption && <Icon name="check" height={15} width={15} />}
      </components.Option>
    )
  }

  return (
    <SelectInput
      onChange={data => {
        setFilters(data)
      }}
      value={filters || null}
      className={styles.periodDropdown}
      name={name}
      options={options}
      components={{
        Control: CustomControl,
        Option: CustomOption,
        IndicatorSeparator: () => null,
      }}
      selectStyles={{
        valueContainer: defaultStyles => {
          return {
            ...defaultStyles,
            padding: "2px 4px",
          }
        },
      }}
    />
  )
}

export default SingleSelectFilter
