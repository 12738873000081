import { useState } from "react"
import { toast } from "react-hot-toast"
import { Form, Spinner } from "react-bootstrap"
import { replyReview } from "api/reviews"
import classNames from "classnames"
import Icon from "components/Icon"
import SuccessToast from "components/shared/Notifications/SuccessToast"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import PrimaryButton from "components/shared/Buttons/Primary"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import styles from "./replyReview.module.scss"

const ReplyReview = ({ setReviewSelect, company, slug, updateReviews }) => {
  const MAX_WORDS = 500
  const [loading, setLoading] = useState(false)
  const [review, setReview] = useState("")
  const [error, setError] = useState(false)
  const [wordCount, setWordCount] = useState(0)

  const handleReview = e => {
    const { value } = e.target
    const words = value.trim().split(/\s+/).filter(Boolean).length
    setWordCount(words)
    setReview(value)
    if (words > 0 && words <= MAX_WORDS) setError(false)
    if (words > MAX_WORDS) setError(true)
  }

  const onSubmit = () => {
    setLoading(true)
    replyReview(slug, review)
      .then(() => {
        updateReviews(false)
        setReviewSelect(null)
        toast.custom(t => (
          <SuccessToast
            t={t}
            headline="RepVue will review and publish it to your company profile shortly."
          />
        ))
      })
      .catch(() => {
        toast.custom(t => (
          <ErrorToast
            t={t}
            headline="Something went wrong. Please try again."
          />
        ))
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.replyReview}>
      <div className={styles.replyReview__header}>
        <span className={styles.replyReview__header__label}>Reply as</span>{" "}
        <Icon name="verified-blue" height="16" width="16" />{" "}
        <span className={styles.replyReview__header__company}>
          {company?.name}
        </span>
      </div>

      <div>
        <Form.Group>
          <Form.Control
            as="textarea"
            onChange={handleReview}
            placeholder="Reply to this review"
            className={classNames({
              [`${styles.replyReview__textArea}`]: true,
              [`${styles.replyReview__textArea__error}`]: error,
            })}
          />
        </Form.Group>
      </div>

      <div className={styles.replyReview__info}>
        <p
          className={classNames({
            [`${styles.replyReview__info__error}`]: error,
          })}
        >
          {wordCount} of {MAX_WORDS} words maximum
        </p>
        <p>
          After you post this reply, RepVue will review and publish it to your
          company profile page within 24 hours. All information submitted is
          subject to our{" "}
          <a href="https://www.repvue.com/terms" target="_blank" rel="noreferrer">
            terms of use
          </a>
          ,{" "}
          <a href="https://www.repvue.com/privacy" target="_blank" rel="noreferrer">
            privacy policy
          </a>{" "}
          and{" "}
          <a href="https://www.repvue.com/community-guidelines" target="_blank" rel="noreferrer">
            community guidelines
          </a>
          .
        </p>
      </div>

      <div className={styles.replyReview__actions}>
        <PrimaryButton
          disabled={error || loading || wordCount === 0}
          onClick={onSubmit}
          className={styles.replyReview__actions__submit}
        >
          {loading ? <Spinner size="sm" animation="border" /> : "Post Reply"}
        </PrimaryButton>
        <SecondaryLightButton
          disabled={loading}
          onClick={() => setReviewSelect(null)}
        >
          Cancel
        </SecondaryLightButton>
      </div>
    </div>
  )
}

export default ReplyReview
