import React from "react"
import cn from "classnames"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import styles from "./BarChart.module.scss"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ points = [], className }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      datalabels: {
        display: false,
      },
    },

    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
  }

  const labels = points.labels

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "",
        data: points.data,
        borderColor: "#1EC086",
        borderWidth: 2,
        backgroundColor: "#1EC086",
        borderRadius: 3,
      },
    ],
  }

  return (
    <article className={styles.barChart}>
      <div className={cn(styles.barChart__container, className)}>
        <Bar options={options} data={data} />
      </div>
      <div className={styles.barChart__labels}>
        <span>{points?.firstPoint}</span>
        <span>{points?.lastPoint}</span>
      </div>
    </article>
  )
}

export default BarChart
