import { useState } from "react"
import { cleanTrim } from "utils/string"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import styles from "./readMore.module.scss"

const ReadMore = ({ children = "", limit = 100, fullText = false }) => {
  const [parent] = useAutoAnimate()
  const TEXT = children
  const [isReadMoreShown, setIsReadMoreShown] = useState(false)

  const toggleRead = e => {
    e.preventDefault()
    setIsReadMoreShown(state => !state)
  }

  return (
    <article ref={parent} className={styles.readMore}>
      {isReadMoreShown || TEXT.length <= limit || fullText
        ? TEXT
        : `${cleanTrim(TEXT, limit)}`}
      {TEXT.length > limit && !fullText && !isReadMoreShown && (
        <span onClick={toggleRead} className={styles.readMore__action}>
          Read&nbsp;More
        </span>
      )}
    </article>
  )
}

export default ReadMore
