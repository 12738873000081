import { formatDate } from "utils/date"
import classNames from "classnames"
import Card from "components/shared/Card"
import TableTeamMembersComponent from "./Components/Table"
import JobTitle from "./Components/JobTitle"
import HeaderTeamMembers from "components/settings/Team/components/Header"
import EmailAddress from "components/shared/EmailAddress"
import TeamMemberName from "components/settings/Team/components/TeamTable/Components/Table/TeamMemberName"
import ActionsCell from "components/settings/Team/components/TeamTable/Components/Table/Actions"
import styles from "./teamTable.module.scss"

const TeamTable = ({
  applicants = [],
  inviteMembers,
  handleDelete,
  handleInviteMember,
}) => {
  const columns = [
    {
      Header: "Team Members",
      hideHeader: true,
      columns: [
        {
          Header: "Full Name",
          accessor: "user",
          filterable: false,
          Cell: ({ cell: { value } }) =>
            (
              <TeamMemberName
                pending={value.pending}
                memberName={`${value.first_name} ${value.last_name}`}
              />
            ) || "--",
          colSpan: 1,
          fixed: false,
          sortable: false,
          width: "22.5%",
          minWidth: "22.%",
        },

        {
          Header: "Email Address",
          accessor: "email",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => <EmailAddress email={value} /> || "--",
          width: "22.5%",
          minWidth: "22.5%",
          colSpan: 1,
          hasAction: true,
        },

        {
          Header: "Job Role",
          accessor: "title",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => <JobTitle jobTitle={value} /> || "--",
          width: "22.5%",
          minWidth: "22.5%",
          colSpan: 1,
          className: styles.applicantsTable__table__jobTitle,
        },

        {
          Header: "Date Added",
          accessor: "created_at",
          sortable: true,
          filterable: false,
          Cell: ({ cell: { value } }) => (value ? formatDate(value) : "--"),
          width: "22.5%",
          minWidth: "22.5%",
          fixed: false,
          colSpan: 1,
          className: styles.applicantsTable__table__applicant,
          sortType: (prev, next) => {
            return (
              new Date(next?.values?.created_at) -
              new Date(prev?.values?.created_at)
            )
          },
        },

        {
          id: "Actions",
          Header: "Actions",
          accessor: "id",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { row } }) => (
            <ActionsCell row={row} handleDelete={handleDelete} />
          ),
          width: "10%",
          minWidth: "10%",
          colSpan: 1,
          className: classNames(styles.teamMembersTable__table__actions, "actions"),
          hasAction: true,
        },
      ],
    },
  ]

  return (
    <section>
      <Card className={styles.teamMembersTable__container}>
        <HeaderTeamMembers
          members={applicants.length}
          handleInviteMember={handleInviteMember}
        />
        <TableTeamMembersComponent
          columns={columns}
          data={applicants}
          inviteMembers={inviteMembers}
        />
      </Card>
    </section>
  )
}

export default TeamTable
