import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Icon from "components/Icon"
import styles from "./actions.module.scss"

const Actions = ({ row, handleDelete }) => {
  return (
    <div className={styles.actionsColumn}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Remove Team Member</Tooltip>}
      >
        <span className={styles.iconWrapper}>
          <Icon
            className={styles.deleteIcon}
            onClick={() => handleDelete(row?.original)}
            name="x"
            height="22"
            width="22"
          />
        </span>
      </OverlayTrigger>
    </div>
  )
}

export default Actions
