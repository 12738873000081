import ProgressBar from "react-bootstrap/ProgressBar"
import styles from "./EmployesRanked.module.scss"

const EmployesRanked = ({ topRankPercentage }) => {
  return (
    <article className={styles.employesRanked}>
      <span>{Number.parseFloat(topRankPercentage).toFixed(2)}%</span>

      <ProgressBar className={styles.employesRanked__progressBar}>
        <ProgressBar
          className={styles.employesRanked__solid}
          now={topRankPercentage}
          key={1}
        />
      </ProgressBar>
    </article>
  )
}

export default EmployesRanked
