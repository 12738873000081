import styles from "./CardInfo.module.scss"

const CardInfo = () => (
  <section>
    <div className={styles.cardInfo}>
      <p>
        Ranked company profiles receive more traffic, higher quantity of
        applicants, and qualify for Reppy awards!
      </p>
    </div>
  </section>
)

export default CardInfo
