import RowRating from "./RowRating"
import GrowthLabel from "components/shared/GrowthLabel"
import styles from "./ListRatings.module.scss"

const ListRatings = ({ rows, averageRating }) => {
  const avgOverall = (
    rows?.reduce(
      (total, next) => total + parseFloat(next?.raw_average_change),
      0
    ) / rows?.length
  ).toFixed(2)

  return (
    <section className={styles.listRatings}>
      <article className={styles.listRatings__header}>
        <div>
          <span>Category</span>
        </div>

        <div>
          <span>Your Rating</span>
          <span className={styles.listRatings__header__rating}>
            Last 6 months
          </span>
        </div>
      </article>

      <article className={styles.listRatings__body}>
        {rows?.map((row, index) => (
          <RowRating key={`rating-${index}`} rating={row} />
        ))}
      </article>

      <article className={styles.listRatings__footer}>
        <div className={styles.listRatings__footer__avg}>
          <span>Avg. Overall Rating</span>
        </div>

        <div className={styles.listRatings__footer__ratingContainer}>
          <span className={styles.listRatings__footer__avg}>
            {averageRating}
          </span>
          <span className={styles.listRatings__footer__ratingContainer__rating}>
            <GrowthLabel growth={avgOverall} label={false} />
          </span>
        </div>
      </article>
    </section>
  )
}

export default ListRatings
