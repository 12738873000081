import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { getTeamMembers } from "api/team_members"
import TeamTable from "components/settings/Team/components/TeamTable"
import Confirmation from "components/shared/Notifications/Confirmation"
import InviteTeamMemberModal from "components/settings/Team/components/InviteModal"
import DeleteTeamMemberModal from "components/settings/Team/components/DeleteModal"
import styles from "./team.module.scss"

const TeamPage = () => {
  const [loading, setLoading] = useState(true)
  const [teamMembers, setTeamMembers] = useState([])
  const [currentMember, setCurrentMember] = useState({})
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalInvite, setShowModalInvite] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const fetchTeamMembers = () => {
    getTeamMembers()
      .then(({ data }) => {
        const members = data?.employers?.map(member => ({
          ...member,
          user: {
            first_name: member?.first_name,
            last_name: member?.last_name,
            pending: member?.pending,
          },
        }))
        setTeamMembers(members)
        setLoading(false)
      })
      .catch(err => {
        console.log("err", err)
        setLoading(false)
      })
  }

  const handleDelete = member => {
    setCurrentMember(member)
    setShowModalDelete(true)
  }

  const handleInviteMember = () => {
    setShowConfirmation(true)
    fetchTeamMembers()
  }

  useEffect(() => {
    fetchTeamMembers()
  }, [])

  return (
    <>
      {!loading ? (
        <section>
          <TeamTable
            handleDelete={handleDelete}
            applicants={teamMembers || []}
            handleInviteMember={() => setShowModalInvite(true)}
          />

          <DeleteTeamMemberModal
            show={showModalDelete}
            currentMember={currentMember}
            modalClose={setShowModalDelete}
            fetchTeamMembers={fetchTeamMembers}
          />
          <InviteTeamMemberModal
            show={showModalInvite}
            modalClose={setShowModalInvite}
            handleInviteMember={handleInviteMember}
            setCurrentMember={setCurrentMember}
          />

          <Confirmation
            show={showConfirmation}
            onClose={() => setShowConfirmation(false)}
          >
            <div className={styles.toast}>
              An invite was sent to {currentMember.first_name}{" "}
              {currentMember.last_name}
            </div>
          </Confirmation>
        </section>
      ) : (
        <div className="applicantsLoader">
          <Spinner animation="border" />
        </div>
      )}
    </>
  )
}

export default TeamPage
