import Icon from "components/Icon"
import { Row, Tooltip, OverlayTrigger } from "react-bootstrap"

import "./Headline.scss"

const TalentDataHeadline = ({ count }) => {
  const renderSalesTeamsTooltip = props => (
    <Tooltip {...props}>
      <div className="sales-teams-tooltip">
        Sales teams are anonymous RepVue users grouped by their current sales
        organization and job role.
      </div>
    </Tooltip>
  )

  return (
    <div className="talent-data-headline">
      {count.toLocaleString()} Sales Teams{" "}
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 100 }}
        overlay={renderSalesTeamsTooltip}
      >
        <Row>
          <Icon name="info" height={18} width={18} />
        </Row>
      </OverlayTrigger>
    </div>
  )
}

export default TalentDataHeadline
