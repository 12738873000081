import cn from "classnames"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import styles from "./itemRowRatingJob.module.scss"

const ratingsAverages = {
  ratings_count_all_time: "average_all_time",
  ratings_count_12_months: "average_last_12_months",
  ratings_count_6_months: "average_last_6_months",
}

const ItemRowRatingJob = ({ value }) => {
  const ROUND = 2

  const getAverage = (companyAvg, globalAvg) => {
    const avg = (companyAvg - globalAvg).toFixed(ROUND)
    return avg < 0 ? avg : `+${avg}`
  }

  const averageKey = ratingsAverages[value?.filter]

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip" className={styles.itemRowRatingJob__tooltip}>
          <div className={styles.itemRowRatingJob__tooltip__container}>
            <div
              className={styles.itemRowRatingJob__tooltip__container__header}
            >
              <span className={styles.itemRowRatingJob__tooltip__title}>
                {value?.label}
              </span>
              <h6 className={styles.itemRowRatingJob__tooltip__average}>
                {value?.average}
              </h6>

              <div
                className={
                  styles.itemRowRatingJob__tooltip__container__header__average
                }
              >
                <div
                  className={cn(
                    styles.itemRowRatingJob__tooltip__container__header__average__tag,
                    {
                      [styles.itemRowRatingJob__tooltip__container__header__average__tag__alert]:
                        value?.average < value?.global[averageKey],
                    }
                  )}
                >
                  <span>
                    {getAverage(value?.average, value?.global[averageKey])}
                  </span>
                </div>
                <span
                  className={
                    styles.itemRowRatingJob__tooltip__container__header__average__description
                  }
                >
                  {value?.average < value?.global[averageKey]
                    ? "Below"
                    : "Above"}{" "}
                  global avg. ({value?.global[averageKey]})
                </span>
              </div>
            </div>

            <span className={styles.itemRowRatingJob__tooltip__description}>
              Sourced from{" "}
              <span
                className={styles.itemRowRatingJob__tooltip__description__bold}
              >
                {value?.ratings} ratings
              </span>{" "}
              with the{" "}
              <span
                className={styles.itemRowRatingJob__tooltip__description__bold}
              >
                {value?.job}
              </span>{" "}
              job role
            </span>
          </div>
        </Tooltip>
      }
    >
      <div
        className={cn(styles.itemRowRatingJob__container, {
          [styles.itemRowRatingJob__container__alert]:
            value?.average < value?.global[averageKey],
        })}
      >
        <span>{value?.average}</span>
      </div>
    </OverlayTrigger>
  )
}

export default ItemRowRatingJob
