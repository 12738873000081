import { useState } from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import Icon from "components/Icon"

import styles from "./emailAddress.module.scss"

const EmailAddress = ({ email }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyTextToClipboard = async text => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }

  const handleCopyClick = e => {
    e.stopPropagation()
    copyTextToClipboard(email).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2500)
    })
  }

  const renderTooltip = props => (
    <Tooltip {...props}>Copy email address</Tooltip>
  )

  return (
    <div className={styles.emailAddress}>
      <div className={styles.emailAddress__email}>
        <span>{isCopied ? "Copied!" : email}</span>
      </div>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 100 }}
        overlay={renderTooltip}
      >
        <Button variant="link" onClick={handleCopyClick}>
          <Icon name="copy" height="20" width="20" />
        </Button>
      </OverlayTrigger>
    </div>
  )
}

export default EmailAddress
