import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table"
import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"

import TableComponent from "components/shared/Table"
import Card from "components/shared/Card"
import AllEmptyTable from "./EmptyTable/AllEmpty"
import ClosedEmptyTable from "./EmptyTable/ClosedEmpty"
import PendingEmptyTable from "./EmptyTable/PendingEmpty"
import styles from "./tableJobListings.module.scss"
import TableFiller from "components/jobListings/JobsListingsTable/Components/TableFiller"

const TableJobListing = ({
  columns,
  data,
  section = "Active",
  noJobs,
  freeTier,
  noMoreJobs,
}) => {

  const EMPTYSTATES = {
    Active: <AllEmptyTable noJobs={noJobs} noMoreJobs={noMoreJobs} />,
    "Pending approval": (
      <PendingEmptyTable noJobs={noJobs} noMoreJobs={noMoreJobs} />
    ),
    Closed: <ClosedEmptyTable noJobs={noJobs} noMoreJobs={noMoreJobs} />,
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data,
        defaultColumn: {
          Filter: SelectColumnFilter,
          DefaultFilter: DefaultFilterForColumn,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    )

  return (
    <Card className={styles.tableContainerJobListings}>
      {data.length > 0 ? (
        <TableComponent
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          className={styles.tableContainerJobListings__table}
          filler={<TableFiller />}
          fillerCondition={freeTier && data?.length <= 2}
          headerFixed
        />
      ) : (
        EMPTYSTATES[section]
      )}
    </Card>
  )
}

export default TableJobListing
