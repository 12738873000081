import Status from "components/shared/Table/Cells/Status"
import styles from "./headerDetails.module.scss"

const HeaderDetail = ({ details }) => {
  return (
    <article>
      <div className={styles.headerDetail__container}>
        <Status
          status={details?.status}
          id={details?.id}
          email={details?.user?.email}
          hasTooltip
          selectable
        />
        <span className={styles.headerDetail__updated}>
          Status updated {details?.applied}
        </span>
      </div>
      {details?.status === "Influenced" && (
        <div className={styles.headerDetail__influenced}>
          Influenced hires are applicants that applied via RepVue but were hired
          for a different role than the posting they applied to.
        </div>
      )}
    </article>
  )
}

export default HeaderDetail
