import { trackAction } from "utils/mixpanel"

import { Link } from "react-router-dom"
import PrimaryButton from "components/shared/Buttons/Primary"
import Icon from "components/Icon"

import styles from "components/jobs/jobListings/JobsListingsTable/Components/Table/EmptyTable/EmptyTable.module.scss"

const PendingEmptyTable = ({ noJobs, noMoreJobs }) => {
  return (
    <article className={styles.emptyTable}>
      <div className={styles.emptyTable__icon}>
        <Icon name="briefcase2" height="42" width="42" />
      </div>
      <h6>
        {!noJobs
          ? "No jobs matched your search"
          : "You have no jobs pending approval"}
      </h6>
      <p>
        {noJobs &&
          "Jobs awaiting approval from our team will be listed here. Post a job to start receiving applicants from RepVue"}
      </p>
      {noJobs && (
        <PrimaryButton disabled={noMoreJobs} onClick={() =>
          trackAction("Call to Action Clicked", {
            location: "Job Listings Table (Pending Tab - Empty)",
            click_text: "Post a Job",
            click_url: `${process.env.REACT_APP_USER_URL}/jobs/new`,
            modal_name: null,
          })
        }>
          <Link to="/jobs/new">
            <Icon name="add" height="20" width="20" />
            Post a Job
          </Link>
        </PrimaryButton>
      )}
    </article>
  )
}

export default PendingEmptyTable
