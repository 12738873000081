import { useState } from "react"
import cn from "classnames"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Button from "react-bootstrap/Button"
import styles from "./rangeDates.module.scss"

const RangeDates = ({ getViews, setMonthFilterSelected }) => {
  const [buttons, setButtons] = useState([
    { label: "7D", value: "7_days", isActive: true },
    { label: "30D", value: "30_days", isActive: false },
    { label: "3M", value: "3_months", isActive: false },
    { label: "6M", value: "6_months", isActive: false },
    { label: "12M", value: "1_year", isActive: false },
  ])

  const handleButtonClick = value => {
    const monthValues = ["1_year", "6_months", "3_months"]
    setMonthFilterSelected(monthValues.includes(value))

    const newButtons = buttons.map(button => ({
      ...button,
      isActive: value === button.value,
    }))

    setButtons(newButtons)
    getViews(value)
  }

  return (
    <article className={styles.rangeDates}>
      <ButtonGroup className={styles.rangeDates__buttonsContainer}>
        {buttons.map(button => (
          <Button
            className={cn({
              [styles.rangeDates__buttonsContainer__active]: button.isActive,
            })}
            key={button.value}
            onClick={() => handleButtonClick(button.value)}
          >
            {button.label}
          </Button>
        ))}
      </ButtonGroup>
    </article>
  )
}

export default RangeDates
