import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table"

import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"
import TableComponent from "components/shared/Table"
import AllEmptyTable from "./EmptyTable/AllEmpty"
import styles from "./tableTeamMembers.module.scss"

const TableTeamMembersComponent = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: {
          Filter: SelectColumnFilter,
          DefaultFilter: DefaultFilterForColumn,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useRowSelect
    )

  return (
    <article>
      {data.length > 0 ? (
        <>
          <TableComponent
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            className={styles.tableContainerTeamMembers__table}
            headerFixed
          />
        </>
      ) : (
        <AllEmptyTable />
      )}
    </article>
  )
}

export default TableTeamMembersComponent
