import React, { useState } from "react"
import CustomTooltip from "./Tooltip"
import cn from "classnames"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import styles from "./LineChart.module.scss"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

const LineChart = ({ points = [], className, title = "" }) => {
  const COLOR = "rgb(0, 119, 204)"
  const [tooltipData, setTooltipData] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)

  const footer = data => {
    if (data.replay === undefined) {
      setTooltipData({ ...data?.tooltip })
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        enabled: false,
        external: footer,
      },
    },

    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
  }

  const labels = points.labels

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "",
        data: points.data,
        borderColor: COLOR,
        borderWidth: 2,
        backgroundColor: context => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 0, 0, 200)
          gradient.addColorStop(0, "rgba(155,208,255,1)")
          gradient.addColorStop(1, "rgba(255,255,255,0)")
          return gradient
        },
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0,
        borderJoinStyle: "miter",
        pointBorderColor: COLOR,
        pointBackgroundColor: COLOR,
        pointBorderWidth: 0.1,
        pointHoverRadius: 1.5,
        pointHoverBackgroundColor: COLOR,
        pointHoverBorderColor: COLOR,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 2,
        drawActiveElementsOnTop: true,
      },
    ],
  }

  return (
    <article className={styles.lineChart}>
      <div className={cn(styles.lineChart__container, className)}>
        <Line
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          options={options}
          data={data}
          plugins={[
            {
              afterDraw: chart => {
                if (chart.tooltip?._active?.length) {
                  let x = chart.tooltip._active[0].element.x
                  let yAxis = chart.scales.y
                  let ctx = chart.ctx
                  ctx.save()
                  ctx.beginPath()
                  ctx.setLineDash([4, 4])
                  ctx.moveTo(x, yAxis.top)
                  ctx.lineTo(x, yAxis.bottom)
                  ctx.lineWidth = 3
                  ctx.strokeStyle = "rgba(0, 0, 0, 1)"
                  ctx.stroke()
                  ctx.restore()
                }
              },
            },
          ]}
        />
      </div>
      <div className={styles.lineChart__labels}>
        <span>{points?.firstPoint}</span>
        <span>{points?.lastPoint}</span>
      </div>
      {showTooltip && <CustomTooltip data={tooltipData} title={title} />}
    </article>
  )
}

export default LineChart
