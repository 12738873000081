import { OverlayTrigger, Tooltip } from "react-bootstrap"
import styles from "./Tooltip.module.scss"

const TooltipRepVue = ({ text, placement = "top" }) => (
  <OverlayTrigger
    placement={placement}
    overlay={<Tooltip id="tooltip-1">{text}</Tooltip>}
  >
    <div className={styles.tooltip}>?</div>
  </OverlayTrigger>
)

export default TooltipRepVue
