import Spinner from "react-bootstrap/Spinner"
import Card from "components/shared/Card"
import InformationRow from "components/profile/summary/rows/information"
import LogoRow from "components/profile/summary/rows/logo"
import styles from "./summaryProfile.module.scss"

const SummaryProfile = ({ profileData, loading }) => {
  return (
    <Card className={styles.details}>
      <section>
        <article>
          <h4 className={styles.details__title}>Profile Summary</h4>
          <p className={styles.details__description}>
            This is the basic information associated to your RepVue profile. If
            any of the above information is incorrect, please email{" "}
            <a
              href="mailto:support@repvue.com"
              className={styles.details__description__email}
            >
              support@repvue.com
            </a>{" "}
            to request changes.
          </p>
        </article>
      </section>
      {!loading ? (
        <section>
          <InformationRow label="Company Name" value={profileData?.name} />
          <LogoRow label="Logo" logoValue={profileData?.logo} />
          <InformationRow label="Website URL" value={profileData?.website} />
          <InformationRow
            label="Industry"
            value={profileData?.industry?.name}
          />
          <InformationRow
            label="Funding Source"
            value={profileData?.funding_source}
          />
          <InformationRow label="Company Size" value={profileData?.size} />
          <InformationRow
            label="HQ Location"
            value={profileData?.hq_location}
          />
          <InformationRow
            label="Description"
            value={profileData?.description}
          />
        </section>
      ) : (
        <div className={styles.details__loading}>
          <Spinner animation="border" />
        </div>
      )}
    </Card>
  )
}

export default SummaryProfile
