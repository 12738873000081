import { useState } from "react"
import { useSelector } from "react-redux"
import Imgix from "react-imgix"
import Modal from "react-bootstrap/Modal"

import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"

import styles from "./GetMoreRatingsModal.module.scss"

const GetMoreRatingsModal = ({ show, modalClose }) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const { company } = currentEmployer
  const [isCopied, setIsCopied] = useState(false)

  const copyTextToClipboard = async text => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }

  const handleCopyClick = () => {
    const link = company?.public_url
    copyTextToClipboard(link).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2500)
    })
  }

  const handleClose = () => {
    modalClose(false)
  }

  return (
    <Modal
      show={show}
      onHide={modalClose}
      className={styles.modalMoreRatings__container}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <section className={styles.modalMoreRatings__header}>
          <Icon
            name="close"
            height="18"
            width="18"
            onClick={handleClose}
            className={styles.modalMoreRatings__header__close}
          />

          <figure>
            <Imgix
              src={`${process.env.REACT_APP_IMAGE_URL}/${company.logo}`}
              alt={company.name}
              width={50}
              height={50}
            />
          </figure>
          <h5>Request More Ratings for {company?.name}</h5>
          <p>
            Share the link below with your sales employees to get more ratings.
            This will increase the credibility & recency of your score!
          </p>
        </section>

        <section className={styles.modalMoreRatings__shareLink}>
          <div className={styles.modalMoreRatings__shareLink__}>
            <h6>Share Link</h6>
          </div>

          <div className={styles.modalMoreRatings__shareLink__box}>
            <span>{company?.public_url}</span>
          </div>
        </section>

        <section className={styles.modalMoreRatings__actions}>
          <PrimaryButton onClick={handleCopyClick}>
            {isCopied ? "Copied!" : "Copy Link"}
          </PrimaryButton>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default GetMoreRatingsModal
