import classNames from "classnames"
import { cleanTrim } from "utils/string"

import Card from "components/shared/Card"
import styles from "./overview.jobs.module.scss"

const OverviewJobDetails = ({ jobInfo = {} }) => {
  const {
    title,
    role,
    location_type,
    metro_locations,
    years_of_experience,
    sales_division,
  } = jobInfo

  return (
    <Card className="col-6 mb-4">
      <h5 className="mb-3">Overview</h5>

      <article className={styles.overviewJobDetails__tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>Job Title</th>
              <td>{cleanTrim(title, 100, true)}</td>
            </tr>
            <tr>
              <th>Primary Role</th>
              <td>{role}</td>
            </tr>
            <tr>
              <th>Sales Division</th>
              <td>{sales_division || String.fromCharCode(8212)}</td>
            </tr>
            <tr>
              <th>Location Type</th>
              <td>{location_type}</td>
            </tr>
            <tr>
              <th>Location(s)</th>
              <td>
                {metro_locations?.length > 0
                  ? metro_locations.map((location, i) => (
                      <div
                        key={location.name}
                        className={classNames("badge rounded-pill bg-primary", {
                          "me-2": i < (metro_locations?.length || 0) - 1,
                        })}
                      >
                        {location.name}
                      </div>
                    ))
                  : String.fromCharCode(8212)}
              </td>
            </tr>

            <tr>
              <th>Work Experience</th>
              <td>{years_of_experience || String.fromCharCode(8212)}</td>
            </tr>
          </tbody>
        </table>
      </article>
    </Card>
  )
}

export default OverviewJobDetails
