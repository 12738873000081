import cn from "classnames"
import { formatDate } from "utils/date"

import { Link } from "react-router-dom"

import styles from "./applicationSection.module.scss"

const ApplicationSection = ({ details, jobInfo }) => {
  return (
    <article className={styles.applicationSection}>
      <h6>Application</h6>
      <div className={styles.applicationSection__details__row}>
        <div className={styles.applicationSection__details__row__title}>
          Job Title
        </div>
        <Link
          to={`/jobs/${jobInfo?.id || details?.job?.id}`}
          className={cn(
            styles.applicationSection__details__row__detail,
            styles.applicationSection__details__row__detail__jobTitle
          )}
        >
          {jobInfo ? jobInfo?.title : details?.job?.title}
        </Link>
      </div>
      <div className={styles.applicationSection__details__row}>
        <div className={styles.applicationSection__details__row__title}>
          Application Date
        </div>
        <div
          className={cn(
            styles.applicationSection__details__row__detail,
            styles.applicationSection__details__row__detail__date
          )}
        >
          {formatDate(details?.applied || details?.created_at)}
        </div>
      </div>
      <div className={styles.applicationSection__details__row}>
        <div className={styles.applicationSection__details__row__title}>
          Match Quality
        </div>
        <div className={styles.applicationSection__details__row__detail}>
          --
        </div>
      </div>
      <div className={styles.applicationSection__details__row}>
        <div className={styles.applicationSection__details__row__title}>
          Recruiter(s)
        </div>
        <div className={styles.applicationSection__details__row__detail}>
          <span>{details?.recruiters?.join(", ") || "--"}</span>
        </div>
      </div>
    </article>
  )
}

export default ApplicationSection
