import cn from "classnames"
import { Form, Offcanvas } from "react-bootstrap"
import { useRef } from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"
import Icon from "components/Icon"
import "./TableControls.scss"

const TableControls = ({
  columnValues,
  setColumnValues,
  showControls,
  handleClose,
}) => {
  const controls = useRef(null)

  return (
    <Offcanvas
      className={`talent-controls-active`}
      show={showControls}
      placement="end"
      scroll={true}
      onHide={handleClose}
    >
      <div ref={controls}>
        <Droppable droppableId="talent-controls-droppable">
          {provided => (
            <div
              className={cn("talent-controls")}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columnValues.map(value => (
                <Draggable
                  draggableId={`talent-controls-draggable-${columnValues.indexOf(
                    value
                  )}`}
                  index={columnValues.indexOf(value)}
                  key={`draggable${columnValues.indexOf(value)}`}
                >
                  {provided => (
                    <div
                      className="talent-draggable-row"
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      <div className="row-start" {...provided.dragHandleProps}>
                        <Icon name="draganddrop" height={18} width={18} />
                        {value.name}
                      </div>
                      <div className="row-end">
                        <div className="talent-control-slider">
                          <Form>
                            <Form.Check
                              id={`column-index-${columnValues.indexOf(
                                value
                              )}-toggle`}
                              type="switch"
                              name="visible"
                              checked={value.visible}
                              onChange={() => {
                                const newColumnValues = [...columnValues]
                                newColumnValues[
                                  columnValues.indexOf(value)
                                ].visible = !value.visible
                                setColumnValues(newColumnValues)
                              }}
                            />
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </Offcanvas>
  )
}

export default TableControls
