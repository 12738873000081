import cn from "classnames"

import styles from "./table.module.scss"

const TableComponent = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  onRowClick,
  className,
  headerFixed,
  filler,
  fillerCondition,
}) => {
  const setOnRowClick = (allowAction, record) => {
    if (allowAction && onRowClick) {
      onRowClick(record)
    }
  }

  headerGroups = headerGroups.filter(group => group.headers.length > 1)

  return (
    <article
      className={cn(styles.tableContainer, { [className]: !!className })}
    >
      <div {...getTableProps()} className={styles.tableContainer__table}>
        <div
          className={cn(styles.tableContainer__table__thead, {
            [styles.tableContainer__table__thead__fixed]: headerFixed,
          })}
        >
          {headerGroups.map(headerGroup => {
            return (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className={styles.tableContainer__table__tr}
              >
                {headerGroup.headers.map(column => {
                  const toggleProps = column.getSortByToggleProps()
                  const styleProps = {
                    style: {
                      minWidth: column.minWidth,
                      flex: 1,
                      maxWidth: column.width === "flex" ? null : column.width,
                    },
                  }
                  return column.hideHeader ? null : (
                    <div
                      {...column.getHeaderProps({
                        ...(column.sortable ? toggleProps : {}),
                        style: {
                          ...(column.sortable ? toggleProps.style : {}),
                          ...styleProps.style,
                        },
                      })}
                      className={cn(
                        column.className,
                        styles.tableContainer__table__th,
                        {
                          [styles.tableContainer__cell__sticky]: column.fixed,
                        }
                      )}
                      colSpan={column.colSpan}
                    >
                      <div className={styles.tableContainer__cellHeader}>
                        <span
                          className={cn({
                            [styles.tableContainer__cellHeader__select]:
                              column.isSorted,
                          })}
                        >
                          {column.render("Header")}
                        </span>

                        {/* rendering column Sort */}

                        {column.sortable && (
                          <span className={styles.tableContainer__sort}>
                            <i
                              className={cn(styles.tableContainer__sortAsc, {
                                [styles.tableContainer__sortAsc__active]:
                                  column.isSortedDesc,
                              })}
                            ></i>
                            <i
                              className={cn(styles.tableContainer__sortDesc, {
                                [styles.tableContainer__sortDesc__active]:
                                  !!!column.isSortedDesc && column.isSorted,
                              })}
                            ></i>
                          </span>
                        )}
                      </div>

                      {/* rendering column filter */}
                      <div>
                        {column.filterable ? column.render("Filter") : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div
          className={styles.tableContainer__table__tbody}
          {...getTableBodyProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row)

            return (
              <div
                {...row.getRowProps()}
                className={cn(
                  styles.tableContainer__row,
                  styles.tableContainer__table__tr,
                  {
                    [styles.tableContainer__row__select]: row?.isSelected,
                    [styles.tableContainer__row__tr__isSelectable]:
                      Boolean(onRowClick),
                  }
                )}
              >
                {row.cells.map(cell => {
                  return (
                    <div
                      onClick={e =>
                        setOnRowClick(!cell?.column?.hasAction, row?.original)
                      }
                      colSpan={cell?.column?.colSpan}
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          flex: 1,
                          maxWidth:
                            cell.column.width === "flex"
                              ? null
                              : cell.column.width,
                        },
                      })}
                      className={cn(
                        cell?.column.className,
                        styles.tableContainer__table__td,
                        {
                          [styles.tableContainer__cell__sticky]:
                            cell?.column.fixed,
                        }
                      )}
                    >
                      {cell.render("Cell")}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
      {rows?.length <= 2 && fillerCondition && filler}
    </article>
  )
}

export default TableComponent
