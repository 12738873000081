import { useState, useCallback, useEffect } from "react"
import { getTalentData } from "api/talent"
import {
  createTalentDataQuery,
  createInitialFilterState,
  calculateFilterCount,
} from "utils/query"
import { useParams } from "react-router-dom"
import { cleanTrim } from "utils/string"
import { pageCall } from "utils/mixpanel"
import Header from "components/Header"
import TalentDataHeader from "components/talentData/Header"
import TalentDataFilters from "components/talentData/Filters"
import SaveTalentProfileModal from "components/talentData/Modal/SaveTalentProfile"
import DeleteModal from "components/talentProfiles/DeleteModal"
import Confirmation from "components/shared/Notifications/Confirmation"
import ProfileHeader from "components/talentProfiles/ProfileHeader"
import TableWrapper from "components/talentData/TableWrapper"
import TalentDataControls from "components/talentData/Controls"
import useColumnValues from "components/talentData/Hooks/useColumnValues"
import styles from "./TalentData.module.scss"

const TalentDataRoute = () => {
  const { id } = useParams()
  const { columnValues, updateColumnValues } = useColumnValues()
  const [talentData, setTalentData] = useState({
    rows: [],
    meta: { rows_count: 0 },
  })
  const [loading, setLoading] = useState(true)
  const [showFilters, setShowFilters] = useState(false)
  const [currentSort, setCurrentSort] = useState([])
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showProfileSuccess, setShowProfileSuccess] = useState(false)
  const [filterState, setFilterState] = useState(null)
  const [talentProfile, setTalentProfile] = useState(null)
  const [filterCount, setFilterCount] = useState(0)

  const handleClose = () => setShowFilters(false)
  const modalClose = () => setShowModal(false)
  const deleteModalClose = () => setShowDeleteModal(false)
  const openDeleteModal = () => setShowDeleteModal(talentProfile.id)

  const fetchTalentData = useCallback(
    (pageIndex = 0, sortBy) => {
      if (filterState !== null) {
        const requestObj = createTalentDataQuery(filterState, pageIndex, sortBy)

        setCurrentSort(sortBy)
        setCurrentPageIndex(pageIndex)

        getTalentData(requestObj)
          .then(res => {
            setTalentData(res.data)
            setLoading(false)
            document.querySelector("#talentDataTableBody").scrollTop = 0
          })
          .catch(err => {
            console.log("err", err)
          })
      }
    },
    [filterState]
  )

  useEffect(() => {
    createInitialFilterState(id, setFilterState, setTalentProfile)
  }, [id])

  useEffect(() => {
    if (filterState) {
      setFilterCount(
        calculateFilterCount(filterState, currentPageIndex, currentSort)
      )
    }
  }, [filterState, currentPageIndex, currentSort])

  useEffect(() => {
    if (talentProfile !== null) {
      pageCall(talentProfile && id ? "Talent Profile" : "Talent Data", "Talent")
    }
  }, [id, talentProfile])

  return (
    <>
      <div className="employer-header">
        <Header
          title={
            talentProfile && id
              ? "Talent Profiles"
              : filterState
              ? "Talent Data"
              : ""
          }
          path={cleanTrim(talentProfile?.name, 33) || null}
        />
      </div>

      <div className="talent content">
        {talentProfile && id && (
          <ProfileHeader profile={talentProfile} onClick={openDeleteModal} />
        )}
        {!id && <TalentDataHeader />}

        <TalentDataControls
          filterState={filterState}
          setFilterState={setFilterState}
          columnValues={columnValues}
          updateColumnValues={updateColumnValues}
          showFilters={showFilters}
          filterCount={filterCount}
          setShowModal={setShowModal}
          showModal={showModal}
          setShowFilters={setShowFilters}
        />

        <TableWrapper
          fetchTalentData={fetchTalentData}
          filterState={filterState}
          setFilterState={setFilterState}
          id={id}
          talentData={talentData}
          loading={loading}
          columnValues={columnValues}
          updateColumnValues={updateColumnValues}
        />

        {filterState && (
          <TalentDataFilters
            show={showFilters}
            handleClose={handleClose}
            handleFilter={fetchTalentData}
            currentSort={currentSort}
            filters={filterState}
            setFilters={setFilterState}
            view={id ? "profile" : "grid"}
            resetFilters={createInitialFilterState}
          />
        )}
      </div>
      {filterState && (
        <SaveTalentProfileModal
          currentFilters={createTalentDataQuery(
            filterState,
            currentPageIndex,
            currentSort
          )}
          modalClose={modalClose}
          show={showModal}
          setShowProfileSuccess={setShowProfileSuccess}
        />
      )}
      <DeleteModal show={showDeleteModal} modalClose={deleteModalClose} />
      <Confirmation
        show={showProfileSuccess}
        onClose={() => setShowProfileSuccess(false)}
      >
        <div className={styles.toast}>Talent Profile created successfully</div>
      </Confirmation>
    </>
  )
}

export default TalentDataRoute
