import apiCall from "utils/api"

export const createJobBoard = data => {
  const options = { data }
  return apiCall("post", `job_board`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
