import dayjs from "dayjs"
import DeleteButton from "./DeleteButton"

import styles from "./ProfileHeader.module.scss"

const ProfileHeader = ({ profile, onClick }) => {
  const { name, author_name, created_at } = profile
  return (
    <div className={styles.header}>
      <div className={styles.info}>
        <h3>
          {name}{" "}
          {/* <Icon name="edit" height="22" width="22" className={styles.icon} /> */}
        </h3>
        <span>{`Created by ${author_name}, ${dayjs(created_at).format(
          "MMM D, YYYY"
        )}`}</span>
      </div>
      <DeleteButton onClick={onClick} />
    </div>
  )
}

export default ProfileHeader
