import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { resetPassword } from "api/auth"
import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"

import icon from "assets/images/icons/email_lock.svg"
import "../signIn/SignIn.scss"

const ForgotPasswordRoute = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setError(false)

    const params = { employer: { email } }
    dispatch(resetPassword(params))
      .then(response => {
        if (response.status === 201) {
          setSuccess(true)
        }
      })
      .catch(response => {
        console.error(response)
        setError(true)
      })
  }

  return (
    <div className="sign-in">
      {error && <div className="alert alert-danger">Something went wrong</div>}

      {success ? (
        <div className="success">
          <img src={icon} height="100" width="100" alt="Success" />
          <h5 className="mb-2">Check your inbox!</h5>

          <p className="mb-1">
            In the next few minutes, a password reset link will
            <br />
            show up in the inbox of <strong>{`${email}`}</strong>.
          </p>

          <p className="mb-4 re-enter">
            Didn't receive the email?{" "}
            <Link to="/forgot_password" onClick={() => setSuccess(!success)}>
              Re-enter you email address
            </Link>
          </p>

          <Link to="/sign_in">
            <SecondaryLightButton size="medium">
              Back to Sign in
            </SecondaryLightButton>
          </Link>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <Link to="/sign_in" className="return">
            <Icon name="back-arrow" height="12" width="12" />
            Cancel & Return
          </Link>
          <h5>Forgot your password?</h5>

          <p>
            No problem! Enter your email address below and we'll send you a link
            to reset your password.
          </p>

          <div className="input-wrapper">
            <Icon name="email" height="20" width="20" className="icon-left" />
            <input
              className="form-control sign-in-input forgot-email"
              type="email"
              id="email"
              placeholder="Email address"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <PrimaryButton size="medium" type="submit">
            {" "}
            Send Password Reset Link{" "}
          </PrimaryButton>
        </form>
      )}
    </div>
  )
}

export default ForgotPasswordRoute
