import apiCall from "utils/api"

export const getBattlecards = () => {
  return apiCall("get", `battle_cards`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("getBattleCards", error)
      throw error
    })
}
export const createBattlecard = (id1, id2) => {
  const options = {
    data: {
      battle_card: {
        opponent_company_1_id: id1,
        opponent_company_2_id: id2 ? id2 : null,
      },
    },
  }
  return apiCall("post", `battle_cards`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("createBattleCards", error)
      throw error
    })
}
export const getBattlecardById = id => {
  return apiCall("get", `battle_cards/${id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("getBattleCard", error)
      throw error
    })
}
export const deleteBattlecardById = id => {
  return apiCall("delete", `battle_cards/${id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("deleteBattleCard", error)
      throw error
    })
}

export const updateBattleCard = (id, data) => {
  const options = {
    data: {
      battle_card: {
        ...data,
      },
    },
  }

  return apiCall("patch", `battle_cards/${id}`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}
