import { useEffect } from "react"
import { Link } from "react-router-dom"

import styles from "./Header.module.scss"

const Header = ({ title, path, basePath }) => {
  useEffect(() => {
    document.title = `RepVue Employer | ${title}`
  }, [title])

  return (
    <header className={styles.header}>
      <div className={styles.title}>
        {basePath ? <Link to={basePath}>{title}</Link> : <span>{title}</span>}
        {path && (
          <>
            <span> / </span>
            <strong>{path}</strong>
          </>
        )}
      </div>
      {/* <div className={styles.header__links}>
        <Link to="#">
          <Icon name="documents" height={20} width={20} />
          Resources
        </Link>
        <Link to="#">
          <Icon name="lifesaver" height={20} width={20} />
          Support
        </Link>
      </div> */}
    </header>
  )
}

export default Header
