import { useState, useCallback, useMemo } from "react"
import qs from "qs"
import TalentDataTable from "components/talentData/Table"
import CompanyCell from "components/talentData/Cells/Company"
import PeopleCell from "components/talentData/Cells/People"
import CurrencyCell from "components/shared/Table/Cells/Currency"
import TalentDataHeadline from "components/talentData/Table/Headline"

const TableWrapper = ({
  fetchTalentData,
  filterState,
  setFilterState,
  id,
  talentData,
  loading,
  columnValues,
  updateColumnValues,
}) => {
  const queryStr = qs.parse(window.location.search)
  const initialPageIndex = parseInt(queryStr.page - 1) || 0
  const initialSort = queryStr.sort || undefined
  const initialDirection = queryStr.direction || undefined
  const [showFilters, setShowFilters] = useState(false)

  const onTableChange = useCallback(
    (pageIndex, sortBy) => {
      fetchTalentData(pageIndex, sortBy)
    },
    [fetchTalentData]
  )

  const columns = useMemo(
    () => [
      {
        Header: "Sales Org",
        accessor: "company_name",
        Cell: ({ row: { original } }) => {
          return <CompanyCell original={original} />
        },
        sortable: true,
        filterable: true,
        isFiltered: filterState?.selectedCompanies.length > 0,
        width: 250,
        colSpan: 1,
        canResize: true,
      },
      {
        Header: "Buyer Persona(s)",
        accessor: "selling_personas",
        Cell: ({ cell: { value } }) =>
          value ? value.map(persona => persona.name).join(", ") : "--",
        isFiltered: filterState?.selectedPersonas.length > 0,
        filterable: true,
        sortable: false,
        width: 250,
        colSpan: 1,
      },
      {
        Header: "Job Role",
        accessor: "user_role",
        isFiltered: filterState?.selectedRoles.length > 0,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => (value ? value : "--"),
        width: 300,
        colSpan: 1,
      },
      {
        Header: "Respondents",
        accessor: "people_count",
        isFiltered: filterState?.peopleCount.length > 0,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => <PeopleCell count={value} />,
        width: 100,
        colSpan: 1,
      },
      {
        Header: "Industry",
        accessor: "industry_name",
        isFiltered: filterState?.selectedIndustries.length > 0,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => (value ? value : "--"),
        colSpan: 1,
      },
      {
        Header: "Division",
        accessor: "sales_division",
        isFiltered: filterState?.selectedSegments.length > 0,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => (value ? value : "--"),
        colSpan: 1,
      },
      {
        Header: "Avg. Deal Size",
        accessor: "average_deal_size",
        isFiltered:
          filterState?.avgDealSize.min > 0 ||
          filterState?.avgDealSize.max < 1000000,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => <CurrencyCell value={value} />,
        colSpan: 1,
      },
      {
        Header: "Base",
        accessor: "average_base_compensation",
        isFiltered:
          filterState?.basePay.min > 0 || filterState?.basePay.max < 300000,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => <CurrencyCell value={value} />,
        colSpan: 1,
      },
      {
        Header: "OTE",
        accessor: "average_ote",
        isFiltered: filterState?.ote.min > 0 || filterState?.ote.max < 500000,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => <CurrencyCell value={value} />,
        colSpan: 1,
      },
      {
        Header: "Top Performer Earnings",
        accessor: "top_performer_earnings",
        isFiltered:
          filterState?.topPerformerEarnings.min > 0 ||
          filterState?.topPerformerEarnings.max < 2000000,
        sortable: true,
        filterable: true,
        Cell: ({ cell: { value } }) => <CurrencyCell value={value} />,
        colSpan: 1,
      },
      {
        Header: "Quota %",
        accessor: "average_quota_attainment",
        isFiltered:
          filterState?.quotaAttainment.min > 0 ||
          filterState?.quotaAttainment.max < 100,
        filterable: true,
        sortable: true,
        Cell: ({ cell: { value } }) => (value ? `${value}%` : "--"),
        colSpan: 1,
      },
      {
        Header: "Culture Score",
        accessor: "average_culture_and_leadership_score",
        isFiltered:
          filterState?.cultureScore.min > 0 ||
          filterState?.cultureScore.max < 5,
        filterable: true,
        sortable: true,
        Cell: ({ cell: { value } }) => (value ? value : "--"),
        colSpan: 1,
      },
      {
        Header: "Product Market Fit Score",
        accessor: "average_product_market_fit_score",
        isFiltered:
          filterState?.productMarketFitScore.min > 0 ||
          filterState?.productMarketFitScore.max < 5,
        width: 180,
        filterable: true,
        sortable: true,
        colSpan: 1,
      },
      {
        Header: "RepVue Score",
        accessor: "repvue_score",
        isFiltered:
          filterState?.repVueScore.min > 0 || filterState?.repVueScore.max < 5,
        filterable: true,
        sortable: true,
        colSpan: 1,
      },
      {
        Header: "Ratings Count",
        accessor: "ratings_count",
        filterable: false,
        width: 100,
        colSpan: 1,
      },
      {
        Header: "HQ Location",
        accessor: "headquarters",
        isFiltered: filterState?.headquarters?.length,
        width: 250,
        filterable: true,
      },
      {
        Header: "Diversity and Inclusion Score",
        accessor: "average_diversity_and_inclusion_score",
        isFiltered:
          filterState?.diversityAndInclusionScore.min > 0 ||
          filterState?.diversityAndInclusionScore.max < 5,
        filterable: true,
        sortable: true,
        width: 100,
        colSpan: 1,
        canResize: true,
      },
      {
        Header: "Base Compensation Score",
        accessor: "average_base_compensation_score",
        isFiltered:
          filterState?.baseCompensationScore.min > 0 ||
          filterState?.baseCompensationScore.max < 5,
        filterable: true,
        sortable: true,
        width: 100,
        colSpan: 1,
        canResize: true,
      },
      {
        Header: "Inbound Lead Opportunity Score",
        accessor: "average_inbound_lead_opportunity_flow_score",
        isFiltered:
          filterState?.inboundLeadScore.min > 0 ||
          filterState?.inboundLeadScore.max < 5,
        filterable: true,
        sortable: true,
        width: 100,
        colSpan: 1,
        canResize: true,
      },
      {
        Header: "Incentive Compensation Structure Score",
        accessor: "average_incentive_compensation_structure_score",
        isFiltered:
          filterState?.incentiveCompensationStructureScore.min > 0 ||
          filterState?.incentiveCompensationStructureScore.max < 5,
        filterable: true,
        sortable: true,
        width: 100,
        colSpan: 1,
        canResize: true,
      },
      {
        Header: "Professional Development and Training Score",
        accessor: "average_professional_development_and_training_score",
        isFiltered:
          filterState?.professionalDevelopmentScore.min > 0 ||
          filterState?.professionalDevelopmentScore.max < 5,
        filterable: true,
        sortable: true,
        colSpan: 1,
        canResize: true,
      },
    ],
    [filterState]
  )

  return (
    <>
      {!loading && <TalentDataHeadline count={talentData.meta.total_count} />}
      <TalentDataTable
        columns={columns}
        data={talentData.rows}
        meta={talentData.meta}
        initialPageIndex={initialPageIndex}
        initialSort={initialSort}
        initialDirection={initialDirection}
        handleChange={onTableChange}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
        filters={filterState}
        setFilters={setFilterState}
        loading={loading}
        columnValues={columnValues}
        setColumnValues={updateColumnValues}
        id={id}
      />
    </>
  )
}

export default TableWrapper
