import React from "react"
import { bool, string, func, oneOfType, object } from "prop-types"
import cn from "classnames"
import Form from "react-bootstrap/Form"
import styles from "./inputComponent.module.scss"

const Checkbox = ({ onChange, className, name, label, value, ...rest }) => {
  return (
    <Form.Check
      className={cn(styles.checkboxComponent, className)}
      type="checkbox"
      id={name}
      name={name}
      label={label}
      onChange={onChange}
      checked={value}
      {...rest}
    />
  )
}

Checkbox.propTypes = {
  type: string,
  onChange: func,
  className: string,
  name: string,
  label: oneOfType([string, object]),
  value: bool,
}

export default Checkbox
