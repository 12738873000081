import { useEffect, useState } from "react"
import Card from "components/shared/Card"
import LineChart from "components/shared/LineChart"
import ScoreComponent from "components/shared/Score"
// import GrowthLabel from "components/shared/GrowthLabel"
import styles from "./Score.module.scss"

const Score = ({ data }) => {
  const [pointsChart, setPointsChart] = useState({
    labels: [],
    data: [],
    firstPoint: "",
    lastPoint: "",
  })

  useEffect(() => {
    let dataMap = { labels: [], data: [], firstPoint: "", lastPoint: "" }

    data?.monthly_company_trends?.forEach((point, index) => {
      dataMap.labels.push(`${point.month} ${point.year}`)
      dataMap.data.push(point.repvue_score)
      if (index === 0) dataMap.firstPoint = `${point.month} ${point.year}`
      if (index + 1 === data?.monthly_company_trends.length)
        dataMap.lastPoint = `${point.month} ${point.year}`
    })

    setPointsChart(dataMap)
  }, [data])

  return (
    <div className={styles.score}>
      <Card className={styles.score__card}>
        <div className={styles.title}>Your RepVue Score</div>
        <div className={styles.value}>{data?.repvue_score}</div>

        {/* Leaving these commented out in case we want to re-implement --TH */}
        {/* <GrowthLabel growth={data?.score_change_since_company_claimed} /> */}
        <div className={styles.score__line}>
          <LineChart points={pointsChart} title="RepVue Score" />
        </div>
        <ScoreComponent
          score={data?.repvue_score}
          topCompanies={data?.top_percent}
        />
      </Card>
    </div>
  )
}

export default Score
