import Icon from "components/Icon"

import styles from "./ExpandButton.module.scss"

const ExpandButton = ({ setShowExpand, setShownSubMenu, setCollapsed }) => {
  return (
    <div className={styles.expandWrapper}>
      <div
        className={styles.expand}
        onClick={() => {
          setCollapsed(false)
          setShowExpand(false)
          setShownSubMenu(null)
        }}
      >
        <Icon name="home-box" height={24} width={24} />
      </div>
    </div>
  )
}

export default ExpandButton
