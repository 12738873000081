import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setCurrentEmployer } from "reducers/auth"
import toast from "react-hot-toast"
import { trackAction } from "utils/mixpanel"

import InitialStage from "./Stages/Initial"
import ExternalStage from "./Stages/External"
import NativeStage from "./Stages/Native"
import SuccessToast from "components/shared/Notifications/SuccessToast"

import styles from "./ApplicantPreferenceModal.module.scss"

const ApplicantPreferenceModal = () => {
  const { currentEmployer } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [stage, setStage] = useState("initial")

  const handleClose = jobBoard => {
    const newEmployer = { ...currentEmployer }
    newEmployer.job_board = jobBoard
    trackAction("Applicant Method Selected", {
      applicant_method: jobBoard?.applicant_method,
    })
    dispatch(setCurrentEmployer(newEmployer))
    toast.custom(t => (
      <SuccessToast t={t} headline="Applicant preference set successfully!" />
    ))
  }

  const stageComponents = {
    initial: <InitialStage setStage={setStage} />,
    native: <NativeStage setStage={setStage} handleClose={handleClose} />,
    external: <ExternalStage setStage={setStage} handleClose={handleClose} />,
  }

  return (
    <>
      <div className={styles.backdrop} />
      <div className={styles.modal}>{stageComponents[stage]}</div>
    </>
  )
}

export default ApplicantPreferenceModal
