import mixpanel from "mixpanel-browser"
import { UAParser } from "ua-parser-js"

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

export const pageCall = (name, category = "Employer", data) => {
  const additionalFields = data ? { ...data } : {}
  const agentData = new UAParser(navigator.userAgent)
  const { pathname, search } = window.location

  additionalFields.device = agentData.getDevice()
  additionalFields.name = name
  additionalFields.path = pathname
  additionalFields.search = search
  additionalFields.category = category

  mixpanel.track(`Viewed ${name}`, additionalFields)
}

export const trackAction = (action, data) => {
  mixpanel.track(action, data)
}

export const identifyUser = employer => {
  mixpanel.identify(employer.uuid)
  mixpanel.people.set({
    $email: employer.email,
    customer_name: employer.company.name,
    created_at: employer.created_at,
    access_tier: employer.customer.access_type,
  })
}
