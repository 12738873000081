import { useState } from "react"
import classNames from "classnames"
import { Accordion } from "react-bootstrap"
import { cleanTrim } from "utils/string"
import Icon from "components/Icon"
import FilterBadge from "../FilterBadge"

import "./AccordionFilter.scss"

const AccordionFilter = ({
  title,
  eventKey,
  defaultActive,
  body,
  watchedValues,
  removeFilter,
  type,
}) => {
  const [isActive, setIsActive] = useState(defaultActive)

  return (
    <Accordion key={title} className="filter-accordion">
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header onClick={() => setIsActive(!isActive)}>
          <div
            className={classNames({
              "filter-accordion-item-active": isActive,
              "filter-accordion-item": true,
            })}
          >
            <div className="filter-accordion-header">
              {title}
              <Icon name="triangle-down" height={18} width={18} />
            </div>
          </div>
        </Accordion.Header>
        {watchedValues?.length > 0 && (
          <div className="filter-accordion-selected">
            {watchedValues?.length > 0 &&
              watchedValues.map((value, i) => (
                <FilterBadge
                  type={type}
                  value={cleanTrim(value, 20, true)}
                  onClick={() => removeFilter(value)}
                  key={i}
                />
              ))}
          </div>
        )}
        <Accordion.Body>{body}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionFilter
