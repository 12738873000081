import { useMemo } from "react"
import dayjs from "dayjs"
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table"
import classNames from "classnames"

import Icon from "components/Icon"
import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"
import TableComponent from "components/shared/Table"
import Card from "components/shared/Card"
import Actions from "./Actions"
import CompanyBadge from "components/shared/CompanyBadge"
import repvueJobsLogo from "assets/images/repvue-jobs-board.svg"
import TooltipRepVue from "components/shared/Tooltip"

import styles from "./Table.module.scss"

const JobAdsTable = ({ data, handleDelete }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Job Ad Locations",
        hideHeader: true,
        columns: [
          {
            Header: "Job Ad Location",
            accessor: "competitor_company.name",
            sortable: true,
            filterable: false,
            Cell: ({
              row: {
                original: { competitor_company },
              },
            }) => {
              return (
                <div className={styles.companyCell}>
                  {competitor_company.jobsBoard ? (
                    <div className={styles.jobsBoard}>
                      <span className={styles.imageWrapper}>
                        <img src={repvueJobsLogo} alt="RepVue" />
                      </span>
                      <span className={styles.companyName}>
                        {competitor_company.name}
                      </span>
                    </div>
                  ) : (
                    <CompanyBadge
                      name={competitor_company.name}
                      logo={competitor_company.logo}
                      competitor={competitor_company.talent_competitor}
                    />
                  )}
                </div>
              )
            },
            width: "flex",
            fixed: true,
            colSpan: 1,
            className: styles.tableCell,
          },
          {
            Header: "Location URL",
            accessor: "competitor_company.slug",
            sortable: false,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              const isJobsBoard = value === "repvue-jobs-board"
              return (
                <a
                  href={
                    isJobsBoard
                      ? `${process.env.REACT_APP_USER_URL}/sales-jobs`
                      : `${process.env.REACT_APP_USER_URL}/companies/${value}/jobs`
                  }
                  target="_blank"
                  className={styles.locationCell}
                  rel="noreferrer"
                >
                  <div>
                    /{isJobsBoard ? "sales-jobs" : `companies/${value}/jobs`}{" "}
                    <Icon name="external-link" height="22" width="22" />
                  </div>
                </a>
              )
            },
            width: "flex",
            colSpan: 1,
            className: styles.tableCell,
          },
          // {
          //   Header: "Total Views",
          //   accessor: "",
          //   sortable: false,
          //   filterable: false,
          //   Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          //   width: 140,
          //   className: styles.tableCell,
          // },
          // {
          //   Header: "Total Clicks",
          //   accessor: "",
          //   sortable: false,
          //   filterable: false,
          //   Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          //   width: 140,
          //   className: styles.tableCell,
          // },
          // {
          //   Header: "Applicants",
          //   accessor: "",
          //   sortable: false,
          //   filterable: false,
          //   Cell: ({ cell: { value } }) => (value >= 0 ? value : "--"),
          //   width: 140,
          //   className: styles.tableCell,
          // },
          {
            Header: "Active Since",
            accessor: "created_at",
            sortable: true,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              const formattedDate = dayjs(new Date(Date.parse(value))).format(
                "MMM DD, YYYY"
              )
              if (formattedDate === "Invalid Date") {
                return "--"
              }
              return formattedDate
            },
            width: "flex",
            className: styles.activeCell,
          },
          {
            Header: "Actions",
            accessor: "id",
            sortable: false,
            filterable: false,
            Cell: ({ cell: { value } }) => {
              if (value === 9999999 || value === 9999998) {
                return (
                  <TooltipRepVue text="This is a permanent job ad location. Anytime you post a job, it will be advertised here by default." />
                )
              }
              return <Actions id={value} handleDelete={handleDelete} /> || "--"
            },
            width: "flex",
            className: (classNames(styles.actionsHeader, "actions")),
          },
        ],
      },
    ],
    [data]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: {
          Filter: SelectColumnFilter,
          DefaultFilter: DefaultFilterForColumn,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    )

  return (
    <>
      <Card className={styles.table}>
        {data.length > 0 && (
          <TableComponent
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          />
        )}
      </Card>
    </>
  )
}

export default JobAdsTable
