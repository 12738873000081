import classNames from "classnames"
import Icon from "components/Icon"

import styles from "./StarRating.module.scss"

const RATINGS_COLORS = {
  1: "orange",
  2: "orange",
  3: "orange",
  4: "yellow",
  5: "green",
}

const STARS_VALUES_DEFAULT = [1, 2, 3, 4, 5]

const StarRating = ({ rating, readyForDownload = false, starValues }) => {
  const VALUES = starValues?.length > 0 ? starValues : STARS_VALUES_DEFAULT
  const totalStars = Math.ceil(Number(rating))
  const starColor = RATINGS_COLORS[totalStars]
  const [int, decimal] = rating?.split(".")

  return (
    <div className={styles.stars}>
      {VALUES.map(star => {
        const isLessThanInt = star <= int
        const isLessThanTotal = star <= totalStars
        const classString = classNames({
          "category-star": true,
          [`category-star--${starColor}`]: isLessThanInt || isLessThanTotal,
          "category-star--full": isLessThanInt,
          "category-star--partial": !isLessThanInt && isLessThanTotal,
        })

        return (
          <span
            className={`${classString} ${readyForDownload && styles.starIcon}`}
            key={star}
            style={{
              "--percentage": `${decimal * 10}%`,
            }}
          >
            <Icon name="star-filled" height="12" width="12" />
          </span>
        )
      })}
    </div>
  )
}

export default StarRating
