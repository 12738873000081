import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { validatePermissions } from "utils/permissions"

import Sidebar from "components/sidebar/Sidebar"
import ProtectRoute from "components/protect"
import Maintenance from "routes/maintenance"

import "./Protected.scss"

function ProtectedRoute() {
  const { authenticated } = useSelector(state => state.auth)
  const location = useLocation()
  const { currentEmployer } = useSelector(state => state.auth)
  const [allowRoute, setAllowRoute] = useState(true)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const isMaintenance = process.env.REACT_APP_MODE === "maintenance"

  useEffect(() => {
    setAllowRoute(validatePermissions(currentEmployer?.customer?.access_type))
  }, [location])

  return isMaintenance ? (
    <Maintenance />
  ) : authenticated ? (
    <div className="app">
      <Sidebar
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
      />
      <main
        className={classNames("main-content", {
          "main-content-expanded": sidebarCollapsed,
        })}
      >
        {allowRoute ? <Outlet /> : <ProtectRoute path={location?.pathname} />}
      </main>
    </div>
  ) : (
    <Navigate
      to="/sign_in"
      state={location.pathname + location.hash + location.search}
    />
  ) // saves the current pathname and search
}

export default ProtectedRoute
