import apiCall from "utils/api"

export const getTeamMembers = () => {
  return apiCall("get", `employers`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const inviteTeamMember = params => {
  const options = { data: params }
  return apiCall("post", `employers/invitation`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const deleteTeamMember = id => {
  return apiCall("delete", `employers/${id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("delete Team Member", error)
      throw error
    })
}
