import classNames from "classnames"

import styles from "./Tabs.module.scss"

const ApplicantsTabs = ({
  fetchApplications,
  counts,
  activeTab,
  activeSearch,
}) => (
  <div className={styles.wrapper}>
    <button
      className={classNames({
        [`${styles.button}`]: true,
        [`${styles.button__active}`]: activeTab === "New",
      })}
      onClick={() => fetchApplications(1, [], activeSearch, "New")}
    >
      New <div className={styles.badge}>{counts.new || 0}</div>
    </button>
    <button
      className={classNames({
        [`${styles.button}`]: true,
        [`${styles.button__active}`]: activeTab === "Engagement",
      })}
      onClick={() => fetchApplications(1, [], activeSearch, "Engagement")}
    >
      Engagement <div className={styles.badge}>{counts.engagement || 0}</div>
    </button>
    <button
      className={classNames({
        [`${styles.button}`]: true,
        [`${styles.button__active}`]: activeTab === "Hire successful",
      })}
      onClick={() => fetchApplications(1, [], activeSearch, "Hire successful")}
    >
      Hired <div className={styles.badge}>{counts.hire_successful || 0}</div>
    </button>
    <button
      className={classNames({
        [`${styles.button}`]: true,
        [`${styles.button__active}`]: activeTab === "Declined",
      })}
      onClick={() => fetchApplications(1, [], activeSearch, "Declined")}
    >
      Declined <div className={styles.badge}>{counts.declined || 0}</div>
    </button>
    {counts?.influenced_hires > 0 && (
      <button
        className={classNames({
          [`${styles.button}`]: true,
          [`${styles.button__active}`]: activeTab === "Influenced Hires",
        })}
        onClick={() =>
          fetchApplications(1, [], activeSearch, "Influenced Hires")
        }
      >
        Influenced Hires{" "}
        <div className={styles.badge}>{counts?.influenced_hires}</div>
      </button>
    )}
  </div>
)

export default ApplicantsTabs
