import { useState, useEffect } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import EmptyReviews from "components/reviews/empty"
import Review from "./review"

const ReviewsList = ({
  reviews,
  company,
  reviewSelect,
  setReviewSelect,
  updateReviews,
  pinnedCount,
  setPinnedCount,
}) => {
  const [reviewsList, setReviewsList] = useState([])
  const [parents] = useAutoAnimate()

  useEffect(() => {
    setReviewsList(reviews)
  }, [reviews])

  return (
    <article>
      <div ref={parents}>
        {reviews.length ? (
          reviewsList?.map(review => (
            <Review
              review={review}
              company={company}
              key={review?.slug}
              id={review?.slug}
              reviewSelect={reviewSelect}
              setReviewSelect={setReviewSelect}
              updateReviews={updateReviews}
              pinnedCount={pinnedCount}
              setPinnedCount={setPinnedCount}
            />
          ))
        ) : (
          <EmptyReviews />
        )}
      </div>
    </article>
  )
}

export default ReviewsList
