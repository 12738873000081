import ProgressBar from "react-bootstrap/ProgressBar"

import styles from "./MultipleProgressbar.module.scss"

const MultipleProgressbar = ({ progressBarData, value = 0, label }) => {
  return (
    <article>
      <div className={styles.multipleProgressbar}>
        {progressBarData.map((bar, index) => (
          <ProgressBar
            className={styles.multipleProgressbar__progress}
            now={value >= bar?.value ? 100 : 0}
            key={`${label}-${index}`}
          />
        ))}
      </div>
      <span className={styles.multipleProgressbar__label}>{label}</span>
    </article>
  )
}

export default MultipleProgressbar
