import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Icon from "components/Icon"
import styles from "./linkedinProfile.module.scss"

const LinkedinProfile = ({ linkedinProfile }) => {
  const [userLinkedin, setUserLinkedin] = useState("")

  useEffect(() => {
    if (linkedinProfile) {
      const urlCleanQueryParams = linkedinProfile
        ?.replace(/\/$/, "")
        .split("?")[0]

      const urlProfile = urlCleanQueryParams?.split("/").pop()

      setUserLinkedin(`/${urlProfile}`)
    }
  }, [linkedinProfile])

  return (
    <div className={styles.linkedinProfile}>
      <a href={linkedinProfile} target="_blank" rel="noreferrer">
        <span>{userLinkedin}</span>

        <Button variant="link">
          <Icon name="external-link" height="20" width="20" />
        </Button>
      </a>
    </div>
  )
}

export default LinkedinProfile
