import apiCall from "utils/api"
import { setCurrentEmployer, signOutEmployer } from "reducers/auth"
import { identifyUser } from "utils/mixpanel"

export const signIn = params => async dispatch => {
  const options = { data: params }
  return apiCall("post", `employers/sign_in`, options)
    .then(response => {
      const employer = response.data
      dispatch(setCurrentEmployer(employer))
      identifyUser(employer)
      return response
    })
    .catch(error => {
      console.error("sign in", error)
      throw error
    })
}

export const signOut = () => async dispatch => {
  return apiCall("delete", `employers/sign_out`)
    .then(response => {
      dispatch(signOutEmployer())
      return response
    })
    .catch(error => {
      dispatch(signOutEmployer())
      console.error("sign out", error)
      throw error
    })
}

export const getCurrentEmployer = () => async dispatch => {
  return apiCall("get", `employers/me`)
    .then(response => {
      const employer = response.data
      dispatch(setCurrentEmployer(employer))
      identifyUser(employer)
      return response
    })
    .catch(error => {
      console.error("get employer", error)
      throw error
    })
}

export const resetPassword = params => async () => {
  const options = { data: params }
  return apiCall("post", `employers/password`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error("reset password", error)
      throw error
    })
}

export const changePassword = params => async () => {
  const options = { data: params }
  return apiCall("put", `employers/password`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error("change password", error)
      throw error
    })
}

export const completeRegistration = params => async () => {
  const options = { data: params }
  return apiCall("put", `employers/password?onboarding=true`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error("complete registration", error)
      throw error
    })
}

export const registerEmployer = params => async dispatch => {
  const options = { data: params }
  return apiCall("put", `employers/invitation`, options)
    .then(response => {
      dispatch(getCurrentEmployer())
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}

export const updateEmployer = params => async dispatch => {
  const options = { data: params }
  return apiCall("put", `employer`, options)
    .then(response => {
      dispatch(getCurrentEmployer())
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}

export const resendRegistrationEmail = token => {
  return apiCall("patch", `employers/${token}/resend_registration_instructions`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
}
