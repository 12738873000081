import cn from "classnames"
import GrowthLabel from "components/shared/GrowthLabel"
import styles from "./RowRating.module.scss"

const RowRating = ({ rating }) => {
  return (
    <div className={cn(styles.rowRating__container)}>
      <article className={styles.rowRating__row}>
        <div className={styles.rowRating__row__company}>
          <div className={styles.rowRating__row__company__information}>
            <span>{rating?.name}</span>
          </div>
        </div>

        <article className={styles.rowRating__row__company__rank}>
          <span className={styles.rowRating__row__company__rank__rating}>
            {rating?.raw_average}
          </span>
          <div className={styles.rowRating__row__company__rank__change}>
            <GrowthLabel growth={rating?.raw_average_change} label={false} />
          </div>
        </article>
      </article>
    </div>
  )
}

export default RowRating
