import Card from "components/shared/Card"
import icon from "assets/images/icons/check.svg"
import styles from "./successMessage.signUp.module.scss"

const SuccessMessage = ({ emailSend }) => {
  return (
    <Card>
      <article className={styles.successMessage}>
        <figure className={styles.successMessage__icon}>
          <img src={icon} height="100" width="100" alt="Success" />
        </figure>

        <h5>We can't wait for you to join RepVue!</h5>
        <p className={styles.successMessage__description}>
          We are taking a moment to verify that you are a representative of your
          company. We'll send an update to {emailSend} within 1-2
          business days about your account status.
        </p>
      </article>
    </Card>
  )
}

export default SuccessMessage
