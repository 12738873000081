const Icon = ({ name, width, height, className, onClick, id }) => {
  return (
    <svg
      id={id}
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <use href={`/icons.svg#${name}`} />
    </svg>
  )
}

export default Icon
