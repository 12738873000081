import { useEffect, useState } from "react"
import { Dropdown, Form } from "react-bootstrap"
import Icon from "components/Icon"
import styles from "./checkboxFilter.module.scss"

const CheckboxFilter = ({
  placeholder = "All",
  icon = "search",
  update,
  data,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(data)

  const optionChecked = option => {
    let flag = false
    selectedOptions.forEach(selectedOption => {
      if (option.name === selectedOption.name) {
        flag = true
      }
    })
    return flag
  }

  const toggleOption = option => {
    const newSelectedOptions = [...selectedOptions]
    if (optionChecked(option)) {
      const filteredSelectedOptions = newSelectedOptions.filter(
        selectedOption => selectedOption.name !== option.name
      )
      if (filteredSelectedOptions.length) {
        setSelectedOptions(filteredSelectedOptions)
        update(
          1,
          undefined,
          undefined,
          undefined,
          false,
          filteredSelectedOptions
        )
      } else {
        setSelectedOptions(data)
        update()
      }
    } else {
      newSelectedOptions.push(option)
      if (newSelectedOptions.length === data.length) {
        setSelectedOptions(data)
        update()
      } else {
        setSelectedOptions(newSelectedOptions)
        update(1, undefined, undefined, undefined, false, newSelectedOptions)
      }
    }
  }

  const allSelected = selectedOptions?.length === data?.length

  useEffect(() => setSelectedOptions(data), [data])

  return (
    <>
      <div className={styles.checkboxFilter}>
        <Dropdown autoClose="outside" id="checkbox-client">
          <div className={styles.checkboxFilter__container}>
            <Dropdown.Toggle className={styles.checkboxFilter__action} as="div">
              <div className={styles.checkboxFilter__action__info}>
                <Icon name={icon} height="18" width="18" />

                {allSelected || selectedOptions?.length === 0 ? (
                  <span className={styles.checkboxFilter__action__default}>
                    {placeholder}
                  </span>
                ) : (
                  selectedOptions.map((option, index) => (
                    <span
                      key={`filter-${index}`}
                      className={styles.checkboxFilter__action__default}
                    >
                      {option.name}
                    </span>
                  ))
                )}
              </div>
            </Dropdown.Toggle>
          </div>

          <Dropdown.Menu className={styles.checkboxFilter__options}>
            <Dropdown.Item
              className={styles.checkboxFilter__options__item}
              as="div"
            >
              <div className={styles.checkboxFilter__item}>
                <Form.Check
                  onChange={() => {
                    update()
                    setSelectedOptions(
                      selectedOptions.length === data.length ? [] : data
                    )
                  }}
                  type="checkbox"
                  name={"all"}
                  checked={allSelected}
                  id="all"
                  className={styles.checkboxFilter__all}
                />
                <span className={styles.checkboxFilter__item__all}>
                  All / None
                </span>
              </div>
            </Dropdown.Item>

            {data.map((option, index) => (
              <Dropdown.Item
                as="div"
                key={index}
                className={styles.checkboxFilter__options__item}
              >
                <div className={styles.checkboxFilter__item}>
                  <Form.Check
                    onChange={() => toggleOption(option)}
                    type="checkbox"
                    name={option?.name}
                    checked={optionChecked(option)}
                    id={index}
                    label={option?.name}
                  />
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}

export default CheckboxFilter
