import React, { useState, useMemo } from "react"
import Icon from "components/Icon"
import { useAsyncDebounce } from "react-table"
import { Form } from "react-bootstrap"
import InputSearch from "components/shared/InputSearch"
import styles from "./filter.module.scss"

// Component for Global Filter
export const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  placeholder = "Search",
}) => {
  const [value, setValue] = useState(globalFilter)

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 500)

  return (
    <InputSearch
      value={value}
      setValue={setValue}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

// Component for Default Column Filter
export const DefaultFilterForColumn = ({
  column: {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  },
}) => {
  return (
    <div className={styles.filterLocal}>
      <Form.Control
        value={filterValue || ""}
        onChange={e => {
          // Set undefined to remove the filter entirely
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${length} records..`}
        style={{ marginTop: "10px" }}
      />
    </div>
  )
}

export const SelectColumnFilter = ({ column }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const { filterValue, setFilter, preFilteredRows, id } = column

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })

    return [...options.values()]
  }, [id, preFilteredRows])

  const pio = value => {
    setFilter(value || undefined)
  }

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        pio(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export const SelectGeneralFilter = ({
  globalFilter,
  setGlobalFilter,
  rows,
  accesor,
  defaultOption = "All",
  icon = "search",
}) => {
  const value = globalFilter

  // Calculate the options for filtering
  const options = useMemo(() => {
    const options = new Set()
    rows.forEach(row => {
      options.add(row.values[accesor])
    })
    return [...options.values()]
  }, [accesor, rows, globalFilter])

  return (
    <div className={styles.selectGeneral}>
      <Icon name={icon} height="20" width="20" />
      <select
        value={value}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined)
        }}
      >
        <option value="">{defaultOption}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}
