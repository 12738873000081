import React, { useEffect, forwardRef } from "react"
import Form from "react-bootstrap/Form"
import styles from "./checkBoxSelect.module.scss"

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const { row, indeterminate, ...rest } = props

  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <Form.Check
        className={styles.checkboxComponent}
        type="checkbox"
        ref={resolvedRef}
        {...rest}
      />
    </>
  )
})

export default IndeterminateCheckbox
