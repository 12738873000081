import classNames from "classnames"

import styles from "./Success.module.scss"

const Success = ({ children, className }) => {
  return (
    <div
      className={classNames({
        [`${styles.success}`]: true,
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

export default Success