import { useSelector } from "react-redux"
import Icon from "components/Icon"
import Card from "components/shared/Card"
import ListOrganizations from "./ListOrganizations"
import styles from "./RankOverall.module.scss"

const RankOverall = ({ data = {}, title, actionLabel, slug }) => {
  const userPortal = process.env.REACT_APP_USER_URL
  const { currentEmployer } = useSelector(state => state.auth)
  const { companies_count, rank, rankings } = data

  const topFour = rankings?.slice(0, 4)

  const isInTopFour = topFour?.filter(
    eliteCompany => eliteCompany?.slug === slug
  ).length

  // we only want to show 7 here
  const truncatedRankings = isInTopFour
    ? rankings?.slice(0, 7)
    : rankings?.slice(3, 10)

  return (
    <div className={styles.rankOverall}>
      <Card className={styles.rankOverall__card}>
        <article className={styles.rankOverall__container}>
          <div className={styles.rankOverall__title}>
            <span>{title}</span>
          </div>

          <div className={styles.rankOverall__rank}>
            <span className={styles.rankOverall__rank__numeral}>#</span>
            <span className={styles.rankOverall__rank__number}>
              {data?.rank}
            </span>
          </div>

          <span className={styles.rankOverall__sales}>
            out of {Number(companies_count).toLocaleString()} sales orgs
          </span>
        </article>

        <ListOrganizations rank={rank} rankings={truncatedRankings} />

        <article className={styles.rankOverall__more}>
          <a
            target="_blank"
            href={`${userPortal}/companies/${currentEmployer?.company?.slug}/rankings`}
            rel="noreferrer"
          >
            {actionLabel}
            <Icon name="external-link" height="20" width="20" />
          </a>
        </article>
      </Card>
    </div>
  )
}

export default RankOverall
