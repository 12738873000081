import { useEffect, useState } from "react"
import { getDashboard, getVisits } from "api/dashboard"
import { useSelector } from "react-redux"
import { Row, Col, Spinner } from "react-bootstrap"
import { pageCall } from "utils/mixpanel"

import Header from "components/Header"
import Content from "components/Content"
import Score from "components/dashboard/Score"
import RatingsGoal from "components/dashboard/Ratings"
import ProfilePageViews from "components/dashboard/ProfilePageViews"
import RankOverall from "components/shared/RankOverall"
import JobsWidget from "components/dashboard/Jobs"
import ScoresWidget from "components/dashboard/ScoresWidget"
import ProfileRankedWidget from "components/dashboard/ProfileRankedWidget"
import WelcomeMessageModal from "components/dashboard/WelcomeMessage"
// import SalesRecruitment from "components/dashboard/SalesRecruitment"

const DashboardRoute = () => {
  const FREE_CUSTOMER_TYPE = "Free"
  const [loading, setLoading] = useState(true)
  const [views, setViews] = useState({})
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)
  const [dashboardData, setDashboardData] = useState({})
  const { currentEmployer } = useSelector(state => state.auth)
  const { company } = currentEmployer

  const partialPublished = company?.published_status === "partial"

  const fetchDashboard = () => {
    setLoading(true)
    getDashboard()
      .then(res => {
        const { data } = res
        setDashboardData(data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log("err", err)
      })
  }

  const fetchVisits = (range = "7_days") => {
    const payload = { range }
    getVisits(payload)
      .then(res => {
        const { data } = res
        setViews(data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  useEffect(() => {
    fetchDashboard()
    fetchVisits()
  }, [partialPublished])

  useEffect(() => {
    if (
      !currentEmployer?.onboarding_finished_at &&
      currentEmployer?.customer?.access_type === FREE_CUSTOMER_TYPE
    )
      setShowWelcomeModal(true)
  }, [currentEmployer])

  useEffect(() => {
    pageCall("Dashboard", "Employer Brand")
  }, [])

  return (
    <div className="dashboard">
      <Header title="Dashboard" />
      <Content>
        <h3>Hello {currentEmployer.first_name},</h3>
        <p>
          Get insights into your org&apos;s health, new applicant notifications,
          your rank on RepVue & more!
        </p>
        {!loading ? (
          <>
            <Row>
              <Col lg={8}>
                <Row>
                  {partialPublished && (
                    <Col lg={6}>
                      <JobsWidget
                        activeJobsCount={
                          dashboardData?.company?.active_jobs_count
                        }
                      />
                    </Col>
                  )}
                  <Col lg={6}>
                    {partialPublished ? (
                      <ScoresWidget
                        metrics={
                          dashboardData?.company?.current_company_metric_scores
                        }
                      />
                    ) : (
                      <Score data={dashboardData?.company} />
                    )}
                  </Col>
                  {!partialPublished && (
                    <Col lg={6}>
                      <RatingsGoal data={dashboardData?.company} />
                    </Col>
                  )}

                  {/* <Col lg={12} className="mt-4">
                    <SalesRecruitment data={views} />
                  </Col> */}

                  <Col lg={12} className="mt-4">
                    <ProfilePageViews data={views} getViews={fetchVisits} />
                  </Col>
                </Row>
              </Col>

              <Col lg={4}>
                {partialPublished ? (
                  <ProfileRankedWidget data={dashboardData?.company} />
                ) : (
                  <RankOverall
                    data={dashboardData?.company}
                    title="Your RepVue Rank Overall"
                    actionLabel="More Rankings"
                    slug={company.slug}
                  />
                )}
              </Col>
            </Row>
            <Row></Row>
            <WelcomeMessageModal
              show={showWelcomeModal}
              modalClose={setShowWelcomeModal}
            />
          </>
        ) : (
          <div className="applicantsLoader">
            <Spinner animation="border" />
          </div>
        )}
      </Content>
    </div>
  )
}

export default DashboardRoute
