import Card from "components/shared/Card"
import ListRatings from "./ListRatings"
import SelectInput from "components/shared/SelectInput"
import Icon from "components/Icon"
import { components } from "react-select"
import cn from "classnames"

import styles from "./CategoryRatings.module.scss"
import { useState } from "react"

const Legends = () => {
  return (
    <div className={styles.legends}>
      <div className={`${styles.legends__icon} ${styles.green}`}></div>
      <div className={styles.legends__label}>Above global avg.</div>
      <div
        className={`${styles.legends__icon} ${styles.red} ${styles.leftMargin}`}
      ></div>
      <div className={styles.legends__label}>Below global avg.</div>
    </div>
  )
}

const CategoryRatingsByJobCard = ({ data, onFilterDate }) => {
  const options = [
    { label: "All Time", value: "average_all_time" },
    { label: "Last 12 Months", value: "average_last_12_months" },
    { label: "Last 6 Months", value: "average_last_6_months" },
  ]
  const [filter, setFilter] = useState(options[0])

  const CustomControl = props => {
    const { children } = props
    return (
      <components.Control {...props} className={styles.placeholder}>
        <Icon name="calendar" height={20} width={20} />
        {children}
      </components.Control>
    )
  }

  const CustomOption = props => {
    const isActiveOption = props.children === filter.label

    return (
      <components.Option
        {...props}
        className={cn({
          [`${styles.activeOption}`]: isActiveOption,
          [`${styles.option}`]: true,
        })}
      >
        {props.children}
        {isActiveOption && <Icon name="check" height={15} width={15} />}
      </components.Option>
    )
  }

  return (
    <div className={styles.categoryRatingsByJob}>
      <Card className={styles.categoryRatingsByJob__card}>
        <h4>Category Ratings by Job Role</h4>
        <p className={styles.categoryRatingsByJob__description}>
          See how people with different jobs rated your org in each category.
          These ratings are benchmarked against the global average for that job
          role and category (green is above average, red is below).
        </p>
        <div className={styles.topElements}>
          <SelectInput
            onChange={data => {
              setFilter(data)
              onFilterDate(data)
            }}
            value={filter}
            className={styles.periodDropdown}
            name="period"
            options={options}
            components={{
              Control: CustomControl,
              Option: CustomOption,
              IndicatorSeparator: () => null,
            }}
          />
          <Legends />
        </div>
        <ListRatings rows={data} />
      </Card>
    </div>
  )
}

export default CategoryRatingsByJobCard
