import Card from "components/shared/Card"
import styles from "./description.jobs.module.scss"

const DescriptionJobDetails = ({ description }) => {
  return (
    <Card className="col-6 mb-4">
      <h5 className="mb-3">Job Description</h5>

      <article className={styles.descriptionJobDetails__container}>
        <article
          className={styles.descriptionJobDetails__body}
          dangerouslySetInnerHTML={{
            __html: description || "",
          }}
        />
      </article>
    </Card>
  )
}

export default DescriptionJobDetails
