import { createJobBoard } from "api/job_board"
import toast from "react-hot-toast"

import PrimaryButton from "components/shared/Buttons/Primary"
import ErrorToast from "components/shared/Notifications/ErrorToast"

import applicantsTable from "./applicants_table.png"

import styles from "./NativeStage.module.scss"

const NativeStage = ({ setStage, handleClose }) => {
  const handleSubmit = () => {
    const jobBoard = {
      job_board: {
        other_vendor: null,
        external_url: null,
        tech_stack_vendor_id: null,
        applicant_method: "RepVue",
      },
    }

    createJobBoard(jobBoard)
      .then(res => {
        handleClose(res.data)
      })
      .catch(error => {
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
        console.error(error)
      })
  }

  return (
    <div className="d-flex flex-column gap-4 p-5 text-center align-items-center">
      <div className={styles.header}>
        <h5 className={styles.headline}>Manage Applicants via RepVue</h5>
        <p className={styles.description}>
          You will view and manage your applicants within your Job Postings and
          in the overall Applicants tab.
        </p>
      </div>
      <img
        src={applicantsTable}
        className={styles.image}
        alt="Applicants Table"
      />
      <div className={styles.submit}>
        <span className={styles.confirm}>
          Please confirm your choice as this will set a precedent for all future
          job postings under your company's profile.
        </span>
        <PrimaryButton
          size="small"
          className={styles.button}
          onClick={handleSubmit}
          data-cy="repvue-submit"
        >
          Submit
        </PrimaryButton>
        <button
          onClick={() => {
            setStage("external")
          }}
          className={styles.switch}
        >
          Link to your job board instead
        </button>
      </div>
    </div>
  )
}

export default NativeStage
