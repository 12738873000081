import RowOrganizations from "./RowOrganization"
import styles from "./ListOrganizations.module.scss"

const ListOrganizations = ({ rankings, rank }) => {
  return (
    <section className={styles.listOrganizations}>
      <article className={styles.listOrganizations__header}>
        <div>
          <span className={styles.listOrganizations__header__rank}>Rank</span>
          <span>Sales orgs</span>
        </div>

        <div>
          <span>Change this Month</span>
        </div>
      </article>

      <article className={styles.listOrganizations__body}>
        {rankings?.map(ranking => (
          <RowOrganizations key={ranking.id} ranking={ranking} rank={rank} />
        ))}
      </article>
    </section>
  )
}

export default ListOrganizations
