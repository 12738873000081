import { useEffect, useState, useCallback } from "react"
import { Row, Col, Spinner } from "react-bootstrap"
import {
  getJobsApplications,
  getInfluencedHires,
  updateJobApplication,
} from "api/jobs"
import { pageCall } from "utils/mixpanel"

import Header from "components/Header"
import ApplicantsTabs from "components/applicants/Tabs"
import ApplicantsTable from "components/applicants/Table"
import { GlobalFilter } from "components/shared/Table/Filter"
import CheckboxFilter from "components/shared/Table/CheckboxFilter"

import "./Applicants.scss"

const ApplicantsRoute = () => {
  const [applications, setApplications] = useState([])
  const [applicationSectionCounts, setApplicationSectionCounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState("New")
  const [paginationMeta, setPaginationMeta] = useState(null)
  const [currentSort, setCurrentSort] = useState([])
  const [activeSearch, setActiveSearch] = useState("")
  const [recruiters, setRecruiters] = useState([])
  const [noApplicants, setNoApplicants] = useState(false)

  const fetchApplications = useCallback(
    (
      pageIndex = 1,
      sortBy = currentSort,
      search = activeSearch,
      tab = activeTab,
      initial = false,
      recruitersFilter = null
    ) => {
      if (initial || tab !== activeTab) {
        setLoading(true)
      }
      setActiveTab(tab)
      setCurrentSort(sortBy)
      setActiveSearch(search)
      const params = {
        "job_applications.status": tab,
        page: pageIndex,
      }

      if (sortBy.length && sortBy[0].id) {
        params.sort_key = sortBy[0].id
        params.sort_direction = sortBy[0].desc ? "desc" : "asc"
      }

      if (search?.length) {
        params.search = search
      }

      if (recruitersFilter && recruitersFilter !== recruiters) {
        params["employers.id"] = recruitersFilter.map(recruiter => recruiter.id)
      }

      if (tab === "Influenced Hires") {
        delete params["job_applications.status"]
      }

      const getterFunction =
        tab === "Influenced Hires" ? getInfluencedHires : getJobsApplications

      getterFunction(params)
        .then(res => {
          const applicants = res?.data?.job_applications

          applicants?.forEach(applicant => {
            applicant.recruiters = applicant?.job?.employers.map(
              employer => `${employer.first_name} ${employer.last_name}`
            )
          })
          setApplications(applicants)
          setApplicationSectionCounts(res?.data?.meta?.counters)
          setLoading(false)
          setPaginationMeta(res?.data?.meta?.page_info)
          setNoApplicants(applicants.length === 0 && activeSearch === "")
          if (initial) {
            setRecruiters(res?.data?.meta?.employers)
          }
        })
        .catch(err => {
          console.log("err", err)
          setLoading(false)
        })
    },
    [activeSearch, activeTab, currentSort, recruiters]
  )

  const handleChange = useCallback(
    (pageIndex, sortBy, search) => {
      fetchApplications(pageIndex, sortBy, search)
    },
    [fetchApplications]
  )

  const updateJob = (id, status) => {
    const payload = {
      job_application: {
        status: status,
      },
    }

    updateJobApplication(id, payload)
      .then(res => {
        fetchApplications()
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  useEffect(() => {
    fetchApplications(1, currentSort, activeSearch, activeTab, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading) {
      fetchApplications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSearch])

  useEffect(() => {
    pageCall("Applicants", "Applicants", { applicants_tab: activeTab })
  }, [activeTab])

  return (
    <>
      <Header title="Applicants" />
      <div className="applicants content">
        <h3>Applicants</h3>
        <Col lg={12}>
          <Row>
            <div className="wrapper">
              <ApplicantsTabs
                counts={applicationSectionCounts}
                fetchApplications={fetchApplications}
                activeTab={activeTab}
                activeSearch={activeSearch}
              />
              <div className="applicants-controls">
                <GlobalFilter
                  globalFilter={activeSearch}
                  setGlobalFilter={setActiveSearch}
                  placeholder={
                    activeSearch?.length ? activeSearch : "Search Applicants"
                  }
                />
                <CheckboxFilter
                  icon="people"
                  update={fetchApplications}
                  data={recruiters}
                  placeholder="All Recruiters"
                />
              </div>
              {!loading ? (
                <ApplicantsTable
                  applicants={applications || []}
                  setApplicants={setApplications}
                  section={activeTab}
                  updateJob={updateJob}
                  handleChange={handleChange}
                  paginationMeta={paginationMeta}
                  currentSort={currentSort}
                  activeSearch={activeSearch}
                  recruiters={recruiters}
                  noApplicants={noApplicants}
                />
              ) : (
                <div className="applicantsLoader">
                  <Spinner animation="border" />
                </div>
              )}
            </div>
          </Row>
        </Col>
      </div>
    </>
  )
}

export default ApplicantsRoute
