import { useState, useEffect } from "react"
import { number, oneOfType, string } from "prop-types"
import LinearScoreComponent from "./LinearScoreComponent"
import styles from "./score.module.scss"

const ScoreComponent = ({ score = 0, topCompanies }) => {
  const [progress, setProgress] = useState(0)

  const getBlobColor = score => {
    if (score >= 0 && score <= 50) return "#f56161"
    if (score > 50 && score <= 70) return "#fe8d5c"
    if (score > 70 && score <= 80) return "#f7cb57"
    if (score > 80 && score < 90) return "#A1D900"
    if (score >= 90) return "#3ec897"
  }

  useEffect(() => {
    let value = 0

    let interval = setInterval(() => {
      if (value >= score) {
        setProgress(Number.parseFloat(score).toFixed(2))
        return clearInterval(interval)
      }

      let fixed = value.toFixed(2)
      setProgress(Number.parseFloat(fixed))
      value = value + 0.3
    }, 1)
  }, [score])

  return (
    <div className={styles.score}>
      <article className={styles.score__linear}>
        <LinearScoreComponent
          progress={progress}
          getBlobColor={getBlobColor}
          topCompanies={topCompanies}
        />
      </article>
    </div>
  )
}

ScoreComponent.propTypes = {
  score: oneOfType([string, number]).isRequired,
  topCompanies: number,
}

export default ScoreComponent
