import dayjs from "dayjs"
import cn from "classnames"
import styles from "./table.questions.module.scss"

const QuestionsTable = ({ questions, openDrawer }) => {
  if (questions.length === 0) {
    return null
  }
  return (
    <div className={styles.questionsTable}>
      <div className="table-wrapper">
        <div className={styles.questionsTable__header}>
          <div className={cn(styles.questionsTable__header__row, "pt-2")}>
            Question
          </div>
          <div className={styles.questionsTable__header__row}>Date Asked</div>
          <div className={styles.questionsTable__header__row}>
            Official Answer Status
          </div>
          <div className={styles.questionsTable__header__row}>Actions</div>
        </div>
        <div className={styles.questionsTable__body}>
          {questions.map(question => {
            const officiallyAnswered = question?.official_answer?.created_at
            const dateAsked = dayjs(
              new Date(Date.parse(question.created_at))
            ).format("MMM DD, YYYY")
            const dateAnswered = dayjs(
              new Date(Date.parse(officiallyAnswered))
            ).format("MMM DD, YYYY")
            return (
              <div
                className={styles.questionsTable__body__row}
                key={question.id}
                onClick={e => {
                  openDrawer(e, question)
                }}
              >
                <div
                  className={cn(styles.questionsTable__body__row__cell, "pe-5")}
                >
                  {question.new && (
                    <span
                      className={styles.questionsTable__body__row__newBadge}
                    >
                      New
                    </span>
                  )}
                  {question.body}
                </div>
                <div className={styles.questionsTable__body__row__cell}>
                  {dateAsked}
                </div>
                <div className={styles.questionsTable__body__row__cell}>
                  {officiallyAnswered ? (
                    <span className={styles.questionsTable__body__row__green}>
                      Answered <span> - {dateAnswered}</span>
                    </span>
                  ) : (
                    <span className={styles.questionsTable__body__row__red}>
                      Unanswered
                    </span>
                  )}
                </div>
                <div className={styles.questionsTable__body__row__cell}>
                  {officiallyAnswered ? (
                    <span className="table-cell-action">View</span>
                  ) : (
                    <span
                      className="table-cell-action"
                      onClick={e => {
                        openDrawer(e, question, true)
                      }}
                    >
                      Answer
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default QuestionsTable
