import { useState } from "react"

const useColumnValues = () => {
  const [columnValues, setColumnValues] = useState([
    { name: "Sales Org", accessor: "company_name", visible: true },
    { name: "Buyer Persona(s)", accessor: "selling_personas", visible: true },
    { name: "Job Role", accessor: "user_role", visible: true },
    { name: "Respondents", accessor: "people_count", visible: true },
    { name: "Industry", accessor: "industry_name", visible: true },
    { name: "Division", accessor: "sales_division", visible: true },
    { name: "Avg. Deal Size", accessor: "average_deal_size", visible: true },
    { name: "Base Pay", accessor: "average_base_compensation", visible: true },
    { name: "OTE", accessor: "average_ote", visible: true },
    {
      name: "Top Performer Earnings",
      accessor: "top_performer_earnings",
      visible: false,
    },
    {
      name: "Quota Attainment",
      accessor: "average_quota_attainment",
      visible: true,
    },
    {
      name: "Culture Score",
      accessor: "average_culture_and_leadership_score",
      visible: true,
    },
    {
      name: "Product Market Score",
      accessor: "average_product_market_fit_score",
      visible: true,
    },
    { name: "RepVue Score", accessor: "repvue_score", visible: true },
    { name: "Ratings Count", accessor: "ratings_count", visible: true },
    { name: "HQ Location", accessor: "headquarters", visible: true },
    {
      name: "Diversity and Inclusion Score",
      accessor: "average_diversity_and_inclusion_score",
      visible: false,
    },
    {
      name: "Base Compensation Score",
      accessor: "average_base_compensation_score",
      visible: false,
    },
    {
      name: "Inbound Lead Opportunity Score",
      accessor: "average_inbound_lead_opportunity_flow_score",
      visible: false,
    },
    {
      name: "Incentive Compensation Structure Score",
      accessor: "average_incentive_compensation_structure_score",
      visible: false,
    },
    {
      name: "Professional Development and Training Score",
      accessor: "average_professional_development_and_training_score",
      visible: false,
    },
  ])

  const updateColumnValues = updatedColumn => {
    setColumnValues(updatedColumn)
  }

  return { columnValues, updateColumnValues }
}

export default useColumnValues
