export const getCustomStyles = error => ({
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#badcfc" : isFocused ? "#ebebeb" : undefined,
    ":active": {
      backgroundColor: "#027597",
      color: "#fff",
    },
    color: "rgb(51, 51, 51)",
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    borderColor: error
      ? "#f56161"
      : isFocused || isSelected
      ? "#81bacb"
      : "#e1e4e8",
    borderRadius: "6px",
    boxShadow:
      isFocused || isSelected
        ? "0 0 0 0.25rem rgb(2 117 151 / 25%)"
        : "0px 2px 2px 0px rgba(0, 0, 0, 0.031372549)",
    ":hover": {
      borderColor: "#81bacb;",
      outline: 0,
      boxShadow: "0 0 0 0.25rem rgb(2 117 151 / 25%)",
    },
  }),
  indicatorSeparator: styles => ({ display: "none" }),
  placeholder: styles => ({ ...styles, fontSize: "14px", color: "#96a2ab" }),
})
