import classNames from "classnames"
import styles from "./buttonTab.module.scss"

const ButtonTab = ({ label, onClick, activeTab, slug }) => {
  return (
    <button
      className={classNames(styles.buttonTab, {
        [`${styles.buttonTab__active}`]: activeTab === slug,
      })}
      onClick={() => onClick(slug)}
    >
      {label}
    </button>
  )
}

export default ButtonTab
