import { useState } from "react"

import Form from "react-bootstrap/Form"
import PrimaryButton from "components/shared/Buttons/Primary"

import styles from "./InitialStage.module.scss"

const InitialStage = ({ setStage }) => {
  const [nextStage, setNextStage] = useState(null)
  return (
    <div className="d-flex flex-column gap-4 py-5 text-center align-items-center">
      <h5 className={styles.headline}>
        Before we can post jobs, select your applicant method:
      </h5>
      <Form className="d-flex gap-4 mb-2">
        <Form.Check
          id="external"
          type="radio"
          label="Link to Your Job Board"
          checked={nextStage === "external"}
          className={styles.check}
          onClick={() => {
            if (nextStage !== "external") {
              setNextStage("external")
            } else {
              setNextStage(null)
            }
          }}
          onChange={() => {}}
          data-cy="external-selector"
        />
        <Form.Check
          type="radio"
          id="native"
          label="Manage Applicants via RepVue"
          checked={nextStage === "native"}
          className={styles.check}
          onClick={() => {
            if (nextStage !== "native") {
              setNextStage("native")
            } else {
              setNextStage(null)
            }
          }}
          onChange={() => {}}
          data-cy="repvue-selector"
        />
      </Form>
      <PrimaryButton
        size="small"
        className={styles.button}
        disabled={!nextStage}
        onClick={() => {
          setStage(nextStage)
        }}
        data-cy="applicant-preference-next"
      >
        Next
      </PrimaryButton>
    </div>
  )
}

export default InitialStage
