import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import qs from "qs"
import { registerEmployer } from "api/auth"

import PasswordField from "components/shared/Forms/PasswordField"
import "../signIn/SignIn.scss"

const AcceptInviteRoute = () => {
  const dispatch = useDispatch()
  const { authenticated } = useSelector(state => state.auth)

  const [password, setPassword] = useState("")
  const [password_confirmation, setPasswordConfirmation] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const queryStr = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const token = queryStr.invitation_token

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const params = {
      employer: {
        password,
        password_confirmation,
        invitation_token: token,
      },
    }

    dispatch(registerEmployer(params))
      .then(() => {})
      .catch(response => {
        console.error(response.data)
        setError(true)
        setLoading(false)
      })
  }

  if (authenticated) {
    return <Navigate to={"/"} replace />
  }

  return (
    <div className="sign-in">
      <h5>Accept Your Invitation</h5>
      <p>Set your password below to finish the registration process.</p>

      {error && <div className="alert alert-danger">Something went wrong</div>}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Create a password
          </label>
          <PasswordField
            id="password"
            value={password}
            handleChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Confirm your password
          </label>
          <PasswordField
            id="password_confirmation"
            retype={true}
            value={password_confirmation}
            handleChange={e => setPasswordConfirmation(e.target.value)}
          />
        </div>
        <input
          className="btn btn-primary w-100"
          type="submit"
          disabled={loading}
          value="Register"
        />
      </form>
    </div>
  )
}

export default AcceptInviteRoute
