import Icon from "components/Icon"
import styles from "./featuresProtect.module.scss"

const FeaturesProtect = ({ features = [] }) => {
  return (
    <div className={styles.featuresProtect}>
      {features.map((feature, index) => (
        <div key={`${index}-feature`} className={styles.featuresProtect__item}>
          <span>{feature}</span>
          <Icon name="check" height={14} width={14} />
        </div>
      ))}
    </div>
  )
}

export default FeaturesProtect
