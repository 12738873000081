import Imgix from "react-imgix"
import Icon from "components/Icon"

import styles from "./CompanyBadge.module.scss"

const CompanyBadge = ({ name, logo, competitor }) => {
  return (
    <div className={styles.badge}>
      <div className={styles.wrapper}>
        {logo &&
          <Imgix
            src={`${process.env.REACT_APP_IMAGE_URL}/${logo}`}
            alt={name}
            width={36}
            height={36}
          />
        }
      </div>
      {name}
      {competitor && <Icon name="check" height={14} width={14} />}
    </div>
  )
}

export default CompanyBadge
