import apiCall from "utils/api"

export const getReviews = params => {
  const options = { params }
  return apiCall("get", `reviews`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const pinReview = slug => {
  return apiCall("put", `reviews/${slug}/pin`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const replyReview = (slug, review) => {
  const options = {
    data: {
      response: {
        body: review,
      },
    },
  }

  return apiCall("post", `reviews/${slug}/responses`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
