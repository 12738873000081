import pluralize from "pluralize"

import PrimaryButton from "components/shared/Buttons/Primary"
import styles from "./ActionBar.module.scss"

const ActionBar = ({ selectedRows, handleCreateTalentCompetitors }) => {
  const companyCount = [...new Set(selectedRows.map(row => row.company_name))]
    .length

  const teamCount = selectedRows.length

  const peopleCount = selectedRows.reduce((a, b) => a + b.people_count, 0)

  return (
    <div className={styles.bar}>
      <div className={styles.bar__stats}>
        <span>{`${teamCount} Sales ${pluralize("Team", teamCount)}`}</span>
        <span className={styles.bar__dot}>&#183;</span>
        <span>
          {`${companyCount} Sales ${pluralize("Org", companyCount)}`}{" "}
        </span>
        <span className={styles.bar__dot}>&#183;</span>
        <span>{`${peopleCount} ${pluralize("People", peopleCount)}`}</span>
      </div>
      <PrimaryButton
        size="large"
        onClick={handleCreateTalentCompetitors}
      >{`Save Talent Competitors (${companyCount})`}</PrimaryButton>
    </div>
  )
}

export default ActionBar
