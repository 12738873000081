import { Col } from "react-bootstrap"
import RowCategoriesValued from "./RowCategoriesValued"
import styles from "./ListCategories.module.scss"

const ListCategories = ({ rows = [] }) => {
  return (
    <section className={styles.listCategories}>
      <article className={styles.listCategories__header}>
        <Col lg={4}>
          <span>Category</span>
        </Col>

        <Col lg={2}>
          <span>Rank</span>
        </Col>

        <Col lg={4}>
          <span>Employees Who Ranked this Category #1</span>
        </Col>

        <Col lg={2}>
          <span> Your Avg. Rating</span>
        </Col>
      </article>

      <article className={styles.listCategories__body}>
        {rows?.map((row, index) => (
          <RowCategoriesValued key={`rating-${index}`} rating={row} />
        ))}
      </article>
    </section>
  )
}

export default ListCategories
