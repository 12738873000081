import { useState } from "react"
import SingleSelectFilter from "components/shared/Filters/SingleSelect"

const getRangeStar = (key, min, max) => {
  return `${min}&${key}=${max}&ratings.average_metric_rating.rel=between`
}

const StarsReviewsFilters = ({ setGlobalFilters }) => {
  const ALL_STAR = "all star"
  const KEY = "ratings.average_metric_rating[]"
  const options = [
    { label: "All Reviews", value: ALL_STAR },
    { label: "1 - 2 Stars", value: getRangeStar(KEY, 1, 2) },
    { label: "2 - 3 Stars", value: getRangeStar(KEY, 2, 3) },
    { label: "3 - 4 Stars", value: getRangeStar(KEY, 3, 4) },
    { label: "4 - 5 Stars", value: getRangeStar(KEY, 4, 5) },
  ]
  const [filterState, setFilterState] = useState(null)

  const onChangeFilter = data => {
    setFilterState(data)
    setGlobalFilters(state => {
      const filterLocal = { ...state }
      filterLocal[KEY] = data?.value
      data?.value === ALL_STAR && delete filterLocal[KEY]
      return filterLocal
    })
  }

  return (
    <article>
      <SingleSelectFilter
        setFilters={onChangeFilter}
        filters={filterState}
        options={options}
        icon="star"
        name="star"
      />
    </article>
  )
}

export default StarsReviewsFilters
