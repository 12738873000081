import cn from "classnames"
import { Row, Col } from "react-bootstrap"
import Icon from "components/Icon"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import SettingsButton from "components/talentData/Controls/SettingsButton"
import PeriodFilter from "components/talentData/Filters/PeriodFilter"
import styles from "./TalentDataControl.module.scss"

const TalentDataControls = ({
  filterState,
  setFilterState,
  columnValues,
  updateColumnValues,
  showFilters,
  filterCount,
  setShowModal,
  showModal,
  setShowFilters,
}) => {
  return (
    <Row className="mb-3">
      <Col
        lg={{ span: 6 }}
        className={cn("d-flex", styles.talentData__filtersContainer)}
      >
        <PeriodFilter setFilters={setFilterState} filters={filterState} />

        <SettingsButton
          columnValues={columnValues}
          updateColumnValues={updateColumnValues}
        />

        <SecondaryLightButton
          onClick={() => {
            setShowFilters(!showFilters)
          }}
          className={cn(`${styles.talentData__filters}`, {
            [`${styles.talentData__filters__selected}`]: showFilters,
          })}
        >
          <Icon
            name={showFilters ? "close" : "filter"}
            height={19}
            width={19}
          />
          <span>Filters</span>
          {filterCount > 0 && (
            <div className={styles.talentData__filters__count}>
              {filterCount}
            </div>
          )}
        </SecondaryLightButton>
      </Col>
      <Col lg={{ span: 6 }} className="d-flex justify-content-end gap-2 g-0">
        <SecondaryLightButton
          className={styles.talentData__save}
          onClick={() => setShowModal(!showModal)}
        >
          <span>Save View as Talent Profile</span>
        </SecondaryLightButton>
      </Col>
    </Row>
  )
}

export default TalentDataControls
