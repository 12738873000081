import Icon from "components/Icon"

import styles from "./JobRoles.module.scss"

const JobRoles = ({ talents }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  })

  return (
    <div className={styles.table}>
      <table>
        <tr>
          <th className={styles.addpad}>Job Role</th>
          <th>People</th>
          <th>Industry</th>
          <th>Division</th>
          <th>Avg. Deal Size</th>
          <th>Quota %</th>
          <th>Buyer Persona(s)</th>
          <th>OTE</th>
          <th>Avg. Base Comp</th>
          <th>Base Comp Score</th>
          <th>Culture Score</th>
          <th>DEI Score</th>
          <th>Inbound Lead Score</th>
          <th>Comp Structure Score</th>
          <th>Product Market Fit Score</th>
          <th>Prof. Development Score</th>
        </tr>
        {talents.map(row => {
          const {
            user_role,
            people_count,
            industry_name,
            sales_division,
            average_deal_size,
            average_quota_attainment,
            selling_personas,
            average_ote,
            average_base_compensation,
            average_base_compensation_score,
            average_culture_and_leadership_score,
            average_diversity_and_inclusion_score,
            average_inbound_lead_opportunity_flow_score,
            average_incentive_compensation_structure_score,
            average_product_market_fit_score,
            average_professional_development_and_training_score,
          } = row
          return (
            <tr>
              <td className={`${styles.role} ${styles.addpad}`}>{user_role}</td>
              <td>
                <div className={styles.peopleWrapper}>
                  <Icon name="people" height="16" width="16" />
                  {people_count}
                </div>
              </td>
              <td>{industry_name}</td>
              <td>{sales_division}</td>
              <td>{formatter.format(average_deal_size)}</td>
              <td>{`${average_quota_attainment}%`}</td>
              <td>
                {selling_personas.length > 0
                  ? selling_personas.map(persona => persona.name).join(", ")
                  : "--"}
              </td>
              <td>{formatter.format(average_ote)}</td>
              <td>{formatter.format(average_base_compensation)}</td>
              <td>{average_base_compensation_score}</td>
              <td>{average_culture_and_leadership_score}</td>
              <td>{average_diversity_and_inclusion_score}</td>
              <td>{average_inbound_lead_opportunity_flow_score}</td>
              <td>{average_incentive_compensation_structure_score}</td>
              <td>{average_product_market_fit_score}</td>
              <td>{average_professional_development_and_training_score}</td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export default JobRoles
