import classNames from "classnames"

import Icon from "components/Icon"

import styles from "./StatusBar.module.scss"

const StatusBar = ({ step }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.step, {
          [styles.completed]: step > 1,
          [styles.current]: step === 1,
        })}
      >
        <div className={styles.indicator}>
          {step <= 1 ? 1 : <Icon name="check" height="14" width="14" />}
        </div>
        <span>Overview</span>
      </div>
      <div className={styles.dash} />
      <div
        className={classNames(styles.step, {
          [styles.completed]: step > 2,
          [styles.current]: step === 2,
        })}
      >
        <div className={styles.indicator}>{step <= 2 ? 2 : <Icon name="check" height="14" width="14" />}</div>
        <span>Job Description</span>
      </div>
      <div className={styles.dash} />
      <div
        className={classNames(styles.step, { [styles.current]: step === 3 })}
      >
        <div className={styles.indicator}>3</div>
        <span>Review Job</span>
      </div>
    </div>
  )
}

export default StatusBar
