import { useState } from "react"
import SingleSelectFilter from "components/shared/Filters/SingleSelect"

const SortReviewsFilters = ({ setGlobalFilters }) => {
  const options = [
    { label: "Newest", value: "ratings.created_at&sort_direction=desc" },
    { label: "Oldest", value: "ratings.created_at&sort_direction=asc" },
    {
      label: "Lowest rating",
      value: "ratings.average_metric_rating&sort_direction=asc",
    },
    {
      label: "Highest rating",
      value: "ratings.average_metric_rating&sort_direction=desc",
    },
    { label: "Featured", value: "reviews.pinned_at&sort_direction=asc" },
  ]

  const [filterState, setFilterState] = useState({
    label: "Newest",
    value: "ratings.created_at&sort_direction=desc",
  })

  const onChangeFilter = data => {
    setFilterState(data)
    setGlobalFilters(state => ({
      ...state,
      sort_key: data?.value,
    }))
  }

  return (
    <article>
      <SingleSelectFilter
        setFilters={onChangeFilter}
        filters={filterState}
        options={options}
        hasIcon={false}
        label="Sort"
        name="sort"
      />
    </article>
  )
}

export default SortReviewsFilters
