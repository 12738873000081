import cn from "classnames"

import EmailAddress from "components/shared/EmailAddress"
import LinkedinProfile from "components/shared/LinkedinProfile"

import styles from "./candidateSection.module.scss"

const CandidateSection = ({ details }) => {
  return (
    <article className={styles.candidateSection}>
      <h6>Candidate</h6>
      <div className={styles.candidateSection__details__row}>
        <div className={styles.candidateSection__details__row__title}>
          First Name
        </div>
        <div className={cn(styles.candidateSection__details__row__detail)}>
          {details?.user?.first_name || "--"}
        </div>
      </div>
      <div className={styles.candidateSection__details__row}>
        <div className={styles.candidateSection__details__row__title}>
          Last Name
        </div>
        <div className={cn(styles.candidateSection__details__row__detail)}>
          {details?.user?.last_name || "--"}
        </div>
      </div>
      <div className={styles.candidateSection__details__row}>
        <div className={styles.candidateSection__details__row__title}>
          Email Address
        </div>
        <div className={styles.candidateSection__details__row__detail}>
          <EmailAddress email={details?.user?.email || "--"} />
        </div>
      </div>
      <div className={styles.candidateSection__details__row}>
        <div className={styles.candidateSection__details__row__title}>
          LinkedIn URL
        </div>
        <div className={styles.candidateSection__details__row__detail}>
          <LinkedinProfile linkedinProfile={details?.user?.linkedin_url} />
        </div>
      </div>

      <div className={styles.candidateSection__details__row}>
        <div className={styles.candidateSection__details__row__title}>
          Phone Number
        </div>
        <div className={styles.candidateSection__details__row__detail}>
          {details?.user?.phone || "--"}
        </div>
      </div>

      <div className={styles.candidateSection__details__row}>
        <div className={styles.candidateSection__details__row__title}>
          Gender
        </div>
        <div
          className={cn(
            styles.candidateSection__details__row__detail,
            "text-capitalize"
          )}
        >
          {details?.user?.gender || "--"}
        </div>
      </div>

      <div className={styles.candidateSection__details__row}>
        <div className={styles.candidateSection__details__row__title}>
          Ethnicity
        </div>
        <div
          className={cn(
            styles.candidateSection__details__row__detail,
            "text-capitalize"
          )}
        >
          {details?.user?.ethnicity || "--"}
        </div>
      </div>
    </article>
  )
}

export default CandidateSection
