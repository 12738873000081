import { trackAction } from "utils/mixpanel"

import { Link } from "react-router-dom"

import styles from "./jobTitle.module.scss"

const JobTitle = ({ jobTitle, data, company }) => {
  const ROUTE = "/jobs"
  return (
    <Link
      className={styles.jobTitle}
      to={`${ROUTE}/${data?.id}`}
      onClick={() => {
        trackAction("Job Listing Clicked", {
          title: data?.title,
          company: company?.name,
        })
      }}
    >
      <h6>{jobTitle}</h6>
    </Link>
  )
}

export default JobTitle
