import { useEffect, useState } from "react"
import cn from "classnames"
import Icon from "components/Icon"
import styles from "./GrowthLabel.module.scss"

function GrowthLabel({ growth, label = true }) {
  const [growthValue, setGrowthValue] = useState()
  const MIN = -1.5
  const MAX = 1.5

  useEffect(() => {
    if (growth) setGrowthValue(parseFloat(growth))
  }, [growth])

  return (
    <article className={styles.growthLabel}>
      <div
        className={cn(styles.growthLabel__container, {
          [styles.growthLabel__container__down]: growthValue < MIN,
          [styles.growthLabel__container__up]: growthValue > MAX,
          [styles.growthLabel__container__none]:
            growthValue >= MIN && growthValue <= MAX,
        })}
      >
        {(growthValue > MAX || growthValue < MIN) && (
          <Icon name="up-arrow" height="16" width="16" />
        )}

        <span>{growth !== "0.00" && growth ? growth : 0}%</span>
      </div>
      {label && (
        <span className={styles.growthLabel__label}>Since profile claimed</span>
      )}
    </article>
  )
}

export default GrowthLabel
