import Card from "components/shared/Card"
import RowScore from "components/dashboard/ScoresWidget/RowScore"
import styles from "./ScoresWidget.module.scss"

const ScoresWidget = ({ metrics = [] }) => {
  return (
    <div className={styles.scores}>
      <Card className={styles.scores__card}>
        <div className={styles.scores__title}>Your Category Scores</div>

        <div className={styles.scores__description}>
          <p>Encourage your employees to rate your company to get ranked!</p>
        </div>

        {metrics?.map(metric => (
          <RowScore
            key={metric?.name}
            score={metric?.raw_average}
            label={metric?.name}
          />
        ))}
      </Card>
    </div>
  )
}

export default ScoresWidget
