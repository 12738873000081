import Imgix from "react-imgix"
import styles from "./logo.row.module.scss"

const LogoRow = ({ label, logoValue }) => {
  return (
    <article className={styles.logo}>
      <span className={styles.logo__label}>{label}</span>
      <Imgix
        src={`${process.env.REACT_APP_IMAGE_URL}/${logoValue}`}
        alt={label}
        width={75}
        height={75}
      />
    </article>
  )
}

export default LogoRow
