import { useEffect, useState } from "react"
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table"
import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"
import TableComponent from "components/shared/Table"
import Card from "components/shared/Card"
import AllEmptyTable from "./EmptyTable/AllEmpty"
import styles from "./tableApplicants.module.scss"

const TableApplicantsComponent = ({
  columns,
  data,
  section = "all",
  onRowClick,
  currentSort,
  handleSort,
}) => {
  const [loading, setLoading] = useState(true)

  const EMPTYSTATES = {
    all: <AllEmptyTable />,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {
        Filter: SelectColumnFilter,
        DefaultFilter: DefaultFilterForColumn,
      },
      initialState: {
        sortBy: [
          {
            id: currentSort.length ? currentSort[0].id : null,
            desc: currentSort.length ? currentSort[0].desc : null,
          },
        ],
      },
      manualSortBy: true,
      manualPagination: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  )

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (!loading) {
      handleSort(sortBy[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  return (
    <>
      <Card className={styles.tableContainerApplicants}>
        <div className={styles.tableContainerApplicants__header}>
          <h5>{data.length} Applicants</h5>
        </div>

        {data.length > 0 ? (
          <TableComponent
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            onRowClick={onRowClick}
            className={styles.tableContainerApplicants__table}
            headerFixed
          />
        ) : (
          EMPTYSTATES[section]
        )}
      </Card>
    </>
  )
}

export default TableApplicantsComponent
