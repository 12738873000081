import { useState } from "react"
import { trackAction } from "utils/mixpanel"
import { deleteTalentCompetitor } from "api/talent"
import {
  createJobAdvertisement,
  deleteJobAdvertisement,
} from "api/job_advertisements"
import classNames from "classnames"

import Imgix from "react-imgix"
import Dropdown from "react-bootstrap/Dropdown"
import Icon from "components/Icon"
import JobRoles from "./JobRoles"

import "./Competitor.scss"

const Competitor = ({
  talentCompetitor,
  rank,
  fetchTalentCompetitors,
  handleSuccess,
}) => {
  const { advertisable, competitor_company, job_advertisement_id } =
    talentCompetitor
  const {
    id,
    name,
    logo,
    slug,
    repvue_score,
    ratings_count,
    public_url,
    linkedin_url,
    talents,
  } = competitor_company
  const [showJobRoles, setShowJobRoles] = useState(false)

  const deleteTalentCompetitorById = id => {
    deleteTalentCompetitor(id)
      .then(() => {
        handleSuccess()
        fetchTalentCompetitors()
      })
      .catch(err => {
        console.error(err)
      })
  }

  const addJobAdvertisement = competitorId => {
    // wrap in array because API takes multiple
    createJobAdvertisement([competitorId])
      .then(() => {
        fetchTalentCompetitors()
      })
      .catch(err => {
        console.error(err)
      })
  }

  const removeJobAdvertisement = id => {
    deleteJobAdvertisement(id)
      .then(res => {
        fetchTalentCompetitors()
      })
      .catch(err => {
        console.log("err", err)
        // toast.error("Error: " + err.data.competitor_company_id[0])
      })
  }

  const toggleJobAdvertisement = (checked, competitorId) => {
    if (checked) {
      addJobAdvertisement(competitorId)
    } else {
      removeJobAdvertisement(job_advertisement_id)
    }
  }

  return (
    <div className="competitor">
      <div className="competitor-row">
        <div className="data-cell">
          {logo && (
            <div className="logo">
              <Imgix
                src={`${process.env.REACT_APP_IMAGE_URL}/${logo}`}
                alt={name}
                width={50}
                height={50}
              />
            </div>
          )}
          <div className="name">
            <div className="top">
              <a target="_blank" href={public_url} rel="noreferrer">
                {name}
                <Icon name="external-link" height="20" width="20" />
              </a>
            </div>
            <div className="bottom">
              Data for {talents.length} roles
              <Icon
                name="chevron"
                height="15"
                width="15"
                className={classNames("expand-competitor", {
                  "expand-competitor-active": showJobRoles,
                })}
                onClick={() => setShowJobRoles(!showJobRoles)}
              />
            </div>
          </div>
        </div>
        <div className="score-cell">{repvue_score.toString().slice(0, 5)}</div>
        <div className="ratings-cell">{ratings_count}</div>
        <div className="advertise-cell">
          {advertisable ? (
            <div className={`label ${!!job_advertisement_id ? "on" : "off"}`}>
              Advertise jobs
              <input
                type="checkbox"
                name="toggle"
                className="apple-switch"
                id="toggle"
                onChange={event => {
                  toggleJobAdvertisement(event.target.checked, id)
                }}
                checked={!!job_advertisement_id}
              />
            </div>
          ) : (
            <div className="label unavailable">Jobs ads unavailable</div>
          )}

          <div className="bottom">{`companies/${slug}/jobs`}</div>
        </div>
        <div className="actions-cell">
          <Dropdown align="end">
            <Dropdown.Toggle>
              <Icon name="ellipsis" height="25" width="25" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href={public_url}
                target="_blank"
                onClick={() =>
                  trackAction("Call to Action Clicked", {
                    location: `Talent Competitors Table Actions`,
                    click_text: "View on RepVue",
                    click_url: public_url,
                    modal_name: null,
                  })
                }
              >
                View on RepVue
                <Icon name="external-link" height="20" width="20" />
              </Dropdown.Item>
              <Dropdown.Item href={linkedin_url} target="_blank">
                View on LinkedIn
                <Icon name="external-link" height="20" width="20" />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  deleteTalentCompetitorById(talentCompetitor.id)
                }}
              >
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {showJobRoles && <JobRoles talents={talents} />}
    </div>
  )
}

export default Competitor
