import { useDispatch, useSelector } from "react-redux"
import { signOut } from "api/auth"
import { identifyUser } from "utils/mixpanel"
import cn from "classnames"

import styles from "./SignOutButton.module.scss"

const SignOutButton = ({ collapsed }) => {
  const dispatch = useDispatch()
  const { currentEmployer } = useSelector(state => state.auth)

  return (
    <div
      className={cn({
        [`${styles.signOutWrapper}`]: true,
        [`${styles.signOutWrapper__collapsed}`]: collapsed,
      })}
    >
      <div
        className={styles.signOut}
        onClick={() => {
          identifyUser(currentEmployer)
          dispatch(signOut())
        }}
      >
        Sign Out
      </div>
    </div>
  )
}

export default SignOutButton
