import { useMemo } from "react"
import { searchCompanies } from "api/companies"
import { getMetroLocations } from "api/metro_locations"
import CategorySelect from "../Filters/CategorySelect"
import SliderSelect from "../Filters/SliderSelect"
import CompanySearch from "../Filters/CompanySearch"
import HeadquartersSearch from "../Filters/HeadquartersSearch"

const useFilters = (
  filters,
  setFilters,
  companies,
  setCompanies,
  headquarters,
  setHeadquarters,
  formOptions,
  setFormOptions,
  handleFilter,
  currentSort
) => {
  const handleCompanySelect = company => {
    if (
      filters.selectedCompanies.includes &&
      filters.selectedCompanies.includes(company)
    ) {
      const newSelectedCompanies = [...filters.selectedCompanies].filter(
        selectedCompany => selectedCompany !== company
      )
      setFilters({ ...filters, selectedCompanies: newSelectedCompanies })
    } else {
      let newSelectedCompanies = [...filters.selectedCompanies]
      newSelectedCompanies.push(company)
      setFilters({ ...filters, selectedCompanies: newSelectedCompanies })
    }
  }

  const handleHeadquartersSelect = headquarters => {
    if (filters.headquarters && filters.headquarters.includes(headquarters)) {
      const newHeadquarters = [...filters.headquarters].filter(
        selectedCompany => selectedCompany !== headquarters
      )
      setFilters({ ...filters, headquarters: newHeadquarters })
    } else {
      let newHeadquarters = [...filters.headquarters]
      newHeadquarters.push(headquarters)
      setFilters({ ...filters, headquarters: newHeadquarters })
    }
  }

  const conjureWatchedValues = (state, min, max, type) => {
    const valueArray = []

    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    })

    Object.keys(state).forEach(valueRange => {
      if (valueRange === "min" && state[`${valueRange}`] > min) {
        valueArray.push(
          type === "currency"
            ? `Min: ${currencyFormatter.format(state.min)}`
            : type === "percentage"
            ? `Min: ${state.min}%`
            : `Min: ${state.min}`
        )
      } else if (valueRange === "max" && state[`${valueRange}`] < max) {
        valueArray.push(
          type === "currency"
            ? `Max: ${currencyFormatter.format(state.max)}`
            : type === "percentage"
            ? `Max: ${state.max}%`
            : `Max: ${state.max}`
        )
      }
    })
    return valueArray.length ? valueArray : []
  }

  const searchCompanyFilter = search => {
    searchCompanies(search)
      .then(response => setCompanies(response.companies))
      .catch(error => console.error(error))
  }

  const searchHeadquartersFilter = search => {
    getMetroLocations(search)
      .then(response => setHeadquarters(response.rows))
      .catch(error => console.error(error))
  }

  const removeSliderValue = (key, value, min, max) => {
    if (value.slice(0, 3) === "Min") {
      setFilters({
        ...filters,
        [`${key}`]: { min: min, max: filters[`${key}`].max },
      })
    } else {
      setFilters({
        ...filters,
        [`${key}`]: { min: filters[`${key}`].min, max: max },
      })
    }
  }

  const filterRequest = () => {
    handleFilter(0, currentSort, "")
  }

  const FILTERS = useMemo(
    () => [
      {
        title: "Sales Org",
        body: (
          <CompanySearch
            companies={companies}
            handleSearch={searchCompanyFilter}
            selectedCompanies={filters.selectedCompanies}
            handleSelect={handleCompanySelect}
          />
        ),
        watchedValues:
          filters.selectedCompanies?.length > 0
            ? filters.selectedCompanies.map(company =>
                company.name ? company.name : company
              )
            : [],
        removeFilter: company => {
          const newSelectedCompanies = [...filters.selectedCompanies].filter(
            selectedCompany =>
              selectedCompany.name
                ? selectedCompany.name !== company
                : selectedCompany !== company
          )
          setFilters({ ...filters, selectedCompanies: newSelectedCompanies })
        },
      },
      {
        title: "Job Role",
        watchedValues: filters.selectedRoles,
        removeFilter: role => {
          const newRoles = [...filters.selectedRoles].filter(
            selectedRole => selectedRole !== role
          )
          setFilters({ ...filters, selectedRoles: newRoles })
        },
        get body() {
          return (
            <CategorySelect
              categories={formOptions.job_roles}
              watchedCategories={filters.selectedRoles}
              addFilter={role =>
                setFilters({
                  ...filters,
                  selectedRoles: [...filters.selectedRoles, role],
                })
              }
              removeFilter={this.removeFilter}
            />
          )
        },
      },
      {
        title: "Respondents",
        watchedValues: conjureWatchedValues(filters.peopleCount, 0, 200),
        removeFilter: value => removeSliderValue("peopleCount", value, 0, 200),
        body: (
          <SliderSelect
            min={0}
            max={200}
            filters={filters}
            filter={"peopleCount"}
            setFilter={setFilters}
            step={1}
            labelMin="0"
            labelMax="200"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Industry",
        watchedValues: filters.selectedIndustries,
        removeFilter: industry => {
          const newIndustries = [...filters.selectedIndustries].filter(
            selectedIndustry => selectedIndustry !== industry
          )
          setFilters({ ...filters, selectedIndustries: newIndustries })
        },
        get body() {
          return (
            <CategorySelect
              categories={formOptions.industries}
              watchedCategories={filters.selectedIndustries}
              addFilter={industry =>
                setFilters({
                  ...filters,
                  selectedIndustries: [...filters.selectedIndustries, industry],
                })
              }
              removeFilter={this.removeFilter}
            />
          )
        },
      },
      {
        title: "Division",
        watchedValues: filters.selectedSegments,
        removeFilter: segment => {
          const newSegments = [...filters.selectedSegments].filter(
            selectedSegment => selectedSegment !== segment
          )
          setFilters({ ...filters, selectedSegments: newSegments })
        },
        get body() {
          return (
            <CategorySelect
              categories={formOptions.segments}
              watchedCategories={filters.selectedSegments}
              addFilter={segment =>
                setFilters({
                  ...filters,
                  selectedSegments: [...filters.selectedSegments, segment],
                })
              }
              removeFilter={this.removeFilter}
            />
          )
        },
      },
      {
        title: "Avg. Deal Size",
        watchedValues: conjureWatchedValues(
          filters.avgDealSize,
          0,
          1000000,
          "currency"
        ),
        removeFilter: value =>
          removeSliderValue("avgDealSize", value, 0, 1000000),
        body: (
          <SliderSelect
            min={0}
            max={1000000}
            filters={filters}
            filter="avgDealSize"
            setFilter={setFilters}
            step={2500}
            labelMin="$0"
            labelMax="$1M"
            filterRequest={filterRequest}
            type="currency"
          />
        ),
      },
      {
        title: "Quota Attainment",
        watchedValues: conjureWatchedValues(
          filters.quotaAttainment,
          0,
          100,
          "percentage"
        ),
        removeFilter: value =>
          removeSliderValue("quotaAttainment", value, 0, 100),
        body: (
          <SliderSelect
            min={0}
            max={100}
            filters={filters}
            filter="quotaAttainment"
            setFilter={setFilters}
            step={1}
            labelMin="0%"
            labelMax="100%"
            filterRequest={filterRequest}
            type="percentage"
          />
        ),
      },
      {
        title: "Buyer Persona",
        watchedValues: filters.selectedPersonas,
        removeFilter: persona => {
          const newPersonas = [...filters.selectedPersonas].filter(
            selectedPersonas => selectedPersonas !== persona
          )
          setFilters({ ...filters, selectedPersonas: newPersonas })
        },
        get body() {
          return (
            <CategorySelect
              categories={formOptions.selling_personas}
              watchedCategories={filters.selectedPersonas}
              addFilter={persona =>
                setFilters({
                  ...filters,
                  selectedPersonas: [...filters.selectedPersonas, persona],
                })
              }
              removeFilter={this.removeFilter}
            />
          )
        },
      },
      {
        title: "OTE",
        watchedValues: conjureWatchedValues(filters.ote, 0, 500000, "currency"),
        removeFilter: value => removeSliderValue("ote", value, 0, 500000),
        body: (
          <SliderSelect
            min={0}
            max={500000}
            filters={filters}
            filter="ote"
            setFilter={setFilters}
            step={1000}
            labelMin="$0"
            labelMax="$500K"
            type="currency"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Top Performer Earnings",
        watchedValues: conjureWatchedValues(
          filters.topPerformerEarnings,
          0,
          2000000,
          "currency"
        ),
        removeFilter: value =>
          removeSliderValue("topPerformerEarnings", value, 0, 2000000),
        body: (
          <SliderSelect
            min={0}
            max={2000000}
            filters={filters}
            filter="topPerformerEarnings"
            setFilter={setFilters}
            step={2500}
            labelMin="$0"
            labelMax="$2M"
            type="currency"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Base Pay",
        watchedValues: conjureWatchedValues(
          filters.basePay,
          0,
          300000,
          "currency"
        ),
        type: "currency",
        removeFilter: value => removeSliderValue("basePay", value, 0, 300000),
        body: (
          <SliderSelect
            min={0}
            max={300000}
            filters={filters}
            filter="basePay"
            setFilter={setFilters}
            step={500}
            labelMin="$0"
            labelMax="$300K"
            type="currency"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Culture Score",
        removeFilter: value => removeSliderValue("cultureScore", value, 0, 5),
        watchedValues: conjureWatchedValues(filters.cultureScore, 0, 5),
        body: (
          <SliderSelect
            min={0}
            max={5}
            filters={filters}
            filter="cultureScore"
            setFilter={setFilters}
            step={0.1}
            labelMin="0"
            labelMax="5"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Product Market Fit Score",
        removeFilter: value =>
          removeSliderValue("productMarketFitScore", value, 0, 5),
        watchedValues: conjureWatchedValues(
          filters.productMarketFitScore,
          0,
          5
        ),
        body: (
          <SliderSelect
            min={0}
            max={5}
            filters={filters}
            filter="productMarketFitScore"
            setFilter={setFilters}
            step={0.1}
            labelMin="0"
            labelMax="5"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "RepVue Score",
        removeFilter: value => removeSliderValue("repVueScore", value, 0, 100),
        watchedValues: conjureWatchedValues(filters.repVueScore, 0, 100),
        body: (
          <SliderSelect
            min={0}
            max={100}
            filters={filters}
            filter="repVueScore"
            setFilter={setFilters}
            step={1}
            labelMin="0"
            labelMax="100"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Headquarters",
        body: (
          <HeadquartersSearch
            headquartersArray={headquarters}
            handleSearch={searchHeadquartersFilter}
            selectedHeadquarters={filters.headquarters}
            handleSelect={handleHeadquartersSelect}
          />
        ),
        watchedValues:
          filters.headquarters?.length > 0
            ? filters.headquarters.map(location => location.name)
            : [],
        removeFilter: headquarters => {
          const newHeadquarters = [...filters.headquarters].filter(location =>
            location.name
              ? location.name !== headquarters
              : location !== headquarters
          )
          setFilters({ ...filters, headquarters: newHeadquarters })
        },
      },
      {
        title: "Diversity and Inclusion Score",
        removeFilter: value =>
          removeSliderValue("diversityAndInclusionScore", value, 0, 5),
        watchedValues: conjureWatchedValues(
          filters.diversityAndInclusionScore,
          0,
          5
        ),
        body: (
          <SliderSelect
            min={0}
            max={5}
            filters={filters}
            filter="diversityAndInclusionScore"
            setFilter={setFilters}
            step={0.1}
            labelMin="0"
            labelMax="5"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Base Compensation Score",
        removeFilter: value =>
          removeSliderValue("baseCompensationScore", value, 0, 5),
        watchedValues: conjureWatchedValues(
          filters.baseCompensationScore,
          0,
          5
        ),
        body: (
          <SliderSelect
            min={0}
            max={5}
            filters={filters}
            filter="baseCompensationScore"
            setFilter={setFilters}
            step={0.1}
            labelMin="0"
            labelMax="5"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Inbound Lead Opportunity Score",
        removeFilter: value =>
          removeSliderValue("inboundLeadScore", value, 0, 5),
        watchedValues: conjureWatchedValues(filters.inboundLeadScore, 0, 5),
        body: (
          <SliderSelect
            min={0}
            max={5}
            filters={filters}
            filter="inboundLeadScore"
            setFilter={setFilters}
            step={0.1}
            labelMin="0"
            labelMax="5"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Incentive Compensation Structure Score",
        removeFilter: value =>
          removeSliderValue("incentiveCompensationStructureScore", value, 0, 5),
        watchedValues: conjureWatchedValues(
          filters.incentiveCompensationStructureScore,
          0,
          5
        ),
        body: (
          <SliderSelect
            min={0}
            max={5}
            filters={filters}
            filter="incentiveCompensationStructureScore"
            setFilter={setFilters}
            step={0.1}
            labelMin="0"
            labelMax="5"
            filterRequest={filterRequest}
          />
        ),
      },
      {
        title: "Professional Development and Training Score",
        removeFilter: value =>
          removeSliderValue("professionalDevelopmentScore", value, 0, 5),
        watchedValues: conjureWatchedValues(
          filters.professionalDevelopmentScore,
          0,
          5
        ),
        body: (
          <SliderSelect
            min={0}
            max={5}
            filters={filters}
            filter="professionalDevelopmentScore"
            setFilter={setFilters}
            step={0.1}
            labelMin="0"
            labelMax="5"
            filterRequest={filterRequest}
          />
        ),
      },
    ],
    [
      filters,
      setFilters,
      companies,
      setCompanies,
      headquarters,
      setHeadquarters,
      formOptions,
      setFormOptions,
    ]
  )

  return {
    FILTERS,
  }
}

export default useFilters
