import { useState } from "react"
import { trackAction } from "utils/mixpanel"
import Card from "components/shared/Card"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import CardInfo from "./CardInfo"
import GetMoreRatingsModal from "components/dashboard/Ratings/Modal"
import MultipleProgressbar from "./MultipleProgressbar"
import StrippedProgressBar from "./StripedProgressBar"

import styles from "./ProfileRanked.module.scss"

const PROGRESSBAR_RATINGS = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
]

const PROGRESSBAR_USERS = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
]

const ProfileRankedWidget = ({ data = {} }) => {
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)

  return (
    <div className={styles.profileRanked}>
      <Card className={styles.profileRanked__card}>
        <div className={styles.profileRanked__title}>
          Get Your Profile Ranked
        </div>

        <div className={styles.profileRanked__description}>
          <p>
            Your profile has not met the criteria to be ranked and receive a
            RepVue score.
          </p>
        </div>

        <article className={styles.profileRanked__progressContainer}>
          <MultipleProgressbar
            progressBarData={PROGRESSBAR_RATINGS}
            value={data?.ratings_count}
            label="At least 6 overall ratings"
          />

          <StrippedProgressBar
            value={data?.verified_ratings_percent}
            valueStriped={30}
            label="At least 70% verification score"
          />

          <MultipleProgressbar
            progressBarData={PROGRESSBAR_USERS}
            value={data?.distinct_raters_count}
            label="Rated by at least 4 unique users"
          />
        </article>

        <CardInfo />

        <SecondaryLightButton
          className={styles.profileRanked__action}
          onClick={() => {
            setShowModalConfirmation(true)
            trackAction("Call to Action Clicked", {
              location: "Dashboard",
              click_text: "Get More Ratings",
              click_url: null,
              modal_name: "Get More Ratings Modal",
            })
          }}
        >
          Get More Ratings
        </SecondaryLightButton>
      </Card>

      <GetMoreRatingsModal
        show={showModalConfirmation}
        modalClose={setShowModalConfirmation}
      />
    </div>
  )
}

export default ProfileRankedWidget
