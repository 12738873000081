import { useEffect, useState } from "react"
import { Button, ProgressBar } from "react-bootstrap"
import {
  createJobAdvertisement,
  getJobAdvertisements,
  deleteJobAdvertisement,
} from "api/job_advertisements"
import pluralize from "pluralize"
import { useSelector } from "react-redux"
import { pageCall } from "utils/mixpanel"

import { Link } from "react-router-dom"
import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"
import JobAdsTable from "components/ads/Table"
import AddCompanyModal from "components/shared/Modals/AddCompany"
import Confirmation from "components/shared/Notifications/Confirmation"

import styles from "./Ads.module.scss"

const JobAdsRoute = () => {
  const { currentEmployer } = useSelector(state => state.auth)
  const [jobAds, setJobAds] = useState([])
  const [showText, setShowText] = useState(false)
  const [show, setShow] = useState(false)
  const [addedCompanies, setAddedCompanies] = useState([])
  const [showSuccess, setShowSuccess] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)

  useEffect(() => {
    fetchJobAdvertisements()
  }, [])

  const handleClose = () => {
    setShow(false)
  }

  const handleSuccessClose = () => {
    setShowSuccess(false)
    setAddedCompanies([])
  }

  const handleShow = () => setShow(true)

  const fetchJobAdvertisements = () => {
    getJobAdvertisements()
      .then(res => {
        const dataArray = res.data.job_advertisements
        const userCompany = {
          id: 9999999,
          created_at: null,
          updated_at: null,
          competitor_company: {
            ...currentEmployer.company,
          },
        }
        const repvueJobsBoard = {
          id: 9999998,
          created_at: null,
          updated_at: null,
          competitor_company: {
            id: 9999997,
            ratings_count: 8,
            repvue_score: 70.30041280012948,
            name: "RepVue Jobs Board",
            slug: "repvue-jobs-board",
            logo: "",
            website: "https://www.repvue.com/",
            public_url: "sales-jobs",
            linkedin_url: "",
            jobsBoard: true,
          },
        }
        dataArray.unshift(repvueJobsBoard)
        dataArray.unshift(userCompany)
        setJobAds(dataArray)
      })
      .catch(e => {
        console.log("e", e)
      })
  }

  const addJobAdvertisement = (selectedCompanies, selectedCompaniesNames) => {
    createJobAdvertisement(selectedCompanies)
      .then(res => {
        fetchJobAdvertisements()
        setAddedCompanies(selectedCompaniesNames)
        setShowSuccess(true)
      })
      .catch(e => {
        console.log("addJobAdvertisement error", e)
      })
      .finally(() => {
        handleClose()
      })
  }

  const removeAdLocation = id => {
    deleteJobAdvertisement(id)
      .then(res => {
        setJobAds(jobAds.filter(ja => ja.id !== id))
        setShowDeleteSuccess(true)
      })
      .catch(e => {
        console.log("e", e)
      })
  }

  const maxJobsAds = 25
  const jobAdsCount = jobAds.length - 2
  const progress = (jobAdsCount / maxJobsAds) * 100
  const remainingProgress = maxJobsAds - jobAdsCount

  useEffect(() => {
    pageCall("Job Ads", "Jobs")
  }, [])

  return (
    <div className="ads scroll-container">
      <Header title="Jobs Advertisements" />
      <Content>
        <div className={styles.titleWrapper}>
          <h3>Jobs Advertisements</h3>
          <p>
            Increase engagement on your job listings by advertising them on your
            talent competitor{" "}
            <span style={{ display: showText ? "inline" : "none" }}>
              's profiles. You can also manage job ads on your{" "}
              <Link to="/competitors">
                <u>talent competitor list</u>
              </Link>
            </span>
            <span
              style={{
                display: !showText ? "inline" : "none",
                cursor: "pointer",
              }}
              onClick={() => setShowText(true)}
            >
              ... <u>More</u>
            </span>
          </p>
        </div>
        <div className={styles.wrapper}>
          {jobAdsCount === maxJobsAds && (
            <div className={styles.topText}>
              You are at the limit for Job Ads. Please remove Ads to continue
            </div>
          )}
          <Button
            className={styles.topButton}
            onClick={handleShow}
            disabled={jobAdsCount === maxJobsAds}
          >
            <Icon name="add" height="20" width="20" /> New Ad Location
          </Button>
          <div className={styles.jobCounts}>
            <span className={styles.progressCount}>
              {jobAdsCount}/{maxJobsAds}
            </span>
            <ProgressBar now={progress} />
            <span className={styles.remainingCount}>
              {remainingProgress} remaining!
            </span>
          </div>
          <div className={styles.tableWrapper}>
            <div className={styles.tableTitle}>
              <h3>Your Job Ad Locations</h3>
            </div>
            <JobAdsTable data={jobAds} handleDelete={removeAdLocation} />
          </div>
          {/* <div className={styles.footer}>
            <div className={styles.twoCompanies}>
              <div className={styles.circleCompany}>
                <Imgix
                  src={`${process.env.REACT_APP_IMAGE_URL}/0z1wb8dhmvu0j9kzzoc36ja3c9p6`}
                  alt="Stripe"
                  width={25}
                  height={25}
                />
              </div>
              <div className={styles.circleCompany}>
                <Imgix
                  src={`${process.env.REACT_APP_IMAGE_URL}/u6xiphmxvi2j86f0t3wmsb892j9x`}
                  alt="Vimeo"
                  width={25}
                  height={25}
                />
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.title}>
                Check out these recommended ad locations!
              </div>
              <div className={styles.description}>
                These orgs are good places to advertise your jobs because a lot
                of reps are looking at these profiles and the org&apos;s
                themselves are in your industry.
              </div>
            </div>
            <Button className={styles.footerButton} variant="light">
              View Recommended Ad Locations
            </Button>
          </div> */}
        </div>
      </Content>
      <AddCompanyModal
        show={show}
        handleClose={handleClose}
        addCompanies={addJobAdvertisement}
        existingCompanies={jobAds}
        title="Advertise on Competitor Profiles"
        buttonText="Save Ad Locations"
      />
      <Confirmation show={showSuccess} onClose={handleSuccessClose}>
        {addedCompanies.length > 0 && (
          <div className={styles.toast}>{`You're now advertising your jobs on ${
            addedCompanies.length > 2
              ? addedCompanies
                  .map(company =>
                    addedCompanies.indexOf(company) ===
                    addedCompanies.length - 1
                      ? `and ${company.name}'s`
                      : `${company.name}'s`
                  )
                  .join(", ")
              : addedCompanies.length > 1
              ? addedCompanies.map(company => `${company.name}'s`).join(" and ")
              : `${addedCompanies[0].name}'s`
          } ${pluralize("profiles", addedCompanies.length)}`}</div>
        )}
      </Confirmation>
      <Confirmation
        show={showDeleteSuccess}
        onClose={() => setShowDeleteSuccess(false)}
      >
        <div className={styles.toast}>Ad deleted successfully</div>
      </Confirmation>
    </div>
  )
}

export default JobAdsRoute
