import classNames from "classnames"
import { useState, useRef } from "react"
import { formatDate } from "utils/date"
import OutsideClickDetector from "hooks/outsideClickDetector"
import TableApplicantsComponent from "./Components/Table"
import JobTitle from "./Components/JobTitle"
import EmailAddress from "components/shared/EmailAddress"
import LinkedinProfile from "components/shared/LinkedinProfile"
import DetailApplication from "components/shared/DetailApplication"
import Recruiters from "components/shared/Table/Cells/Recruiters"
import ApplicantName from "components/shared/Table/Cells/ApplicantName"
import Actions from "components/shared/Table/Cells/Actions"

import styles from "./applicantsTable.module.scss"

const ApplicantsTable = ({
  applicants = [],
  setApplications,
  section,
  updateJob,
  paginationMeta,
  handleChange,
  currentSort,
  activeSearch,
  recruiters,
  noApplicants,
  jobInfo,
}) => {
  const detailsRef = useRef()
  const [showDetails, setShowDetails] = useState(false)
  const [details, setDetails] = useState({})

  const columns = [
    {
      Header: "Job Listings",
      hideHeader: true,
      columns: [
        {
          Header: "Applied",
          accessor: "created_at",
          sortable: true,
          filterable: false,
          Cell: ({ cell: { value } }) => (value ? formatDate(value) : "--"),
          width: "8rem",
          minWidth: "8rem",
          fixed: false,
          colSpan: 1,
          className: styles.applicantsTable__table__applicant,
          sortType: (prev, next) => {
            return (
              new Date(next?.values?.created_at) -
              new Date(prev?.values?.created_at)
            )
          },
        },
        {
          Header: "Applicant Name",
          accessor: "user",
          filterable: false,
          Cell: ({ cell: { value } }) =>
            <ApplicantName applicantName={value.name} /> || "--",
          colSpan: 1,
          fixed: false,
          sortable: false,
          width: "12rem",
          minWidth: "12rem",
        },
        {
          Header: "Job Title",
          accessor: "job.title",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => <JobTitle jobTitle={value} /> || "--",
          width: "17rem",
          minWidth: "17rem",
          colSpan: 1,
          className: styles.applicantsTable__table__jobTitle,
        },
        {
          Header: "Email Address",
          accessor: "user.email",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => <EmailAddress email={value} /> || "--",
          width: "17rem",
          minWidth: "17rem",
          colSpan: 1,
          hasAction: true,
        },
        {
          Header: "Linkedin",
          accessor: "user.linkedin_url",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) =>
            <LinkedinProfile linkedinProfile={value} /> || "--",
          width: "15rem",
          minWidth: "15rem",
          colSpan: 1,
          hasAction: true,
        },
        {
          Header: "Phone Number",
          accessor: "user.phone",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) => value || "--",
          width: "12rem",
          minWidth: "12rem",
          colSpan: 1,
        },
        {
          Header: "Recruiter(s)",
          accessor: "recruiters",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { value } }) =>
            <Recruiters recruiters={value} /> || "--",
          width: "12rem",
          minWidth: "12rem",
          colSpan: 1,
        },
        {
          id: "Actions",
          accessor: "",
          sortable: false,
          filterable: false,
          Cell: ({ cell: { row } }) => <Actions row={row} /> || "--",
          width: "6rem",
          minWidth: "6rem",
          colSpan: 1,
          className: classNames(
            styles.applicantsTable__table__actions,
            "actions"
          ),
          hasAction: true,
        },
      ],
    },
  ]

  const hideDeails = () => {
    setShowDetails(false)
  }

  const getDetails = data => {
    setDetails(data)
    setShowDetails(true)
  }

  return (
    <>
      <OutsideClickDetector exceptionRef={detailsRef} onClick={hideDeails}>
        <DetailApplication
          show={showDetails}
          details={details}
          setShowDetails={setShowDetails}
          updateJob={updateJob}
          jobInfo={jobInfo}
        />
      </OutsideClickDetector>

      <article ref={detailsRef}>
        <TableApplicantsComponent
          columns={columns}
          data={applicants}
          section={section}
          onRowClick={getDetails}
          setData={setApplications}
          paginationMeta={paginationMeta}
          handleChange={handleChange}
          currentSort={currentSort}
          activeSearch={activeSearch}
          recruiters={recruiters}
          noApplicants={noApplicants}
        />
      </article>
    </>
  )
}

export default ApplicantsTable
