import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import qs from "qs"
import { changePassword, getCurrentEmployer } from "api/auth"

import Error from "components/shared/Notifications/Error"
import PrimaryButton from "components/shared/Buttons/Primary"
import PasswordField from "components/shared/Forms/PasswordField"

import "../signIn/SignIn.scss"

const ResetPasswordRoute = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { authenticated } = useSelector(state => state.auth)

  const [password, setPassword] = useState("")
  const [password_confirmation, setPasswordConfirmation] = useState("")
  const [error, setError] = useState(false)

  const queryStr = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const token = queryStr.token

  const handleSubmit = e => {
    e.preventDefault()
    setError(false)

    const params = {
      employer: {
        password,
        password_confirmation,
        reset_password_token: token,
      },
    }

    dispatch(changePassword(params))
      .then(() => {
        dispatch(getCurrentEmployer(params))
      })
      .catch(response => {
        console.error(response)
        setError(true)
      })
  }

  if (authenticated) {
    const path = location.state || "/dashboard"
    return <Navigate to={path} replace />
  }

  return (
    <div className="sign-in">
      <h5>Reset Your Password</h5>
      <p>Type your new password below</p>
      {error && <Error>Something went wrong</Error>}
      <form onSubmit={handleSubmit}>
        <PasswordField
          value={password}
          handleChange={e => setPassword(e.target.value)}
          reset={true}
        />
        <PasswordField
          value={password_confirmation}
          handleChange={e => setPasswordConfirmation(e.target.value)}
          retype={true}
        />
        <PrimaryButton size="medium" type="submit">
          Reset Password
        </PrimaryButton>
      </form>
    </div>
  )
}

export default ResetPasswordRoute
