import { useMemo } from "react"
import cn from "classnames"
import debounce from "lodash/debounce"

import Icon from "components/Icon"
import Form from "react-bootstrap/Form"

import styles from "../CompanySearch/CompanySearch.module.scss"

const HeadquartersSearch = ({
  headquartersArray,
  handleSearch,
  handleSelect,
  selectedHeadquarters,
}) => {
  const debounceHandleSearch = useMemo(
    () => debounce(handleSearch, 200),
    [handleSearch]
  )

  return (
    <div
      className={cn(`${styles.search}`, {
        [`${styles.search__hasResults}`]: headquartersArray?.length > 11,
      })}
    >
      <div className={styles.inputWrapper}>
        <Icon
          name="search"
          height="20"
          width="20"
          className={styles.iconLeft}
        />
        <Form.Control
          className={styles.input}
          type="text"
          placeholder="Search sales orgs"
          name="search"
          onChange={e => debounceHandleSearch(e.target.value)}
        />
      </div>
      <div className={styles.results}>
        {headquartersArray.length ? (
          headquartersArray.map(headquarters => {
            return (
              <div
                className={styles.results__selection}
                onClick={() => {
                  handleSelect(headquarters)
                }}
                key={headquartersArray.indexOf(headquarters)}
              >
                {selectedHeadquarters.includes(headquarters) ? (
                  <Icon name="checkbox" height={18} width={18} />
                ) : (
                  <div className={styles.results__checkbox} />
                )}
                {selectedHeadquarters === headquarters ? (
                  <strong className={styles.results__selected}>
                    {headquarters.name}
                  </strong>
                ) : (
                  headquarters.name
                )}
              </div>
            )
          })
        ) : (
          <div className={styles.results__selection}>
            No results were found.
          </div>
        )}
      </div>
    </div>
  )
}

export default HeadquartersSearch
