import { createSlice } from "@reduxjs/toolkit"
import { removeAuth } from "utils/auth"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    currentEmployer: {},
    authenticated: false,
    fingerprint: {
      key: "",
      updated: true,
      keepCurrentVersion: false,
    },
  },
  reducers: {
    setCurrentEmployer: {
      reducer(state, action) {
        state.currentEmployer = action.payload
        state.authenticated = true
      },
    },
    signOutEmployer: {
      reducer(state, _) {
        removeAuth()
        state.currentEmployer = {}
        state.authenticated = false
      },
    },
    setAppVersion: {
      reducer(state, action) {
        state.fingerprint.updated =
          state.fingerprint.key === "" ||
          action.payload === state.fingerprint.key
        state.fingerprint.key = action.payload
      },
    },
    doNotUpdate: {
      reducer(state) {
        state.fingerprint.keepCurrentVersion = true
      },
    },
  },
})

export const {
  setCurrentEmployer,
  signOutEmployer,
  setAppVersion,
  doNotUpdate,
} = authSlice.actions

export default authSlice.reducer
