import { useEffect, useState } from "react"
import BarChart from "components/shared/BarChart"
// import GrowthLabel from "components/shared/GrowthLabel"
import Tooltip from "components/shared/Tooltip"
import styles from "./RatingHeathOrganization.module.scss"

const RatingHeathOrganization = ({ data }) => {
  const [pointsChart, setPointsChart] = useState({
    labels: [],
    data: [],
    firstPoint: "",
    lastPoint: "",
  })

  useEffect(() => {
    let dataMap = { labels: [], data: [], firstPoint: "", lastPoint: "" }

    data?.monthly_company_trends?.forEach((point, index) => {
      dataMap.labels.push(`${point.month} ${point.year}`)
      dataMap.data.push(point.ratings_count)
      if (index === 0) dataMap.firstPoint = `${point.month} ${point.year}`
      if (index + 1 === data?.monthly_company_trends.length)
        dataMap.lastPoint = `${point.month} ${point.year}`
    })

    setPointsChart(dataMap)
  }, [data])

  return (
    <section className={styles.ratingHeathOrganization__container}>
      <article>
        <div className={styles.ratingHeathOrganization__title}>
          Total Number of Ratings
        </div>
        <div className={styles.ratingHeathOrganization__value}>
          {data?.ratings_count?.toLocaleString()}
        </div>
        {/* Leaving these commented out in case we want to re-implement --TH */}
        {/* <GrowthLabel growth={data?.score_change_since_company_claimed} /> */}
        <div className={styles.ratingHeathOrganization__infoSection}>
          <p
            className={styles.ratingHeathOrganization__infoSection__description}
          >
            {data?.verified_ratings_count || 0} Verified (
            {data?.verified_ratings_percent || 0}%)
          </p>
          <Tooltip text="Our team verifies the identity of employees by checking their LinkedIn profile. If an employee doesn’t have a LinkedIn profile, we can’t verify their rating" />
        </div>
      </article>
      <div className={styles.ratingHeathOrganization__bar}>
        <BarChart
          points={pointsChart}
          className={styles.ratingHeathOrganization__barChart}
        />
      </div>
    </section>
  )
}

export default RatingHeathOrganization
