import Modal from "react-bootstrap/Modal"
import { updateEmployer } from "api/auth"
import { useDispatch } from "react-redux"
import toast from "react-hot-toast"
import Icon from "components/Icon"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import BenefitsIcon from "assets/images/icons/benefits.svg"
import styles from "./WelcomeMessageModal.module.scss"

const WelcomeMessageModal = ({ show, modalClose }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    const payload = {
      employer: {
        onboarding_finished_at: new Date(),
      },
    }

    dispatch(updateEmployer(payload))
      .then(res => {
        modalClose(false)
      })
      .catch(err => {
        console.log("err", err)
        modalClose(false)
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
      })
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName={styles.welcomeMessageModal__modal}
      centered
    >
      <Modal.Body>
        <section className={styles.welcomeMessageModal__modal__body}>
          <article className={styles.welcomeMessageModal__header}>
            <Icon
              name="close"
              height="18"
              width="18"
              onClick={handleClose}
              className={styles.welcomeMessageModal__header__close}
            />
          </article>
          <article className={styles.welcomeMessageModal__content}>
            <figure>
              <img src={BenefitsIcon} height="100" width="100" alt="benefits" />
            </figure>

            <h5 className={styles.welcomeMessageModal__title}>
              Welcome to your free RepVue employer account!
            </h5>
            <div className={styles.welcomeMessageModal__description}>
              <p>
                Based on your job title and company you have been approved for
                RepVue's free employer account! This means you can post jobs
                that will appear on RepVue, respond to your company's reviews
                and questions, and measure engagement of your RepVue profile.
              </p>
            </div>
          </article>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default WelcomeMessageModal
