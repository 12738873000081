import CompanyBadge from "components/shared/CompanyBadge"

import styles from "./Company.module.scss"

const CompanyCell = ({ original }) => {
  const { company_name, logo, talent_competitor } = original

  return (
    <div className={styles.company}>
      <CompanyBadge
        name={company_name}
        logo={logo}
        competitor={talent_competitor}
      />
    </div>
  )
}

export default CompanyCell
