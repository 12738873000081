import Icon from "components/Icon"
import styles from "./replyAction.module.scss"

const ReplyAction = ({ setReviewSelect, id }) => {
  const sendVote = () => {
    setReviewSelect(id)
  }

  return (
    <div className={styles.replyAction} onClick={sendVote}>
      <Icon name="message" height="18" width="18" />
      <span>Reply</span>
    </div>
  )
}

export default ReplyAction
