import React from "react"
import classNames from "classnames"

import Button from "react-bootstrap/Button"

import styles from "./SecondaryLight.module.scss"

const SecondaryLightButton = React.forwardRef((props, ref) => {
  const { children, onClick, disabled, size, type, className, id } = props
  return (
    <Button
      className={classNames({
        [`${styles.secondaryLight}`]: true,
        [`${styles.small}`]: size === "small",
        [`${styles.medium}`]: size === "medium",
        [`${styles.large}`]: size === "large",
        [`${styles.extraLarge}`]: size === "xl",
        [`${className}`]: className,
      })}
      disabled={disabled}
      onClick={onClick}
      variant="outline-dark"
      ref={ref}
      type={type}
      id={id}
    >
      {children}
    </Button>
  )
})

SecondaryLightButton.displayName = "SecondaryLightButton"

export default SecondaryLightButton
