import React from "react"
import cn from "classnames"
import styles from "./input.module.scss"

const Input = ({
  name,
  placeholder,
  className,
  onChange,
  value,
  type,
  error,
  ...rest
}) => {
  return (
    <>
      <input
        className={cn("form-control", className, {
          [styles.input__error]: error,
        })}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />
      <span className={styles.input__errorMessage}>{error}</span>
    </>
  )
}

export default Input
