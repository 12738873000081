import { toast } from "react-hot-toast"
import Icon from "components/Icon"

const SuccessToast = ({ t, headline, body }) => {
  return (
    <div className="toast-success">
      <div className="toast-success-wrapper">
        <div className="toast-success-header">
          <Icon
            name="close"
            height="20"
            width="20"
            onClick={() => {
              toast.remove(t.id)
            }}
            className="toast-success-close"
          />
        </div>
        <div className="toast-success-body">
          <Icon name="check-circle" height="25" width="25" />
          <div className="toast-success-children">
            <div className="toast-success-text toast-success-headline">
              {headline}
            </div>
            {body && <div className="toast-success-text">{body}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessToast
