export function cleanTrim(string, maxLength = 145, rough = false) {
  if (string) {
    const roughTrim = string.substr(0, maxLength)

    if (rough) {
      return string.length < maxLength
        ? string
        : roughTrim.substr(0, maxLength) + "..."
    }

    const trimmedDescription = roughTrim.substr(
      0,
      Math.min(roughTrim.length, roughTrim.lastIndexOf(" "))
    )
    return string.length < maxLength ? string : trimmedDescription + "..."
  }
  return ""
}

/**
 * formatBytes - this function formats file sizes
 * @param {Number} bytes
 * @returns
 */
export function formatBytes(bytes) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  let size = bytes
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }

  size = Math.round(size * 100) / 100

  return `${size} ${units[unitIndex]}`
}

export const htmlStripper = html => {
  return html.replace(/(\\r\\n|\\n|\\r\|)|(<iframe.*?\/iframe>)/gm, "")
}

export const formattedErrors = errors => {
  return Object.fromEntries(
    Object.entries(errors).map(([key, value]) => [key, value.join(", ")])
  )
}
