import apiCall from "utils/api"

export const createTalentCompetitors = competitor_ids => {
  const options = {
    data: {
      competitor_ids,
    },
  }
  return apiCall("post", `talent_competitors`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getTalentCompetitors = () => {
  return apiCall("get", `talent_competitors`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const deleteTalentCompetitor = id => {
  return apiCall("delete", `talent_competitors/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getTalentData = params => {
  const options = { params }
  return apiCall("get", `companies/talents`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getTalentProfiles = params => {
  const options = { params }
  return apiCall("get", `talent_profiles`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const getTalentProfile = id => {
  return apiCall("get", `talent_profiles/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const createTalentProfile = (filter, name) => {
  const options = {
    data: {
      name,
      filter,
    },
  }
  return apiCall("post", `talent_profiles`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const deleteTalentProfile = id => {
  return apiCall("delete", `talent_profiles/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
