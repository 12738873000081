import { useEffect, useState } from "react"
import { Row, Col, Spinner } from "react-bootstrap"
import {
  getDataHealth,
  getRatingByJobRole,
  getEmployeeMetric,
} from "api/dashboard"
import { pageCall } from "utils/mixpanel"

import Header from "components/Header"
import Content from "components/Content"
import ScoreCard from "components/salesOrganization/ScoreCard"
import RatingsComponent from "components/dashboard/Ratings"
import RankOverall from "components/shared/RankOverall"
import CategoryRatingsCard from "components/salesOrganization/CategoryRatings"
import CategoryRatingsByJobCard from "components/salesOrganization/CategoryRatingsByJob"
import CategoryMostValued from "components/salesOrganization/CategoryMostValued"

const SalesOrgHealthRoute = () => {
  const [loading, setLoading] = useState(true)
  const [healthData, setHealthData] = useState({})
  const [ratingsJobRole, setRatingsJobRole] = useState([])
  const [categoriesMostValued, setCategoriesMostValued] = useState([])
  const [unmappedRatings, setUnmappedRatings] = useState([])

  const fetchSalesOrg = () => {
    setLoading(true)

    getDataHealth()
      .then(res => {
        const { data } = res
        setHealthData(data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log("err", err)
      })
  }

  const formatValuesRatingJobs = (
    data = [],
    global = [],
    filter = "average_all_time"
  ) => {
    let categories = {}
    const ratingJobs = []
    const ratingsFilter = {
      average_all_time: "ratings_count_all_time",
      average_last_12_months: "ratings_count_12_months",
      average_last_6_months: "ratings_count_6_months",
    }
    data.forEach(cat => {
      categories = {
        ...categories,
        [cat.role]: {
          label: cat.role,
          global: global.filter(el => el?.role === cat?.role),
          values: {
            ...categories[cat.role]?.values,
            [cat.metric_name]: {
              average: cat[filter],
              ratings: cat[ratingsFilter[filter]],
              filter: ratingsFilter[filter],
              label: cat.metric_name,
              job: cat.role,
              global: global?.find(
                el =>
                  el?.role === cat?.role && el?.metric_name === cat?.metric_name
              ),
            },
          },
        },
      }
    })

    for (let key in categories) {
      ratingJobs.push(categories[key])
    }

    setRatingsJobRole(ratingJobs)
  }

  const fetchRatingByJobRole = () => {
    getRatingByJobRole()
      .then(res => {
        const { company, global } = res?.data

        setUnmappedRatings(res.data)
        formatValuesRatingJobs(company, global)
      })
      .catch(err => {
        setLoading(false)
        console.log("err", err)
      })
  }

  const fetchEmployeeMetric = () => {
    setLoading(true)
    getEmployeeMetric()
      .then(({ data }) => {
        setCategoriesMostValued(data)
      })
      .catch(err => {
        setLoading(false)
        console.log("err", err)
      })
  }

  const onFilterDate = filter => {
    const { company, global } = unmappedRatings
    formatValuesRatingJobs(company, global, filter.value)
  }

  useEffect(() => {
    fetchSalesOrg()
    fetchRatingByJobRole()
    fetchEmployeeMetric()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    pageCall("Sales Org Health", "Employer Brand")
  }, [])

  return (
    <div>
      <Header title="Sales Org Health" />
      <Content>
        <h3>Sales Org Health</h3>

        <p>
          See the health of your RepVue score, category ratings, employee job
          roles, & more.
        </p>
        {!!!loading ? (
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={8}>
                  <ScoreCard data={healthData?.company} />
                </Col>
                <Col lg={4}>
                  <RatingsComponent data={healthData?.company} />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={8}>
                  <CategoryRatingsCard data={healthData?.company} />
                </Col>
                <Col lg={4}>
                  <RankOverall
                    data={healthData?.company}
                    title="Your RepVue Rank"
                    actionLabel="View More"
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={12}>
                  <CategoryRatingsByJobCard
                    data={ratingsJobRole}
                    onFilterDate={onFilterDate}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={12}>
                  <CategoryMostValued data={categoriesMostValued} />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <div className="applicantsLoader">
            <Spinner animation="border" />
          </div>
        )}
      </Content>
    </div>
  )
}

export default SalesOrgHealthRoute
