import { Button, Modal } from "react-bootstrap"

const NewVersionNotification = ({ show, closeModal }) => {
  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton bsPrefix="modal-header notification-header">
        <Modal.Title>New Version Available</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          Would you like to refresh your browser to receive the latest&nbsp;version?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center w-100">
          <Button className="w-100 mb-2" onClick={() => closeModal(true)}>
            Yes
          </Button>
          <Button
            className="w-25"
            variant="link"
            onClick={() => closeModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default NewVersionNotification
