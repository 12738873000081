import styles from "./teamMemberName.module.scss"

const ApplicantName = ({ memberName, pending }) => {
  return (
    <div className={styles.teamMemberName}>
      <h6 className={styles.teamMemberName__name}>{memberName}</h6>
      {pending && (
        <span className={styles.teamMemberName__tag}>Invite Pending</span>
      )}
    </div>
  )
}

export default ApplicantName
