import React from "react"
import cn from "classnames"
import Form from "react-bootstrap/Form"
import Select, { components } from "react-select"
import LabelInput from "components/shared/LabelInput"
import Imgix from "react-imgix"

import styles from "./SelectInput.module.scss"
const { Control, Option } = components

const IconOption = props => (
  <Option {...props} className={styles.optionWrapper}>
    {props.data.logo && (
      <Imgix
        className={styles.iconWrapperDropdown}
        src={`${process.env.REACT_APP_IMAGE_URL}/${props.data.logo}`}
        alt={props.data.label}
        width={24}
        height={24}
      />
    )}
    {props.data.label}
  </Option>
)

const IconControl = ({ children, ...props }) => {
  const [selectedOption] = props.getValue()
  return (
    <Control {...props} className={styles.optionWrapper}>
      {selectedOption.logo && (
        <Imgix
          className={styles.iconWrapper}
          src={`${process.env.REACT_APP_IMAGE_URL}/${selectedOption.logo}`}
          alt={selectedOption.label}
          width={24}
          height={24}
        />
      )}

      {children}
    </Control>
  )
}

const SelectInput = ({
  label,
  isRequired,
  options = [],
  name,
  onChange,
  value,
  placeholder,
  className,
  error,
  selectStyles,
  isSearchable = true,
  optionsWithLogo = false,
  onInputChange = null,
  errorMessage,
  isClearable,
  ...rest
}) => {
  const customStyles = {
    control: base => ({
      ...base,
      boxShadow: "0px 2px 2px rgb(0 0 0 / 3%)",
      border: "1px solid #E1E4E8",
      borderRadius: "6px",
      height: "42px",
    }),
    option: base => ({
      ...base,
      color: "#19303e",
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "center",
    }),
    menu: base => ({
      ...base,
      zIndex: 3,
    }),
    valueContainer: base => ({
      ...base,
    }),
    ...selectStyles,
  }

  return (
    <div className={cn(className)}>
      <Form.Group>
        {label && <LabelInput label={label} isRequired={isRequired} />}
        <div className={styles.selectContainer}>
          {optionsWithLogo ? (
            <Select
              styles={customStyles}
              name={name}
              placeholder={placeholder}
              options={options}
              id={name}
              className={cn({ [styles.selectContainer__error]: error })}
              value={value}
              instanceId={name}
              onChange={e => onChange(e, name)}
              onInputChange={onInputChange}
              isClearable={isClearable}
              components={{
                Control: IconControl,
                Option: IconOption,
                IndicatorSeparator: () => null,
              }}
              isSearchable={isSearchable}
              {...rest}
            />
          ) : (
            <Select
              styles={customStyles}
              name={name}
              placeholder={placeholder}
              options={options}
              id={name}
              className={cn({ [styles.selectContainer__error]: error })}
              value={value}
              instanceId={name}
              onChange={e => onChange(e, name)}
              onInputChange={onInputChange}
              isClearable={isClearable}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={isSearchable}
              {...rest}
            />
          )}
          {error && !errorMessage && (
            <span className={styles.selectContainer__errorMessage}>
              Please make a selection from the {label}
            </span>
          )}
          {error && errorMessage && (
            <span className={styles.selectContainer__errorMessage}>
              {errorMessage}
            </span>
          )}
        </div>
      </Form.Group>
    </div>
  )
}

export default SelectInput
