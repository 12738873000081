import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getJobById } from "api/jobs"

import Header from "components/Header"
import StatusBar from "components/jobs/Form/StatusBar"
import StepOne from "components/jobs/Form/StepOne"
import StepTwo from "components/jobs/Form/StepTwo"
import { Container, Row, Col, Spinner } from "react-bootstrap"
import StepThree from "components/jobs/Form/StepThree"
import StepNav from "components/jobs/Form/StepNav"
import ApplicantPreferenceModal from "components/jobListings/ApplicantPreferenceModal"
import TableFiller from "components/jobListings/JobsListingsTable/Components/TableFiller"

import styles from "./Form.module.scss"
import Card from "components/shared/Card"

const JobFormRoute = () => {
  const params = useParams()
  const { currentEmployer } = useSelector(state => state.auth)
  const { job_board, customer } = currentEmployer
  const { access_type, jobs_remaining } = customer

  const [step, setStep] = useState(1)
  const [errors, setErrors] = useState([])
  const [job, setJob] = useState(null)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    title: "",
    location_type: null,
    role: null,
    ote_min: undefined,
    ote_max: undefined,
    open_to_new: false,
    metro_location_ids: [],
    sales_division: null,
    years_of_experience: null,
    external_url: null,
    description: null,
  })

  const { company } = currentEmployer
  const { integration } = company

  const isEditing = Boolean(job)
  const isEditingAndIntegrated = isEditing && Boolean(integration)

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  const setDescription = value => {
    setForm({
      ...form,
      description: value,
    })
  }

  const scrollToTop = () => {
    const newJobContainer = document.getElementById("new-job-container")

    newJobContainer.scrollTop = 0
  }

  const unauthorized = jobs_remaining === 0 && access_type === "free" && !job

  const fetchJob = id => {
    getJobById(id)
      .then(({ data }) => {
        setJob(data)
        setForm({
          ...form,
          title: data.title,
          location_type: data.location_type,
          role: data.role,
          ote_min: data.ote_min,
          ote_max: data.ote_max,
          open_to_new: data.open_to_new,
          metro_location_ids: data.metro_locations,
          sales_division: data.sales_division,
          years_of_experience: data.years_of_experience,
          external_url: data.external_url,
          description: data.description,
        })
        setLoading(false)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  useEffect(() => {
    if (params?.id) {
      setLoading(true)
      fetchJob(params?.id)
    }
  }, [params])

  useEffect(() => {
    if (!unauthorized) {
      scrollToTop()
    }
  }, [step])

  return (
    <>
      <Header
        title={job ? "Edit Job" : "Post a Job"}
        icon="job"
        className="create-job"
      />
      <div className={styles.new}>
        <StatusBar step={step} />
        <div className={styles.form} id="new-job-container">
          <Container className="pt-4 pb-4">
            <Row>
              <Col sm={{ offset: 2, span: 8 }}>
                {loading ? (
                  <section className="d-flex justify-content-center w-100 mt-5 pt-5">
                    <Spinner animation="border" />
                  </section>
                ) : unauthorized ? (
                  <Card>
                    <TableFiller />
                  </Card>
                ) : (
                  <>
                    {step === 1 && (
                      <StepOne
                        getErrors={getErrors}
                        setForm={setForm}
                        form={form}
                        errors={errors}
                        isEditing={isEditing}
                        isEditingAndIntegrated={isEditingAndIntegrated}
                      />
                    )}
                    {step === 2 && (
                      <StepTwo
                        setDescription={setDescription}
                        description={form.description}
                        errors={errors}
                        isEditingAndIntegrated={isEditingAndIntegrated}
                      />
                    )}
                    {step === 3 && (
                      <StepThree
                        setStep={setStep}
                        form={form}
                        isEditingAndIntegrated={isEditingAndIntegrated}
                        isEditing={isEditing}
                      />
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Container>
          {!unauthorized && !loading && (
            <StepNav
              step={step}
              setStep={setStep}
              setErrors={setErrors}
              form={form}
              errors={errors}
              job={job}
            />
          )}
        </div>
      </div>
      {!job_board && access_type === "free" && <ApplicantPreferenceModal />}
    </>
  )
}

export default JobFormRoute
