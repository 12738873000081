import styles from "./lineChartTooltip.module.scss"

const CustomTooltip = ({ data, title }) => {
  const VALUE = parseFloat(data?.dataPoints?.[0]?.formattedValue).toFixed(2)

  return (
    <section
      className={styles.lineChartTooltip}
      style={{
        left: `calc(${data?.caretX}px - 5rem)`,
        bottom: `calc(${data?.chart?.height}px + 2rem)`,
      }}
    >
      <div>
        <span className={styles.lineChartTooltip__title}>{title}</span>
      </div>
      <div>
        <span className={styles.lineChartTooltip__value}>{VALUE || ""}</span>
      </div>
      <div>
        <span className={styles.lineChartTooltip__label}>
          {data?.dataPoints?.[0]?.label || ""}
        </span>
      </div>
    </section>
  )
}

export default CustomTooltip
