import { useState } from "react"
import cn from "classnames"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import { toast } from "react-hot-toast"
import { pinReview } from "api/reviews"
import Icon from "components/Icon"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import styles from "./pinAction.module.scss"

const PinAction = ({ review, company, setPinnedCount }) => {
  const { slug, pinned_at } = review
  const [pin, setPin] = useState(pinned_at)

  const updatePin = () => {
    pinReview(slug)
      .then(({ data }) => {
        const { meta, review } = data
        setPin(review?.pinned_at)
        setPinnedCount(meta?.reviews_counters?.pinned_reviews)
      })
      .catch(({ data }) => {
        toast.custom(t => <ErrorToast t={t} headline={data?.base?.[0]} />)
      })
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-pin`}>
          Pin review to top of {company?.name} review page
        </Tooltip>
      }
    >
      <div
        className={cn(styles.pinAction, {
          [styles.pinAction__pinned]: pin,
        })}
        onClick={updatePin}
      >
        <Icon name="pin" height="20" width="20" />
        <span>{pin ? `Unpin Featured Review` : "Feature this Review"}</span>
      </div>
    </OverlayTrigger>
  )
}

export default PinAction
