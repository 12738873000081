import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Imgix from "react-imgix"
import Spinner from "react-bootstrap/Spinner"

import Icon from "components/Icon"
import BattlecardPrint from "./BattlecardPrint"

import "./Battlecard.scss"

const OpponentCard = ({ opponent }) => {
  return (
    <div className="battlecard-half">
      <div className="battlecard-title">
        <Imgix
          className="company-logo"
          src={`${process.env.REACT_APP_IMAGE_URL}/${opponent.logo}`}
          alt={opponent.name}
          width={22}
          height={22}
        />
        {opponent.name}
      </div>
      <div className="battlecard-check">
        <Icon name="check" height="10" width="19" />
        <span></span>
      </div>
      <div className="battlecard-check battlecard-check--gray">
        <Icon name="x" height="19" width="19" />
        <span></span>
      </div>
      <div className="battlecard-check battlecard-check--gray">
        <Icon name="x" height="19" width="19" />
        <span></span>
      </div>
      <div className="battlecard-check">
        <Icon name="check" height="10" width="19" />
        <span></span>
      </div>
      <div className="battlecard-check">
        <Icon name="check" height="10" width="19" />
        <span></span>
      </div>
      <div className="battlecard-check">
        <Icon name="check" height="10" width="19" />
        <span></span>
      </div>
    </div>
  )
}

const Battlecard = ({ opponent1, opponent2, opponent3, id, onDelete }) => {
  const navigate = useNavigate()
  const [download, setDownload] = useState(false)
  if (!opponent1 || !opponent2) {
    return null
  }

  const downloadBattlecard = e => {
    setDownload(true)
  }

  function stopDownload() {
    setDownload(false)
  }

  const redirectToBattlecard = e => {
    e.target.classList.contains("battlecard-body") &&
      navigate(`/battlecards/${id}/edit`)
  }

  return (
    <div className="battlecard">
      <div className="battlecard-body" onClick={e => redirectToBattlecard(e)}>
        <div className="battlecard-actions">
          <div className="action">
            {download ? (
              <Spinner animation="border" />
            ) : (
              <Icon
                name="download"
                height="20"
                width="20"
                onClick={downloadBattlecard}
              />
            )}
          </div>
          <div className="action">
            <Icon
              name="trash"
              height="20"
              width="20"
              onClick={() => {
                onDelete(id)
              }}
            />
          </div>
        </div>
        <div className="battlecard-data">
          <OpponentCard opponent={opponent1} />
          <OpponentCard opponent={opponent2} />
          {opponent3 && <OpponentCard opponent={opponent3} />}
        </div>
      </div>
      <Link to={`/battlecards/${id}/edit`} className="battlecard-footer-link">
        <div className="battlecard-footer">
          <div className="battlecard-name">
            {opponent1.name} vs {opponent2.name}
            {opponent3 && ` vs ${opponent3.name}`}
          </div>
        </div>
      </Link>
      <div className="battlecard-print">
        <BattlecardPrint
          companies={[opponent1, opponent2, opponent3]}
          downloading={download}
          afterDownload={stopDownload}
        />
      </div>
    </div>
  )
}

export default Battlecard
