import { useState } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { inviteTeamMember } from "api/team_members"
import Icon from "components/Icon"
import Input from "components/shared/Input"
import PrimaryButton from "components/shared/Buttons/Primary"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import LabelInput from "components/shared/LabelInput"
import InformativeMessage from "./InformativeMessage"

import styles from "./inviteModal.module.scss"

const InviteTeamMemberModal = ({
  show,
  modalClose,
  handleInviteMember,
  setCurrentMember,
}) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState()
  const [form, setForm] = useState({})

  const handleClose = () => {
    setForm({})
    setErrors({})
    modalClose(false)
  }

  const inviteMembers = payload => {
    setLoading(true)
    inviteTeamMember(payload)
      .then(({ data }) => {
        setLoading(false)
        setCurrentMember(payload?.employer)
        handleInviteMember()
        handleClose()
      })
      .catch(({ data }) => {
        setErrors({ ...data.errors })
        setLoading(false)
      })
  }

  const setValuesForm = e => {
    const { value, name } = e.target
    setForm({ ...form, [name]: value })
    setErrors({})
  }

  const submitForm = () => {
    const payload = { employer: { ...form } }
    inviteMembers(payload)
  }

  return (
    <Modal
      show={show}
      onHide={modalClose}
      className={styles.connectModal__container}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <section className={styles.inviteModal__header}>
          <Icon
            name="close"
            height="18"
            width="18"
            onClick={handleClose}
            className={styles.inviteModal__header__close}
          />

          <h5>Invite a Team Member to Join</h5>
        </section>

        <section className={styles.inviteModal__form}>
          <div className={styles.inviteModal__form__input}>
            <LabelInput label="First Name" />
            <Input
              type="text"
              name="first_name"
              placeholder="John"
              autoFocus
              value={form?.first_name}
              onChange={setValuesForm}
              error={errors?.first_name?.[0]}
            />
          </div>
          <div className={styles.inviteModal__form__input}>
            <LabelInput label="Last Name" />
            <Input
              type="text"
              name="last_name"
              placeholder="Doe"
              value={form?.last_name}
              onChange={setValuesForm}
              error={errors?.last_name?.[0]}
            />
          </div>

          <div className={styles.inviteModal__form__input}>
            <LabelInput label="Title" />
            <Input
              type="text"
              name="title"
              placeholder="Title"
              value={form?.title}
              onChange={setValuesForm}
              error={errors?.title?.[0]}
            />
          </div>

          <div className={styles.inviteModal__form__input}>
            <LabelInput label="Email Address" />
            <Input
              type="email"
              name="email"
              placeholder="johndoe@company.com"
              value={form?.email}
              onChange={setValuesForm}
              error={errors?.email?.[0]}
            />
          </div>
        </section>

        <InformativeMessage />

        <section className={styles.inviteModal__actions}>
          <SecondaryLightButton onClick={handleClose}>
            Cancel
          </SecondaryLightButton>
          <PrimaryButton disabled={loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" /> : "Send Invite"}
          </PrimaryButton>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default InviteTeamMemberModal
