import { formatDate } from "utils/date"
import Status from "components/jobs/jobListings/JobsListingsTable/Components/Status"
import HeaderActions from "components/jobs/jobListings/HeaderActions"
import styles from "./headerJobListings.module.scss"

const JobListingsHeader = ({ jobInfo, jobsRemaining }) => {
  const CLOSED = "closed"
  const RECRUITER = jobInfo?.employers?.[0] || {}
  const notSelectableStatuses = ["pending_approval", "rejected"]
  const hideView = jobInfo?.status !== "active"

  return (
    <section>
      <article className={styles.jobListingsDetailsHeader}>
        <div className={styles.jobListingsDetailsHeader__title}>
          <h4>{jobInfo?.title}</h4>
          <Status
            job={jobInfo}
            selectable={!notSelectableStatuses.includes(jobInfo?.status)}
          />
        </div>
        {jobInfo?.status !== CLOSED && (
          <HeaderActions
            jobInfo={jobInfo}
            jobsRemaining={jobsRemaining}
            hideView={hideView}
            location="Job Detail"
          />
        )}
      </article>

      <article className={styles.jobListingsDetailsHeader__info}>
        <p>Published {formatDate(jobInfo?.created_at)}</p>
        {jobInfo?.employers?.length > 0 && (
          <span>
            Managed by {RECRUITER?.first_name} {RECRUITER?.last_name}
          </span>
        )}
      </article>
    </section>
  )
}

export default JobListingsHeader
