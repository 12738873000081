import Card from "components/shared/Card"
import ListCategories from "./ListCategories"
import styles from "./CategoryMostValued.module.scss"

const CategoryMostValued = ({ data }) => {
  return (
    <div className={styles.categoryMostValued}>
      <Card className={styles.categoryMostValued__card}>
        <h4>Categories Most Valued by Your Employees</h4>
        <p className={styles.categoryMostValued__description}>
          When adding a rating, employees are asked to rank the 7 categories in
          order of importance (#1 being most important to them, #7 being least
          important). See the results below
        </p>

        <ListCategories rows={data} />
      </Card>
    </div>
  )
}

export default CategoryMostValued
