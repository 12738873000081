import ButtonTab from "components/shared/ButtonTab"
import styles from "./SettingsTabs.module.scss"

const SettingsTabs = ({ setTab, activeTab }) => {
  const TABS = [{ label: "Team", slug: "team" }]

  return (
    <div className={styles.wrapper}>
      {TABS.map(tab => (
        <ButtonTab
          key={tab?.slug}
          label={tab?.label}
          onClick={setTab}
          slug={tab?.slug}
          activeTab={activeTab}
        />
      ))}
    </div>
  )
}

export default SettingsTabs
