import cn from "classnames"
import Icon from "components/Icon"
import ResumeSection from "./ResumeSection"
import ApplicationSection from "./ApplicationSection"
import CandidateSection from "./CandidateSection"
import HeaderDetail from "./Header"
import ActionsDetails from "./Actions"
import styles from "./detailApplications.module.scss"

const DetailApplication = ({
  show,
  details = {},
  setShowDetails,
  updateJob,
  jobInfo,
}) => {
  return (
    <section
      className={cn(styles.detailApplication__container, {
        [styles.detailApplication__open]: show,
      })}
    >
      <article className={styles.detailApplication__header}>
        <h4>{details?.user?.name}</h4>
        <Icon
          name="close"
          height="22"
          width="22"
          onClick={() => setShowDetails(false)}
        />
      </article>

      <HeaderDetail details={details} />

      <section>
        {details.resume && <ResumeSection resume={details.resume} />}
        <ApplicationSection details={details} jobInfo={jobInfo} />
        <CandidateSection details={details} />
      </section>

      <ActionsDetails updateJob={updateJob} details={details} />
    </section>
  )
}

export default DetailApplication
