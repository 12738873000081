import { useState, useEffect } from "react"
import { generate } from "utils/pagination-sequence"
import classNames from "classnames"

import Icon from "components/Icon"
import styles from "./Pagination.module.scss"

const Pagination = ({ handleChange, meta, sortBy, perPage }) => {
  const NUM_PAGES_AT_EDGES = 1
  const NUM_PAGES_AROUND_CURRENT = 1

  const [sequence, setSequence] = useState(1)

  const { page, total_pages, next_page, prev_page, total_count } = meta

  useEffect(() => {
    setSequence(
      generate(page, total_pages, NUM_PAGES_AT_EDGES, NUM_PAGES_AROUND_CURRENT)
    )
  }, [page, total_pages])

  const handleNext = () => {
    handleChange(next_page, sortBy)
  }

  const handlePrev = () => {
    handleChange(prev_page, sortBy)
  }

  const handlePageClick = page => {
    handleChange(page, sortBy)
  }

  return (
    <nav aria-label="pagination" className={styles.container}>
      <ul className={`pagination ${styles.pagination}`}>
        <li className={styles.pageItem}>
          <button
            onClick={handlePrev}
            disabled={page === 1}
            aria-label="Previous"
          >
            <div className={styles.buttonWrapper}>
              <Icon
                name="back-arrow"
                height={12}
                width={12}
                className={classNames({
                  [`${styles.pageItem__arrow}`]: true,
                  [`${styles.pageItem__arrow__disabled}`]: page === 1,
                })}
              />
            </div>
          </button>
        </li>
        {sequence.length &&
          sequence.map((pageButton, index) => (
            <li className={styles.pageItem} key={`${pageButton}${index}`}>
              <button
                onClick={() => handlePageClick(pageButton)}
                className={classNames({
                  [`${styles.pageItem__active}`]: pageButton === page,
                })}
                disabled={isNaN(pageButton)}
              >
                {pageButton}
              </button>
            </li>
          ))}
        <li className={styles.pageItem}>
          <button
            onClick={handleNext}
            disabled={page === total_pages}
            aria-label="Next"
          >
            <div className={styles.buttonWrapper}>
              <Icon
                name="back-arrow"
                height={12}
                width={12}
                className={classNames({
                  [`${styles.pageItem__back}`]: true,
                  [`${styles.pageItem__arrow}`]: true,
                  [`${styles.pageItem__arrow__disabled}`]: page === total_pages,
                })}
              />
            </div>
          </button>
        </li>
      </ul>
      <div className={styles.readout}>{`${Math.ceil(
        (page - 1) * perPage + 1
      ).toLocaleString()}-${
        page !== total_pages
          ? Math.ceil(page * perPage).toLocaleString()
          : total_count.toLocaleString()
      } of ${total_count.toLocaleString()} Results`}</div>
    </nav>
  )
}

export default Pagination
