import pluralize from "pluralize"
import styles from "./CardInfo.module.scss"

const CardInfo = ({ quarterGoal, quarterRemaining }) => (
  <section>
    {quarterRemaining > 0 ? (
      <div className={styles.cardInfo}>
        <p>
          You are {quarterRemaining} ratings away from your quarterly goal of{" "}
          {pluralize("rating", quarterGoal, true)}.
        </p>
      </div>
    ) : (
      <div className={styles.cardInfo__success}>
        <p>Great job! You've hit your quarterly ratings goal!</p>
      </div>
    )}
  </section>
)

export default CardInfo
