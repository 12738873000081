import Icon from "components/Icon"
import styles from "./allEmptyTable.module.scss"

const AllEmptyTable = () => {
  return (
    <article className={styles.allEmptyTable}>
      <div className={styles.allEmptyTable__icon}>
        <Icon name="customer" height="50" width="50" />
      </div>

      <h6>No Team Members yet</h6>
    </article>
  )
}

export default AllEmptyTable
