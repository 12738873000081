import { useEffect, useState, useRef } from "react"
import cn from "classnames"
import { Button } from "react-bootstrap"
import dayjs from "dayjs"
import toast from "react-hot-toast"
import { answerQuestion, editAnswer } from "api/questions"
import Icon from "components/Icon"
import ModalQuestions from "../Modal"
import styles from "./drawer.questions.module.scss"

const Drawer = ({ open, onClose, data, company, refreshQuestions }) => {
  const FORMAT_DATE = "MMM DD, YYYY"
  const textareaRef = useRef(null)
  const [text, setText] = useState("")
  const [originalAnswer, setOriginalAnswer] = useState("")
  const [show, setShow] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [firstTimeAnswer, setFirstTimeAnswer] = useState(!data?.official_answer)

  const askedAt = dayjs(new Date(Date.parse(data?.created_at))).format(
    FORMAT_DATE
  )
  const answeredAt = dayjs(
    new Date(Date.parse(data?.official_answer?.created_at))
  ).format(FORMAT_DATE)
 
  const communityAnswers = data?.published_answers.filter(
    comment => !comment.official
  )

  useEffect(() => {
    setText(data?.official_answer?.body || "")
    setOriginalAnswer(data?.official_answer?.body || "")
    setFirstTimeAnswer(!data?.official_answer)
  }, [data])

  const handleClose = () => {
    setShow(false)
  }

  const submitAnswer = () => {
    const questionId = data.id
    let apiCall

    if (firstTimeAnswer) {
      apiCall = answerQuestion(questionId, text)
    } else {
      const answerId = data.official_answer.id
      apiCall = editAnswer(answerId, text)
    }

    apiCall
      .then(() => {
        if (firstTimeAnswer) {
          setShow(true)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e.data.author[0]}`)
      })
      .finally(() => {
        refreshQuestions()
        setIsEditing(false)
        setFirstTimeAnswer(false)
      })
  }

  useEffect(() => {
    if (textareaRef.current && data?.autofocus) {
      setTimeout(() => {
        textareaRef.current.focus()
      }, 500)
    }
  }, [data])

  return (
    <>
      <div className={cn(styles.drawer, { [styles.drawer__open]: open })}>
        {open && (
          <div className={styles.drawer__wrapper}>
            <div className={styles.drawer__wrapper__header}>
              <Icon name="avatar" height="20" width="20" />
              <span className="header-user">
                {data.user ? data.user : "Anonymous"}
              </span>
              <span className={styles.drawerOfficialAnswer__top__headerDate}>
                {askedAt}
              </span>
              <span className={styles.drawer__wrapper__header__close}>
                <Icon name="close" height="28" width="28" onClick={onClose} />
              </span>
            </div>
            <div className={styles.drawer__wrapper__question}>{data.body}</div>
            {firstTimeAnswer || isEditing ? (
              <>
                <div className={styles.drawer__wrapper__label}>
                  Official Answer from{" "}
                  <span className={styles.drawer__wrapper__label__companyBadge}>
                    <Icon name="verified" height="14" width="14" />
                    {company.name}
                  </span>
                </div>
                <div
                  className={styles.drawer__wrapper__drawerOfficialAnswerInput}
                >
                  <textarea
                    ref={textareaRef}
                    placeholder="Type your answer... "
                    value={text}
                    onChange={e => {
                      setText(e.target.value)
                      setIsEditing(true)
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles.drawerOfficialAnswer}>
                  <div className={styles.drawerOfficialAnswer__top}>
                    <div className={styles.drawer__wrapper__label}>
                      Official Answer from{" "}
                      <span
                        className={styles.drawer__wrapper__label__companyBadge}
                      >
                        <Icon name="verified" height="14" width="14" />
                        {company.name}
                      </span>
                    </div>
                    <span
                      className={styles.drawerOfficialAnswer__top__headerDate}
                    >
                      {answeredAt}
                    </span>
                  </div>
                  <div className={styles.drawerOfficialAnswer__description}>
                    {text}
                  </div>
                </div>
                {data?.official_answer?.status === "pending" && (
                  <div className={styles.drawerOfficialAnswer__extra}>
                    This answer is pending approval from RepVue
                  </div>
                )}
                {data?.official_answer?.status === "published" && (
                  <div className={styles.drawerOfficialAnswer__extra}>
                    If you would like to change this answer, please email{" "}
                    <a href="mailto:employer@repvue.com">employer@repvue.com</a>
                  </div>
                )}
              </>
            )}
            {text.length > 0 && isEditing && (
              <div className={styles.drawer__wrapper__drawerButtons}>
                <Button onClick={submitAnswer}>Submit Answer</Button>
                <Button
                  className={styles.drawer__wrapper__drawerButtons__whiteButton}
                  onClick={() => {
                    setText(originalAnswer)
                    setIsEditing(false)
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            <div
              className={`${
                text.length > 0 &&
                isEditing &&
                styles.drawer__wrapper__drawerScreen
              }`}
            >
              {communityAnswers.length > 0 ? (
                <div className={styles.drawer__wrapper__drawerAnswersTitle}>
                  Answers from RepVue Users
                </div>
              ) : (
                <div className={styles.drawer__wrapper__drawerAnswersTitle}>
                  <i>This question has not been answered by our community</i>
                </div>
              )}
              <div className={styles.drawer__wrapper__drawerAnswers}>
                {communityAnswers.map(comment => {
                  const { id, body, votes_total_value, user, created_at } =
                    comment
                  const createdAt = dayjs(
                    new Date(Date.parse(created_at))
                  ).format("MMM DD, YYYY")

                  return (
                    <div
                      className={styles.drawer__wrapper__drawerAnswers__answer}
                      key={id}
                    >
                      <div
                        className={
                          styles.drawer__wrapper__drawerAnswers__answer__rating
                        }
                      >
                        <Icon
                          className={
                            styles.drawer__wrapper__drawerAnswers__answer__rating__arrow
                          }
                          name="arrow-up-thick"
                          height="18"
                          width="15"
                        />
                        <span
                          className={`${
                            votes_total_value !== 0 &&
                            styles.drawer__wrapper__drawerAnswers__answer__rating__colored
                          }`}
                        >
                          {votes_total_value}
                        </span>
                        <Icon
                          name="arrow-up-thick"
                          height="18"
                          width="15"
                          className={cn(
                            styles.drawer__wrapper__drawerAnswers__answer__rating__arrow,
                            styles.drawer__wrapper__drawerAnswers__answer__rating__arrowDown
                          )}
                        />
                      </div>
                      <div
                        className={
                          styles.drawer__wrapper__drawerAnswers__answer__wrapper
                        }
                      >
                        <div
                          className={
                            styles.drawer__wrapper__drawerAnswers__answer__header
                          }
                        >
                          <Icon name="avatar" height="20" width="20" />
                          <span> {user ? user : "Anonymous"}</span>
                          <span>{createdAt}</span>
                        </div>
                        <div
                          className={
                            styles.drawer__wrapper__drawerAnswers__answer__body
                          }
                        >
                          {body}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      <ModalQuestions show={show} company={company} handleClose={handleClose} />
    </>
  )
}

export default Drawer
