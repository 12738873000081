import ProgressCount from "components/shared/ProgressCount"
import styles from "./remainingProgress.module.scss"

const RemainingProgress = ({ pinnedCount }) => {
  const MAX = 3
  return (
    <article className={styles.remainingProgress}>
      <span className={styles.remainingProgress__count}>
        {pinnedCount}/{MAX}Featured
      </span>
      <div className={styles.remainingProgress__progress}>
        <ProgressCount max={MAX} progress={pinnedCount} />
      </div>
      <span className={styles.remainingProgress__remaining}>
        {MAX - pinnedCount} remaining!
      </span>
    </article>
  )
}

export default RemainingProgress
