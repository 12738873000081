import { useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Bar } from "react-chartjs-2"
import CustomTooltip from "./Tooltip"
import styles from "./StackedBarChart.module.scss"

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const StackedBarChart = ({ labels, dataSets, title }) => {
  const [tooltipData, setTooltipData] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)

  const footer = data => {
    if (data.replay === undefined) {
      return setTooltipData({ ...data?.tooltip })
    }
    return
  }

  const options = {
    responsive: true,
    layout: {
      padding: {
        left: -15,
        right: 0,
        top: 25,
        bottom: 0,
      },
    },
    tooltip: {
      yAlign: "top",
    },
    plugins: {
      tooltip: {
        enabled: false,
        reverse: true,
        mode: "index",
        yAlign: "bottom",
        intersect: true,
        external: footer,
      },
      title: {
        display: false,
      },
      legend: {
        display: true,
        reverse: true,
        position: "bottom",
        align: "start",

        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: "rectRounded",
          color: "#19303E",
          font: { size: 14, family: "Söhne" },
        },
        title: {
          display: false,
        },
      },
      datalabels: {
        formatter: (_, ctx) => {
          let datasets = ctx.chart.data.datasets
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0
            datasets.forEach(dataset => {
              sum += dataset.data[ctx.dataIndex]
            })
            return sum.toLocaleString()
          } else {
            return ""
          }
        },
        anchor: "end",
        align: "end",
        color: "#19303E",
        font: { size: 14, family: "Söhne", weight: 500 },
      },
    },
    scales: {
      x: {
        stacked: true,
        font: { size: 14, family: "Söhne", color: "red" },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        display: false,
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  }

  const data = {
    labels,
    datasets: dataSets,
  }

  return (
    <section
      className={styles.stackedBarChart}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Bar
        options={options}
        data={data}
        plugins={[
          {
            id: "legendMargin",
            beforeInit: function (chart) {
              if (chart.legend) {
                const originalFit = chart.legend.fit

                // Override the fit function
                chart.legend.fit = function fit() {
                  // Call original function and bind scope in order to use `this` correctly inside it
                  originalFit.bind(chart.legend)()
                  chart.legend.height += -2
                }
              }
            },
          },
        ]}
      />

      {showTooltip && tooltipData?.body && (
        <CustomTooltip data={tooltipData} title={title} />
      )}
    </section>
  )
}

export default StackedBarChart
