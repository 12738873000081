import Icon from "components/Icon"

export const getAvatar = (color, size, className) => {
  const AVATAR_DICTIONARY = {
    pink: () => (
      <Icon
        height={size}
        width={size}
        name="pink-avatar"
        className={className}
      />
    ),
    orange: () => (
      <Icon
        height={size}
        width={size}
        name="orange-avatar"
        className={className}
      />
    ),
    yellow: () => (
      <Icon
        height={size}
        width={size}
        name="yellow-avatar"
        className={className}
      />
    ),
    lime: () => (
      <Icon
        height={size}
        width={size}
        name="lime-avatar"
        className={className}
      />
    ),
    gray: () => (
      <Icon
        height={size}
        width={size}
        name="gray-avatar"
        className={className}
      />
    ),
    violet: () => (
      <Icon
        height={size}
        width={size}
        name="violet-avatar"
        className={className}
      />
    ),
    green: () => (
      <Icon
        height={size}
        width={size}
        name="green-avatar"
        className={className}
      />
    ),
    blue: () => (
      <Icon
        height={size}
        width={size}
        name="blue-avatar"
        className={className}
      />
    ),
  }
  return AVATAR_DICTIONARY[color]()
}
