import styles from "./DescriptionGoal.module.scss"

const DescriptionGoal = ({ percentage, total }) => (
  <div className={styles.descriptionGoal}>
    <p>
      This goal is {percentage}% of your sales employees ({Number(total).toLocaleString()} total
      employees).
      {/* <Link to="#">Edit Goal</Link> */}
    </p>
  </div>
)

export default DescriptionGoal
