import RcRange from "rc-slider"
import "rc-slider/assets/index.css"

import "./Slider.scss"

function SliderRange({
  onChange,
  min,
  max,
  step,
  className,
  localRange,
  onAfterChange,
}) {
  return (
    <RcRange
      min={min}
      max={max}
      step={step}
      defaultValue={[min, max]}
      value={[localRange.min, localRange.max]}
      range={true}
      onChange={onChange}
      onAfterChange={onAfterChange}
      className={className}
    />
  )
}

export default SliderRange
