import { useMemo } from "react"
import cn from "classnames"
import debounce from "lodash/debounce"

import Icon from "components/Icon"
import Form from "react-bootstrap/Form"

import styles from "./CompanySearch.module.scss"

const CompanySearch = ({
  companies,
  handleSearch,
  handleSelect,
  selectedCompanies,
}) => {
  const debounceHandleSearch = useMemo(
    () => debounce(handleSearch, 200),
    [handleSearch]
  )

  return (
    <div
      className={cn(`${styles.search}`, {
        [`${styles.search__hasResults}`]: companies.length > 11,
      })}
    >
      <div className={styles.inputWrapper}>
        <Icon
          name="search"
          height="20"
          width="20"
          className={styles.iconLeft}
        />
        <Form.Control
          className={styles.input}
          type="text"
          placeholder="Search sales orgs"
          name="search"
          onChange={e => debounceHandleSearch(e.target.value)}
        />
      </div>
      <div className={styles.results}>
        {companies.length ? (
          companies.map(company => {
            return (
              <div
                className={styles.results__selection}
                onClick={() => {
                  handleSelect(company)
                }}
                key={companies.indexOf(company)}
              >
                {selectedCompanies.includes(company) ? (
                  <Icon name="checkbox" height={18} width={18} />
                ) : (
                  <div className={styles.results__checkbox} />
                )}
                {selectedCompanies.includes(company) ? (
                  <strong className={styles.results__selected}>
                    {company.name}
                  </strong>
                ) : (
                  company.name
                )}
              </div>
            )
          })
        ) : (
          <div className={styles.results__selection}>
            {" "}
            No results were found.
          </div>
        )}
      </div>
    </div>
  )
}

export default CompanySearch
