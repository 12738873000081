import { Row, Col } from "react-bootstrap"

import styles from "./Header.module.scss"

const TalentDataHeader = () => {
  return (
    <>
      <Row>
        <Col lg={{ span: 9 }}>
          <div className={styles.headline}>
            <h3>Talent Data</h3>
            <p>
              Analyze our real-time market data, identify your talent
              competitors, save talent profiles & more.
            </p>
          </div>
        </Col>
        <Col lg={{ span: 3 }} className="d-flex justify-content-end">
          {/* <div className={styles.tutorials}>
            <Button>
              Tutorials & Insights{" "}
              <Icon name="double-arrow" height={24} width={24} />
            </Button>
          </div> */}
        </Col>
      </Row>
    </>
  )
}

export default TalentDataHeader
