import styles from "./applicantRecruiters.module.scss"

const Recruiters = ({ recruiters = [] }) => {
  return (
    <div className={styles.recruiters}>
      <span>{recruiters.join(", ") || "--"}</span>
    </div>
  )
}

export default Recruiters
