import { useEffect, useState, useCallback } from "react"
import { useParams } from "react-router-dom"
import { Row, Col, Spinner, Tab, Tabs } from "react-bootstrap"
import { getJobApplications, getJobById, updateJobApplication } from "api/jobs"
import { useSelector } from "react-redux"
import { pageCall } from "utils/mixpanel"

import Header from "components/Header"
import Content from "components/Content"
import ApplicantsTable from "components/jobs/jobsDetails/ApplicantsTable"
import Pagination from "components/talentData/Table/Pagination"
import JobDetails from "components/jobsDetails/Details"
import JobListingsHeader from "components/jobsDetails/Header"

import "./JobListingsDetails.scss"

const JobListingsDetailsRoute = () => {
  const params = useParams()
  const [jobInfo, setJobInfo] = useState({})
  const [applicants, setApplicants] = useState([])
  const [applicationsMeta, setApplicationsMeta] = useState({})
  const [loading, setLoading] = useState(true)
  const [sortState, setSortState] = useState({})

  const { currentEmployer } = useSelector(state => state.auth)

  const { customer, job_board } = currentEmployer
  const { jobs_remaining } = customer

  const external = job_board?.applicant_method === "External"

  const rejectedJob = jobInfo?.status === "rejected"
  const hideApplicants = rejectedJob || external

  const updateJob = (id, status) => {
    const payload = {
      job_application: {
        status: status,
      },
    }

    updateJobApplication(id, payload)
      .then(res => {
        fetchJob()
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const fetchJob = id => {
    setLoading(true)
    getJobById(id)
      .then(({ data }) => {
        setJobInfo(data)
      })
      .catch(err => {
        console.log("err", err)
      })
    getJobApplications(id)
      .then(({ data }) => {
        setApplicants(data?.job_applications)
        setApplicationsMeta(data?.meta)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  const fetchApplicants = useCallback(
    options => {
      getJobApplications(params?.id, options)
        .then(({ data }) => {
          setApplicants(data?.job_applications)
          setApplicationsMeta(data?.meta)
          setLoading(false)
        })
        .catch(error => {
          console.log(error)
          setLoading(false)
        })
    },
    [params?.id]
  )

  const changePage = useCallback(
    page => {
      fetchApplicants({ ...sortState, page: page })
    },
    [fetchApplicants, sortState]
  )

  const handleSort = sort => {
    const newSort = sort
      ? { sort_direction: sort.desc ? "asc" : "desc", sort_key: sort.id }
      : {}
    newSort.page = 1

    setSortState(newSort)
    fetchApplicants(newSort)
  }

  useEffect(() => {
    fetchJob(params?.id)
  }, [params])

  useEffect(() => {
    pageCall("Job Detail", "Jobs")
  }, [])

  return (
    <div className="jobListingsDetails">
      <Header title="Job Listings" path={jobInfo?.title} basePath="/jobs" />
      <Content>
        {!loading && (
          <JobListingsHeader jobInfo={jobInfo} jobsRemaining={jobs_remaining} />
        )}
        <Row>
          <Col lg={12}>
            {!loading ? (
              <Tabs defaultActiveKey={hideApplicants ? "details" : "all"}>
                {!hideApplicants && (
                  <Tab
                    eventKey="all"
                    title={
                      <>
                        Applicants
                        <span>{applicants.length}</span>
                      </>
                    }
                  >
                    <>
                      <ApplicantsTable
                        applicants={applicants || []}
                        section="all"
                        updateJob={updateJob}
                        jobInfo={jobInfo}
                        handleSort={handleSort}
                        sortState={sortState}
                      />

                      {!loading && applicationsMeta?.page_info && (
                        <div className="rv-tfoot">
                          <Pagination
                            handleChange={changePage}
                            meta={applicationsMeta?.page_info}
                            sortBy={null}
                            perPage={50}
                          />
                        </div>
                      )}
                    </>
                  </Tab>
                )}

                <Tab eventKey="details" title="Job Details">
                  <JobDetails jobInfo={jobInfo} />
                </Tab>
              </Tabs>
            ) : (
              <div className="jobListingsLoader">
                <Spinner animation="border" />
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </div>
  )
}

export default JobListingsDetailsRoute
