import apiCall from "utils/api"

export const getQuestions = () => {
  return apiCall("get", `questions`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("getQuestions error:", error)
      throw error
    })
}

export const getQuestionDetails = questionId => {
  return apiCall("get", `questions/${questionId}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("getAnswers error:", error)
      throw error
    })
}

export const answerQuestion = (questionId, answer) => {
  const options = {
    data: {
      answer: {
        body: answer,
      },
    },
  }
  return apiCall("post", `questions/${questionId}/answers`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("answerQuestion error:", error)
      throw error
    })
}

export const editAnswer = (answerId, answer) => {
  const options = {
    data: {
      answer: {
        body: answer,
      },
    },
  }
  return apiCall("patch", `answers/${answerId}`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("editAnswer error:", error)
      throw error
    })
}
