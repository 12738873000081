import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import qs from "qs"
import {
  completeRegistration,
  getCurrentEmployer,
  resendRegistrationEmail,
} from "api/auth"
import toast from "react-hot-toast"

import PrimaryButton from "components/shared/Buttons/Primary"
import PasswordField from "components/shared/Forms/PasswordField"
import SuccessToast from "components/shared/Notifications/SuccessToast"
import ErrorToast from "components/shared/Notifications/ErrorToast"

import "./CompleteRegistration.scss"

const CompleteRegistrationRoute = () => {
  const dispatch = useDispatch()
  const { authenticated } = useSelector(state => state.auth)
  const [password, setPassword] = useState("")
  const [password_confirmation, setPasswordConfirmation] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const queryStr = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const token = queryStr.token

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const params = {
      employer: {
        password,
        password_confirmation,
        reset_password_token: token,
      },
    }

    dispatch(completeRegistration(params))
      .then(() => {
        dispatch(getCurrentEmployer())
      })
      .catch(response => {
        console.error(response.data)
        setError(response?.data?.errors || true)
        setLoading(false)
      })
  }

  const resendPassword = () => {
    resendRegistrationEmail(token)
      .then(() => {
        toast.custom(
          t => (
            <SuccessToast
              t={t}
              headline="Your new registration has been sent to your email!"
            />
          ),
          { duration: 10000 }
        )
        setError(false)
      })
      .catch(() =>
        toast.custom(
          t => (
            <ErrorToast
              t={t}
              headline={
                <span>
                  Something went wrong, please try again or contact{" "}
                  <a href="mailto:support@repvue.com">support@repvue.com</a> for
                  assistance
                </span>
              }
            />
          ),
          { duration: 10000 }
        )
      )
  }

  if (authenticated) {
    return <Navigate to={"/"} replace />
  }

  return (
    <div className="completeRegistration">
      <h5>Set Your Password</h5>
      <p>Type your new password below</p>

      {error && (
        <div className="alert alert-danger">
          {error?.reset_password_token?.[0] ===
          "has expired, please request a new one" ? (
            <span>
              Your registration link has expired.{" "}
              <button className="resend-registration" onClick={resendPassword}>
                Click here
              </button>{" "}
              to resend a registration link.
            </span>
          ) : (
            "Something went wrong"
          )}
        </div>
      )}

      <form onSubmit={handleSubmit} className="set-password">
        <div className="mb-3">
          <PasswordField
            id="password"
            value={password}
            handleChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <PasswordField
            id="password_confirmation"
            retype={true}
            value={password_confirmation}
            handleChange={e => setPasswordConfirmation(e.target.value)}
          />
        </div>
        <PrimaryButton size="medium" type="submit" disabled={loading}>
          Set Your Password
        </PrimaryButton>
      </form>
    </div>
  )
}

export default CompleteRegistrationRoute
