import { ProgressBar } from "react-bootstrap"
import styles from "./progressCount.module.scss"

const ProgressCount = ({ progress, max }) => {
  const determineProgress = value => {
    return (value * 100) / max
  }

  return (
    <article>
      <ProgressBar
        className={styles.progressCount}
        now={determineProgress(progress)}
      />
    </article>
  )
}

export default ProgressCount
