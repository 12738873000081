const FULL_PLATFORM = "full_platform"
const PREMIUM_PROFILE = "profile_only"
const TALENT_ICON = "talent-data"
const TALENT_SUBHEADER = "Want access to talent data?"
const TALENT_DESCRIPTION =
  "This data is sourced from 10.5K+ anonymous sales reps who rated their workplaces on RepVue. You can leverage it to benchmark compensation at your own org, track talent competitors, and find sales professionals who match your hiring criteria."
const TALENT_ITEMS = [
  "Identify talent competitors",
  "Compare your org to talent competitors",
  "Recruit to sales professionals who match your hiring criteria",
]

export const PROTECT_ROUTES = {
  "/talent-data": {
    permissions: [FULL_PLATFORM],
    icon: TALENT_ICON,
    title: "Talent Data",
    subtitle: TALENT_SUBHEADER,
    description: TALENT_DESCRIPTION,
    items: TALENT_ITEMS,
  },
  "/talent-profiles": {
    permissions: [FULL_PLATFORM],
    icon: TALENT_ICON,
    title: "Talent Profiles",
    subtitle: TALENT_SUBHEADER,
    description: TALENT_DESCRIPTION,
    items: TALENT_ITEMS,
  },
  "/competitors": {
    permissions: [FULL_PLATFORM],
    icon: TALENT_ICON,
    title: "Talent Competitors",
    subtitle: TALENT_SUBHEADER,
    description: TALENT_DESCRIPTION,
    items: TALENT_ITEMS,
  },
  "/battlecards": {
    permissions: [FULL_PLATFORM],
    icon: "battlecards",
    title: "Battlecards",
    subtitle: "Want access to battlecards?",
    description:
      "Want access to battlecards? Battlecards show how two or more companies compare to each other in several key categories. These categories include: quota attainment, RepVue score, number of ratings, and the scores for each rating category.",
    items: [
      "See how your org compares in each category to another org",
      "Analyze your RepVue score, category ratings, & more!",
      "Show job candidates how your org out-performs",
    ],
  },
  "/health": {
    permissions: [FULL_PLATFORM, PREMIUM_PROFILE],
    icon: "health",
    title: "Sales Organization Health",
    subtitle: "Want access to your sales org health report?",
    description:
      "Your sales org health report reveals a deep dive into your RepVue score, category scores, and overall rank within RepVue.",
    items: [
      "Benchmark your sales org against competitors",
      "See ratings by individual job roles (for each category)",
      "Analyze your repvue score, category ratings, rankings, & more!",
    ],
  },
  "/ads": {
    permissions: [FULL_PLATFORM, PREMIUM_PROFILE],
    icon: "talent-data",
    title: "Job Advertisements",
    subtitle: "Want access to job ads?",
    description:
      "Showcase your job listings directly on your competitors' profiles, amplifying your brand's visibility and attracting the ideal candidates who are interested in your competitors!",
    items: [
      "List your jobs on your talent competitors profiles",
      "Increase your awareness and applicants to your target candidates",
      "Prevent job ads from being run on your company's profile",
    ],
  },
}
