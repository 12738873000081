import Icon from "components/Icon"
import { Link } from "react-router-dom"

import styles from "./SubMenuPopOut.module.scss"

const SubMenuPopOut = ({ item, setShownSubMenu }) => {
  const { title, subItems } = item
  return (
    <div className={styles.popoutWrapper}>
      <div className={styles.popout}>
        <div className={styles.popout__title}>
          <span>{title}</span>
          <Icon name="chevron" height={14} width={14} />
        </div>
        {subItems.map(item => (
          <Link
            to={item.path}
            className={styles.popout__subItem}
            key={item.path}
            onClick={() => setShownSubMenu(null)}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default SubMenuPopOut
