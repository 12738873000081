import PrimaryButton from "components/shared/Buttons/Primary"
import { Link } from "react-router-dom"
import empty from "./applicants.png"

import styles from "./EmptyState.module.scss"

const EmptyState = ({ search }) => {
  return (
    <div className={styles.card}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <img src={empty} alt="No Profiles" />
        </div>
        <h5>
          {search
            ? "No profiles matched your search."
            : "No talent profiles saved yet"}
        </h5>
        <p>
          {!search &&
            "Save a talent profile from the talent data page. Once you do, it will appear here and you can track and promote your jobs to the sales reps who match the parameters."}
        </p>
        <Link to="/talent-data">
          <PrimaryButton size="large"> Go to Talent Data Page</PrimaryButton>
        </Link>
      </div>
    </div>
  )
}

export default EmptyState
