import { string } from "prop-types"
import classNames from "classnames"
import Icon from "components/Icon"
import styles from "./starsCategory.module.scss"

const RATINGS_COLORS = {
  red: [0, 1.9],
  orange: [2, 2.9],
  yellow: [3, 3.7],
  lightGreen: [3.8, 4.2],
  green: [4.3, 5],
  gray: [6, 7],
}

const StarsCategory = ({ average = "0.0", color, size }) => {
  const assignStarColor = rating => {
    return Object.keys(RATINGS_COLORS).find(
      key =>
        rating >= RATINGS_COLORS[key][0] && rating <= RATINGS_COLORS[key][1]
    )
  }

  const totalStars = Math.ceil(parseFloat(average))
  const starColor = assignStarColor(color || totalStars)
  const [int, decimal] = average.split(".")

  return (
    <div className={styles["category-data__stars"]}>
      {[1, 2, 3, 4, 5].map(star => {
        const isLessThanInt = star <= int
        const isLessThanTotal = star <= totalStars
        const classString = classNames({
          [styles["category-data__stars__star"]]: true,
          [styles[`category-data__stars__star__${starColor}`]]:
            isLessThanInt || isLessThanTotal,
          [styles["category-data__stars__star__full"]]: isLessThanInt,
          [styles["category-data__stars__star__small"]]: size === "small",
          [styles["category-data__stars__star__partial"]]:
            !isLessThanInt && isLessThanTotal,
        })

        return (
          <span
            className={classString}
            key={star}
            style={{
              "--percentage": `${decimal * 10}%`,
            }}
          >
            <Icon name="star-filled" height="13.8" width="14.5" />
          </span>
        )
      })}
    </div>
  )
}

StarsCategory.propTypes = {
  average: string.isRequired,
}

export default StarsCategory
