import { useState } from "react"
import { generateFilterBadges } from "utils/query"
import { createTalentProfile } from "api/talent"
import { startCase } from "lodash"

import { Form, Modal } from "react-bootstrap"
import Icon from "components/Icon"
import Error from "components/shared/Notifications/Error"

import "./SaveTalentProfile.scss"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import PrimaryButton from "components/shared/Buttons/Primary"

const SaveTalentProfileModal = ({
  show,
  modalClose,
  currentFilters,
  setShowProfileSuccess,
}) => {
  const [name, setName] = useState("")
  const [error, setError] = useState({})
  const filterModel = currentFilters.filter_model || null
  const periodModel = currentFilters.period || null
  const personaModel = currentFilters.selling_persona_names || null

  const submitProfile = () => {
    createTalentProfile(currentFilters, name)
      .then(res => {
        setShowProfileSuccess()
        setName("")
        modalClose()
      })
      .catch(error => {
        setError(error.data)
        console.error(error)
      })
  }

  const handleClose = () => {
    modalClose()
    setError({})
  }

  return (
    <Modal show={show} onHide={handleClose} className="profile-modal-container">
      <Modal.Body>
        <div className="profile-modal-header">
          <span>Save View as Talent Profile</span>
          <Icon name="close" height="22" width="22" onClick={handleClose} />
        </div>
        <p>
          Save this set of filters as a talent profile can track, recruit, and
          promote your jobs to this targeted group of sales reps. The results
          are dynamic - which means that all future RepVue users who match these
          filters will be included.
        </p>
        {error.name && <Error>{error.name}</Error>}
        <Form>
          <Form.Group>
            <Form.Label>Profile Name</Form.Label>
            <Form.Control
              className="profile-modal-input"
              type="text"
              placeholder="Eg: “Account Executives with high quota attainment”"
              name="name"
              value={name}
              onChange={e => {
                if (error.name && e.target.value.length > 0) {
                  setError({})
                }
                setName(e.target.value)
              }}
            />
          </Form.Group>
        </Form>
        <span className="profile-modal-headline">Filters</span>
        <div className="profile-modal-filters">
          {filterModel !== null &&
            generateFilterBadges(filterModel).map((filter, i) =>
              filter.map((subFilter, j) => (
                <div className="profile-modal-badge" key={`${i}${j}`}>
                  {subFilter}
                </div>
              ))
            )}
          {periodModel !== null && (
            <div className="profile-modal-badge">{startCase(periodModel)}</div>
          )}
          {personaModel !== null &&
            personaModel.map(persona => (
              <div className="profile-modal-badge">{`Buyer Persona: ${persona}`}</div>
            ))}
        </div>
        <div className="profile-modal-footer">
          <SecondaryLightButton onClick={handleClose} size="large">
            Cancel
          </SecondaryLightButton>
          <PrimaryButton onClick={submitProfile} size="large">
            Save Talent Profile
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SaveTalentProfileModal
