import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { Toaster } from "react-hot-toast"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import TagManager from "react-gtm-module"
import store from "store"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "components/shared/Notifications/SuccessToast.scss"
import "components/shared/Notifications/ErrorToast.scss"
import "index.scss"

const container = document.getElementById("root")
const root = createRoot(container)

const tagManagerArgs = {
  gtmId: "GTM-KTNTVJC",
}

TagManager.initialize(tagManagerArgs)

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
  enabledReleaseStages: ["staging", "production"],
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
    <Toaster position="bottom-center" />
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
