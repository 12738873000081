export const LABELS_FILTERS_TALENT_PROFILE = {
  repvue_score: "repVueScore",
  people_count: "peopleCount",
  average_deal_size: "avgDealSize",
  average_quota_attainment: "quotaAttainment",
  average_ote: "ote",
  top_performer_earnings: "topPerformerEarnings",
  average_base_compensation: "basePay",
  average_culture_and_leadership_score: "cultureScore",
  average_product_market_fit_score: "productMarketFitScore",
  average_diversity_and_inclusion_score: "diversityAndInclusionScore",
  average_base_compensation_score: "baseCompensationScore",
  average_inbound_lead_opportunity_flow_score: "inboundLeadScore",
  average_incentive_compensation_structure_score:
    "incentiveCompensationStructureScore",
  average_professional_development_and_training_score:
    "professionalDevelopmentScore",

  company_name: "selectedCompanies",
  user_role: "selectedRoles",
  selling_persona_names: "selectedPersonas",
  industry_name: "selectedIndustries",
  sales_division: "selectedSegments",
  headquarters: "headquarters",
}
