import cn from "classnames"
import ItemRowRatingJob from "./ItemRating"
import styles from "./RowRating.module.scss"

const CATEGORIES = {
  product: "Product - Market Fit",
  development: "Professional Development and Training",
  culture: "Culture and Leadership",
  compensation: "Incentive Compensation Structure",
  inbound: "Inbound Lead / Opportunity Flow",
  baseCompensation: "Base Compensation",
  diversity: "Diversity and Inclusion",
}

const RowRating = ({ rating }) => {
  return (
    <div className={cn(styles.rowRating__container)}>
      <article className={styles.rowRating__row}>
        <div className={styles.rowRating__row__category}>
          <span>{rating.label}</span>
        </div>

        <div className={styles.rowRating__row__column}>
          <ItemRowRatingJob value={rating.values[CATEGORIES.product]} />
        </div>
        <div className={styles.rowRating__row__column}>
          <ItemRowRatingJob value={rating.values[CATEGORIES.development]} />
        </div>
        <div className={styles.rowRating__row__column}>
          <ItemRowRatingJob value={rating.values[CATEGORIES.culture]} />
        </div>
        <div className={styles.rowRating__row__column}>
          <ItemRowRatingJob value={rating.values[CATEGORIES.compensation]} />
        </div>
        <div className={styles.rowRating__row__column}>
          <ItemRowRatingJob value={rating.values[CATEGORIES.inbound]} />
        </div>
        <div className={styles.rowRating__row__column}>
          <ItemRowRatingJob
            value={rating.values[CATEGORIES.baseCompensation]}
          />
        </div>
        <div className={styles.rowRating__row__column}>
          <ItemRowRatingJob value={rating.values[CATEGORIES.diversity]} />
        </div>
      </article>
    </div>
  )
}

export default RowRating
