import { useEffect, useState } from "react"
import cn from "classnames"

import Icon from "components/Icon"
import CompanyBadge from "components/shared/CompanyBadge"

import styles from "./RowOrganization.module.scss"


const RowOrganizations = ({ ranking, rank }) => {
  const [up, setUp] = useState(false)

  const { name, logo, talent_competitor } = ranking

  useEffect(() => {
    setUp(parseFloat(ranking.rank_change) > 0)
  }, [ranking])

  return (
    <div
      className={cn(styles.rowOrganization__container, {
        [styles.rowOrganization__container__select]: rank === ranking?.rank,
      })}
    >
      <article className={styles.rowOrganization__row}>
        <div className={styles.rowOrganization__row__company}>
          <span
            className={cn(styles.rowOrganization__row__rank, {
              [styles.rowOrganization__row__rank__select]:
                rank === ranking?.rank,
            })}
          >
            {ranking.rank}
          </span>
          <div className={styles.rowOrganization__row__company__information}>
            <CompanyBadge
              name={name}
              logo={logo}
              competitor={talent_competitor}
            />
          </div>
        </div>

        <div
          className={cn(styles.rowOrganization__row__company__change, {
            [styles.rowOrganization__row__company__change__up]: up,
            [styles.rowOrganization__row__company__change__down]: !!!up,
          })}
        >
          <Icon
            className={cn({
              [styles.rowOrganization__row__company__change__arrowDown]: !!!up,
            })}
            name="up-arrow"
            height="15"
            width="15"
          />
          <span>{ranking.rank_change}</span>
        </div>
      </article>
    </div>
  )
}

export default RowOrganizations
