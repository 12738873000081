import PinAction from "./pinAction"
import ReplyAction from "./replyAction"
import styles from "./actionsCardList.module.scss"

const ReviewsCardListActions = ({
  company,
  review,
  setReviewSelect,
  id,
  response,
  pinnedCount,
  setPinnedCount,
}) => {
  return (
    <section className={styles.actions}>
      <article className={styles.actions__main}>
        {!!!response && (
          <ReplyAction setReviewSelect={setReviewSelect} id={id} />
        )}
        <PinAction
          pinnedCount={pinnedCount}
          setPinnedCount={setPinnedCount}
          company={company}
          review={review}
        />
      </article>
    </section>
  )
}

export default ReviewsCardListActions
